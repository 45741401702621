import React from 'react';
import { Modal } from 'reactstrap';

const RemoveCardModal = ({ cardInfo, removeCardModalOpen, setRemoveCardModalOpen, confirmRemoveCard }) => {
    return (
        <Modal isOpen={removeCardModalOpen} toggle={() => setRemoveCardModalOpen(false)} centered={true}>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Remove Payment Card</h5>
                <button
                    type="button"
                    onClick={() => setRemoveCardModalOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <h3>Are you sure you want to remove this card?</h3>
                {cardInfo && (
                    <div>
                        <p style={{ fontSize: 18, marginTop: 20, marginBottom: 20 }}>
                            {cardInfo.brand} ending in {cardInfo.last4}
                        </p>

                        <button onClick={() => confirmRemoveCard(cardInfo)} className="btn btn-danger">
                            Remove
                        </button>
                        <button onClick={() => setRemoveCardModalOpen(false)} className="btn">
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default RemoveCardModal;
