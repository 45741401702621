import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { prettySize } from 'helpers/pretty';
import {ApexOptions} from "apexcharts";
import { Plan } from 'store/billing/types';
import {Typography} from "@mui/material";

interface BandwidthUsageChartProps {
    currentPlan: Plan | null;
    bandwidth: number;
    percentage: number;
    limit: number;
}

const BandwidthUsageChart = (props: BandwidthUsageChartProps) => {
    const {bandwidth, percentage, limit, currentPlan} = props;

    const series: number [] = [percentage];
    const options: ApexOptions = {
        chart: {
            height: 150,
            type: 'radialBar',
            sparkline: {
                enabled: true
            }
        },
        colors: ['#ff009e'],
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5 // margin is in pixels
                },

                hollow: {
                    size: '60%'
                },

                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '16px'
                    }
                }
            }
        },
        grid: {
            padding: {
                top: -10
            }
        },
        stroke: {
            dashArray: 3
        },
        labels: ['Storage']
    };
    return (
        <Row className="my-2">
            <Col>
                <div className="text-center">
                    <Typography variant="h6">Bandwidth Used This Month</Typography>
                    <div className="apex-charts">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="radialBar"
                            // height={150}
                            width={'30%'}
                        />
                    </div>
                    <Typography color="grey">{prettySize(bandwidth)} of {currentPlan?.name !== 'PROFESSIONAL' ? limit : 100} GB used</Typography>
                </div>
            </Col>
        </Row>
    );
};

export default BandwidthUsageChart;
