import { api, managedApi } from 'services/api';
import { listFolderContents } from '../../actions/pin.actions';
import {
    USER_PIN_LIST_RETRIEVED,
    USER_PINNED_DATA_TOTALS_RECEIVED,
    PIN_JOBS,
    TRAVERSE_FOLDER
} from '../../actions/types';
import {PinData} from "./types";

export const shapeV2Data = (item: any) => {
    return {
        id: item.id,
        ipfs_pin_hash: item.cid,
        size: item.size,
        date_pinned: item.createdAt,
        metadata: item.metadata,
        name: item.name,
        originalname: item.originalname,
        uri: item.uri,
        v2: true,
        type: item.type,
        submarined: true //@todo THIS WILL NEED BE CHANGED WHEN PUBLIC AND PRIVATE FILES EXIST
    };
};

const mergeFileData = (v1: any, v2: any): PinData [] => {
    const v1Cids = v1.rows.map((p: any) => p.ipfs_pin_hash);
    for (const item of v2.items) {
        if (!v1Cids.includes(item.cid)) {
            v1.rows.push(shapeV2Data(item));
        }
    }

    const sorted = v1.rows.sort((a: any, b: any) => {
        const next = new Date(b.date_pinned).valueOf();
        const prev = new Date(a.date_pinned).valueOf();
        return next - prev;
    });
    return sorted;
};

export const pinJobs = (pageLimit: any, pageOffset: any) => async (dispatch: any) => {
    try {
        const response = await api.get(`pinning/pinJobs?limit=${pageLimit}&offset=${pageOffset}&sort=DESC`);
        dispatch({
            type: PIN_JOBS,
            payload: response?.data?.rows
        });
    } catch (error) {
        console.log(error);
    }
};

export const userPinList = (queryParams: any, folderDetails?: any) => async (dispatch: any) => {
    try {
        if (folderDetails) {
            dispatch(listFolderContents(folderDetails, queryParams.pageOffset, queryParams.pageLimit));
        } else {
            const query = new URLSearchParams();
            for (const key in queryParams) {
                let currentKey = key;
                const value = queryParams[key];

                if (currentKey === 'submarined') break;

                if (value !== undefined && value !== null && value !== '') {
                    query.append(currentKey, value);
                }
            }

            const url = `data/pinList${query.toString() ? '?' + query.toString() : ''}`;
            const urlV2 = `content${query.toString() ? '?' + query.toString().replace('pageLimit', 'limit').replace('pageOffset', 'offset') : ''}`;

            if (queryParams.submarined) {
                //  We are only making one request and that's to the v2 api
                const v2Response = await managedApi.get(urlV2);
                const data: any = {
                    rows: [],
                    count: v2Response?.data?.totalItems
                };
                for (const item of v2Response?.data?.items) {
                    data.rows.push(shapeV2Data(item));
                }

                dispatch({
                    type: USER_PIN_LIST_RETRIEVED,
                    payload: data
                });
            } else {
                const response = await api.get(url);
                //  @todo this is temporary as all v2 files are there or they are not
                const data = {...response.data};
                if (queryParams.status === 'pinned' || queryParams.status === 'all') {
                    try {
                        const responseV2 = await managedApi.get(urlV2);
                        const dataV2 = {...responseV2?.data};
                        if (dataV2) {
                            const rows = mergeFileData(data, dataV2);
                            data.rows = rows;
                            data.count = data.count + dataV2.totalItems;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                dispatch({
                    type: TRAVERSE_FOLDER,
                    payload: null
                });
                dispatch({ type: USER_PIN_LIST_RETRIEVED, payload: data });
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const userPinnedDataTotal = () => async (dispatch: any) => {
    try {
        //  @TODO V2 - Wire this up to fetch data from v2 endpoint as well
        const response = await api.get(`data/userPinnedDataTotal`);
        dispatch({
            type: USER_PINNED_DATA_TOTALS_RECEIVED,
            payload: response?.data
        });
    } catch (error) {
        console.log(error);
    }
};
