import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeDatePretty } from 'helpers/pretty';
import { metricLimitInfo } from 'helpers/enums';
import {
  Fade,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Alert
} from '@mui/material';

import { MetricLimitData } from '../../store/metrics/types';

interface PendingPinsModalProps {
  pinJobsModalOpen: boolean;
  userMetricLimit: MetricLimitData;
  setPinJobsModalOpen: (isOpen: boolean) => void;
  pinJobs: Array<any>;
  setAlert: (text: string, type: string) => void;
}

const PendingPinsModal = ({
  pinJobsModalOpen,
  setPinJobsModalOpen,
  pinJobs,
  userMetricLimit,
  setAlert
}: PendingPinsModalProps) => {
  const [pinJobsModalAnchorEl, setPinJobsModalAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const isPinJobsModalOpen = Boolean(pinJobsModalAnchorEl);

  const copyHash = (hash: string) => {
    navigator.clipboard.writeText(hash);
    setAlert('Copied!', 'success');
    setPinJobsModalAnchorEl(null);
  };

  const renderPinJobRow = (row: any, key: number) => {
    return (
      <TableRow key={key}>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <Link
            to="#"
            onClick={(event) => setPinJobsModalAnchorEl(event.currentTarget)}
            aria-controls={isPinJobsModalOpen ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isPinJobsModalOpen ? 'true' : undefined}
          >
            {row.ipfs_pin_hash}
          </Link>
          <Menu
            id="pinjob-menu"
            anchorEl={pinJobsModalAnchorEl}
            open={isPinJobsModalOpen}
            onClose={() => setPinJobsModalAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() => copyHash(row.ipfs_pin_hash)}>Copy CID</MenuItem>
          </Menu>
        </TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{makeDatePretty(row.date_queued)}</TableCell>
      </TableRow>
    );
  };
  return (
    <Dialog fullWidth maxWidth={'lg'} open={pinJobsModalOpen} onClose={() => setPinJobsModalOpen(false)}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert severity={userMetricLimit.type}>{userMetricLimit.text}</Alert>
      )}
      <DialogTitle className="m-0" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Pending Pins By CID Jobs
        <IconButton className="p-0" aria-label="close" onClick={() => setPinJobsModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>IPFS Hash</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date Queued</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{pinJobs?.length > 0 && pinJobs.map((job, key) => renderPinJobRow(job, key))}</TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PendingPinsModal;
