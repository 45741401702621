import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { connect } from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import { changeLayout, changeTopbarTheme, changeLayoutWidth } from '../../store/actions';
import Alert from "../Common/Alert";
import {useEffect, useState} from "react";
import {NavLink} from "reactstrap";
import {ListItem, ListItemButton, Snackbar, useTheme} from "@mui/material";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import VerticalAlert from "../Common/VerticalAlert";
import {PinataState} from "../../store/types";
import {AlertState} from "../../store/alert/types";
import CodeIcon from "@mui/icons-material/Code";


interface LayoutProps {
  changeLayout: any;
  Layout: any;
  children: any;
  alert: AlertState;
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function Layout(props: LayoutProps) {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const theme = useTheme();
  const {alert} = props;

  useEffect(() => {
    let currentage = location.pathname.replace('/', '');
    currentage = currentage.charAt(0).toUpperCase() + currentage.slice(1);

    document.title = `${currentage} | Pinata`;

    props.changeLayout('vertical');
  }, [location.pathname]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
      <Box sx={{ display: 'flex' }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
             <ProfileMenu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              height: '86px',
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListItem>
              <Link to="/pinmanager">
                <Typography color={theme.palette.primary.light}>
                  <i className="fas fa-file mr-2" />
                  { open && 'Files'}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/gateway">
                <Typography color={theme.palette.primary.light}>
                <i className="fas fa-bolt mr-2" />
                { open && 'Gateway'}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/cidchecker">
                <Typography color={theme.palette.primary.light}>
                <i className="fas fa-check-double mr-2" />
                { open && 'File CID Verifier'}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/developers">
                <Typography color={theme.palette.primary.light}>
                <CodeIcon />
                { open && 'Developers'}
                </Typography>
              </Link>
            </ListItem>
          </List>
          <Divider sx={{ my: 1 }} />
          <List component="nav" sx={{justifySelf: 'end'}}>
            <ListItemButton>
              <a className="pinata-link" href="https://pinata.cloud/privacy">
                <Typography> Privacy</Typography>
              </a>
            </ListItemButton>
            <ListItemButton>
              <a className="pinata-link" href="https://pinata.cloud/terms">
                <Typography> Terms and Conditions </Typography>
              </a>
          </ListItemButton>
            <ListItemButton>
              <a className="pinata-link" href="https://pinata.cloud/acceptableuse">
                <Typography> Acceptable Use </Typography>
              </a>
          </ListItemButton>
            <ListItemButton>
              <a className="pinata-link" href="https://pinata.cloud/dmca">
                <Typography> DMCA </Typography>
              </a>
          </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Container>
              <VerticalAlert alert={alert} />
              <div>{props.children}</div>
            </Container>
          </Container>
        </Box>
      </Box>
  );
}

const mapStatetoProps = (state: PinataState) => ({
  Layout: state.Layout,
  alert: state.alert
});

const mapDispatchToProps = {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth
};
export default connect(mapStatetoProps, mapDispatchToProps)(Layout);
