import React from 'react';
import { CustomInput } from 'reactstrap';
import { DOMAIN_PAGE } from './gatewayConstants';

const PrivacyPage = ({ setPage, setRestricted, restricted, createNewGateway }) => {
    return (
        <div>
            <h3>Gateway Access</h3>
            <p>
                Choose whether or not you want your gateway to be able to pull content from the whole IPFS network or
                just content you have pinned.
            </p>

            <div style={{ marginTop: 20, marginBottom: 20 }} className="form-group">
                <div>
                    <CustomInput
                        bsSize="lg"
                        size="lg"
                        style={{ fontSize: 24 }}
                        checked={restricted}
                        onChange={() => setRestricted(!restricted)}
                        type="switch"
                        id="privacy-switch"
                        name="privacy-switch"
                        label="Restricted Gateway"
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <button onClick={() => setPage(DOMAIN_PAGE)} className="btn">
                    Back
                </button>
                <button onClick={createNewGateway} className="btn btn-primary">
                    Create
                </button>
            </div>
        </div>
    );
};

export default PrivacyPage;
