import { prettySize } from 'helpers/pretty';
import React from 'react';
import { Col, Card } from 'reactstrap';
import {Typography} from "@mui/material";

interface BandwidthObj {
    domain: string;
    bandwidth: number;
    requests: number;
}

let grandTotalRequests = 0;
let grandTotalBandwidth = 0;
const Bandwidth = ({ usageData }: {usageData: BandwidthObj []}) => {
    grandTotalRequests = 0;
    grandTotalBandwidth = 0;
    return (
        <Col style={{ marginTop: 30, textAlign: "center" }} xs={12} md={12}>
            <Typography variant="h5">Bandwidth Used</Typography>
            {usageData.map((usage, index) => {
                grandTotalRequests += usage.requests;
                grandTotalBandwidth += usage.bandwidth;
                return (
                    <div key={index}>
                        <p>&nbsp;</p>
                        <h4>{usage.domain}</h4>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 70,
                                padding: 25,
                                border: '1px #556ee6 solid',
                                borderRadius: 1000,
                                height: 355,
                                width: 355,
                                margin: "auto"
                            }}
                        >
                            <h1>{prettySize(usage.bandwidth || 0)}</h1>
                        </div>
                        <p>Requests: {usage.requests}</p>
                    </div>
                );
            })}
            <Card color="light" style={{padding: 20}}>
                <h4>
                    Total
                    <br />
                    Requests: {grandTotalRequests} - Bandwidth: {prettySize(grandTotalBandwidth || 0)}
                </h4>
            </Card>
        </Col>
    );
};

export default Bandwidth;
