import React, { useState, useEffect } from 'react';
import { Spinner, Modal, Row, Alert, CustomInput, Container } from 'reactstrap';
import { metricLimitInfo } from '../../helpers/enums';

interface EditGatewayProps {
  editModalOpen: any;
  setEditModalOpen: any;
  gatewayDetails: any;
  updateGateway: any;
  currentPage: any;
  userMetricLimit: any;
}

const EditGateway = ({
  editModalOpen,
  setEditModalOpen,
  gatewayDetails,
  updateGateway,
  currentPage,
  userMetricLimit
}: EditGatewayProps) => {
  const [restricted, setRestricted] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (gatewayDetails && gatewayDetails.restrict !== undefined && gatewayDetails.restrict !== null) {
      setRestricted(gatewayDetails.restrict);
    }
  }, [gatewayDetails]);

  const handleUpdates = async () => {
    setLoading(true);
    const updatedGateway = {
      subdomain: gatewayDetails.domain,
      restricted: restricted,
      id: gatewayDetails.id
    };
    await updateGateway(updatedGateway, currentPage);
    setLoading(false);
    setEditModalOpen(false);
  };

  return (
    <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(false)} centered={true}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit.type}>
          {userMetricLimit.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update Gateway</h5>
        <button
          type="button"
          onClick={() => setEditModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <h4>Change Access Settings</h4>
            <Row>
              <div style={{ marginTop: 40, marginBottom: 40 }} className="form-group">
                <Container>
                  <CustomInput
                    bsSize="lg"
                    style={{ fontSize: 24 }}
                    checked={restricted}
                    onChange={() => setRestricted(!restricted)}
                    type="switch"
                    id="privacy-switch"
                    name="privacy-switch"
                    label="Restricted Gateway"
                  />
                </Container>
              </div>
            </Row>

            <button onClick={handleUpdates} className="btn btn-primary">
              Update
            </button>
            <button onClick={() => setEditModalOpen(false)} className="btn">
              Cancel
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditGateway;
