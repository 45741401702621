import ButtonWithState from 'components/Common/ButtonWithState';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { emailFieldRules } from 'helpers/formFieldValidations';
const SignInForm = ({ submitting }) => {
  const { control, getFieldState, formState } = useFormContext();

  const emailState = getFieldState('email');
  const passwordState = getFieldState('password');

  return (
    <div>
      <FormGroup>
        <Controller
          name="email"
          control={control}
          rules={emailFieldRules}
          render={({ field }) => (
            <>
              <Input
                {...field}
                type="email"
                placeholder="Enter email"
                invalid={emailState.invalid}
                autoComplete="current-email"
              />
              {emailState.invalid && <FormFeedback>{emailState.error.message}</FormFeedback>}
            </>
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="password"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Please enter your password'
            }
          }}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              placeholder="Enter password"
              invalid={passwordState.invalid}
              autoComplete="current-password"
            />
          )}
        />
        {passwordState.invalid && <FormFeedback>{passwordState.error.message}</FormFeedback>}
      </FormGroup>
      <ButtonWithState disabled={!formState.isValid} submitting={submitting}>
        Sign In
      </ButtonWithState>
    </div>
  );
};

export default SignInForm;
