import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import feature1 from "../../../assets/images/crypto/features-img/img-1.png"

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>You are good to go!</span>
  } else {
    // Render a countdown
    return (
      <>
        <div className="coming-box">
          {days}
          <span>Days</span>
        </div>
        <div className="coming-box">
          {hours}
          <span>Hours</span>
        </div>
        <div className="coming-box">
          {minutes}
          <span>Minutes</span>
        </div>
        <div className="coming-box">
          {seconds}
          <span>Seconds</span>
        </div>
      </>
    )
  }
}

const Section = () => {
  return (
    <React.Fragment>
      <section className="pinata-page-flex section hero-section" id="home">
        <div className=""/>
        <Container>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-black-50">
                <h1 className="text-black font-weight-semibold mb-3 hero-title">
                  Pinata Sync - Your NFT File Management System
                </h1>
                <p className="font-size-14">
                  NFTs have assets associated with them, but do you control those assets? Pinata Sync will automatically fetch token metadata, retreive the underlying assets 
                  and pin them to IPFS for you.
                </p>

                <div className="button-items mt-4">
                  <Link to="#" className="btn btn-primary mr-1">
                    Get On The Waiting List
                  </Link>                  
                </div>
              </div>
            </Col>            
            <Col md="6" sm="8" lg="6">
              <div>
                <img
                  src={feature1}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Section
