import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import PaymentInfo from './PaymentInfo';
import CardPricing from '../Pricing/CardPricing';
import BillingHistory from './BillingHistory';
import CurrentPlanInfo from './CurrentPlanInfo';
import ConfirmationModal from 'pages/Gateway/ConfirmationModal';
import { planTypes } from 'helpers/enums';
import { cancelUserAccount, loadUserInfo, restoreCanceledAccount } from '../../store/user/user.actions';
import { retrieveStripeCustomer } from '../../store/billing/billing.actions';
import { getUsageMetrics } from 'store/metrics/metrics.actions';
import { BillingPlan, BillingState, Plan } from '../../store/billing/types';
import BillingAddressModal from './BillingAddressModal';
import { MetricsState } from '../../store/metrics/types';
import { CircularProgress, Typography } from '@mui/material';
import { User, UserState } from '../../store/user/types';
import dayjs from 'dayjs';

interface UsageCardProps {
  data: any;
  billing: BillingState;
  setAlert: any;
  changePlan: any;
  gateways: any;
  killGateways: any;
  cancelUserAccount: any;
  getUsageMetrics: () => void;
  metrics: MetricsState;
  user: UserState;
  apiKeys: any;
  tabHasFocus: boolean;
  restoreCanceledAccount: () => void;
  loadUserInfo: (user: User) => Promise<any>;
}

const UsageCard = ({
  billing,
  changePlan,
  gateways,
  killGateways,
  getUsageMetrics,
  metrics,
  user,
  restoreCanceledAccount,
  loadUserInfo
}: UsageCardProps) => {
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planToChangeTo, setPlanChoice] = useState<any>(null);
  const [planChoiceConfirmationOpen, setPlanChangeConfirmationOpen] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false); //Boolean or null if no default
  const [changePlanAfterCardAdded, setChangePlanAfterCardAdded] = useState(false);
  const [openBillingAddressModal, setOpenBillingAddressModal] = useState(false);
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [metricsInterval, setMetricsInterval] = useState<any>(null);
  const [restorePlanModalOpen, setRestorePlanModalOpen] = useState(false);

  const location: any = useLocation();

  const changePlanLocal = async (planToChangeTo: any) => {
    // if (
    //   planToChangeTo.type !== planTypes.FREE.type &&
    //   (!(billing?.stripe_customer?.address?.country?.length > 0) ||
    //     !(billing?.stripe_customer?.address?.postalCode?.length > 0))
    // ) {
    //   setOpenBillingAddressModal(true);
    // } else {
    try {
      const changePlanRes = await changePlan(planToChangeTo);
      if (!changePlanRes.nextPlan) {
        scheduleUsageMetrics();
      }
    } catch (error) {
      clearInterval(metricsInterval);
      setUpdatingPlan(false);
    } finally {
      setLoading(false);
    }
    // }
  };

  // Scroll to anchor hash
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop - 75,
        behavior: 'smooth'
      });
    };

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, []);

  useEffect(() => {
    // check if user came from marketing site and wants to change plan
    if (location?.state?.registerFromMarketing) {
      const currentPlan = billing?.activePricingPlan;
      const desiredPlan = billing?.billing_plans?.find((item) => item.nickname === location?.state?.desiredPlan);
      if (desiredPlan && currentPlan) {
        handlePlanChoice(desiredPlan);
        localStorage.removeItem('pinata-registration-plan-selection');
      }
    }
  }, []);

  const handlePlanChoice = async (plan: Plan) => {
    setPlanChoice(plan);
    if (!billing.stripe_customer.paymentMethods.length && plan.type !== planTypes.FREE.type) {
      // if there is no payment method -> add credit card
      setChangePlanAfterCardAdded(true);
      setOpenCardModal(true);
      return;
    }
    if (user.user?.scheduledToBeCancelledAt) {
      setRestorePlanModalOpen(true);
    } else if (
      (billing?.activePricingPlan &&
        plan.id !== billing?.activePricingPlan?.id &&
        plan.type > billing?.activePricingPlan?.type) ||
      billing?.activePricingPlan?.isLegacy
    ) {
      // if planToChange's type is greater than current - we just confirm and change it
      setPlanChangeConfirmationOpen(true);
    } else {
      // Need to kill off gateways and alert them
      setChangePlanModalOpen(true);
    }
  };

  const scheduleUsageMetrics = () => {
    setUpdatingPlan(true);
    const updateInterval = setInterval(() => {
      getUsageMetrics();
    }, 2500);
    setMetricsInterval(updateInterval);
  };

  const confirmProfessionalUpgrade = async () => {
    setLoading(true);
    try {
      if (billing.stripe_customer) {
        await changePlanLocal(planToChangeTo);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPlanChangeConfirmationOpen(false);
    }
  };

  const confirmRestorePlan = async () => {
    setLoading(true);
    try {
      await restoreCanceledAccount();
    } catch (error) {
      console.log(error);
    } finally {
      if (user.user) {
        await loadUserInfo(user.user);
      }
      setRestorePlanModalOpen(false);
    }
  };

  useEffect(() => {
    if (metricsInterval) {
      clearInterval(metricsInterval);
      setUpdatingPlan(false);
      window.Intercom('shutdown');
      const reloadTimeout = setTimeout(() => {
        // window.Intercom('boot', {
        //   app_id: process.env.REACT_AP P_INTERCOM_ID
        // });
        getUsageMetrics();
      }, 1500);
      return () => clearTimeout(reloadTimeout);
    }
    setUpdatingPlan(false);
  }, [metrics.metrics.metricsAccount.fileCount.limit]);

  useEffect(() => {
    const timer = setInterval(getUsageMetrics, 60000);
    return () => clearInterval(timer);
  }, []);

  const confirmDowngrade = async () => {
    setLoading(true);
    try {
      await changePlanLocal(planToChangeTo);
    } catch (error) {
      console.log(error);
    } finally {
      setChangePlanModalOpen(false);
    }
  };

  const getChangePlanText = useMemo(() => {
    return (
      <>
        <p>
          {!billing?.activePricingPlan?.isLegacy && planToChangeTo?.type === planTypes.FREE.type
            ? `By downgrading your plan, any dedicated gateways you've created will be removed and you won't be able to access them.`
            : billing?.activePricingPlan?.isLegacy
            ? `Are you sure you want to change your plan?`
            : billing?.activePricingPlan?.type === planToChangeTo?.type
            ? "Are you sure you want to cancel your downgrade and keep your current plan?"
            : `Are you sure you want to downgrade? If your usage exceeds 125% of the plan you downgrade to, your account will be locked.`}
          {planToChangeTo?.type === planTypes.FREE.type
            ? 'Also the payments methods will be removed at the end of the billing period.'
            : ''}
        </p>
        {billing?.nextBillingDate && billing?.activePricingPlan?.type !== planToChangeTo?.type && (
          <p>
            This change will take effect on <strong>{billing?.nextBillingDate}</strong>. Please continue to enjoy the
            features of the <strong>{billing?.activePricingPlan?.nickname}</strong> until then.
          </p>
        )}
      </>
    );
  }, [billing?.activePricingPlan, billing?.nextBillingDate, planToChangeTo]);

  return (
    <>
      <Row className="my-4">
        <Col xs={12} md={6}>
          {updatingPlan ? (
            <div className="updating-plan-spinner">
              <Typography>We're updating your plan</Typography>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <CurrentPlanInfo billing={billing} />
          )}
        </Col>
        <Col xs={12} md={6} className="d-flex flex-column justify-content-between">
          <PaymentInfo
            openCardModal={openCardModal}
            changePlanAfterCardAdded={changePlanAfterCardAdded}
            actionAfterCardAdded={() => {
              setPlanChangeConfirmationOpen(true);
              setChangePlanAfterCardAdded(false);
            }}
            setOpenCardModal={setOpenCardModal}
          />
          <BillingHistory />
        </Col>
      </Row>
      {billing?.activePricingPlan?.type === planTypes.ENTERPRISE.type ? (
        <Alert color="primary" className="text-center">
          Please <a href="mailto:sales@pinata.cloud">contact</a> your account manager to adjust your plan.
        </Alert>
      ) : (
        !!billing?.billing_plans?.length && (
          <Row className="my-4" id="pricing-plans">
            <Col xs={12} md={12} className="d-flex align-items-center flex-column">
              <ul id="pinata-pricing-cards-list" className="list-unstyled m-0 p-0 w-100">
                {billing?.billing_plans.map((pricing: BillingPlan) => (
                  <CardPricing
                    plan={pricing}
                    key={pricing.id}
                    action={handlePlanChoice}
                    currentPlan={billing?.activePricingPlan}
                    nextPlan={billing?.nextPlan}
                  />
                ))}
              </ul>
            </Col>
          </Row>
        )
      )}
      {changePlanModalOpen && (
        <ConfirmationModal
          title="Change Plan?"
          text={getChangePlanText}
          modalOpen={changePlanModalOpen}
          toggleModal={setChangePlanModalOpen}
          loading={loading}
          action={confirmDowngrade}
        />
      )}
      {planChoiceConfirmationOpen && (
        <ConfirmationModal
          title={`Upgrade to ${planToChangeTo.nickname}?`}
          text={`Are you sure you want to upgrade to the ${planToChangeTo.nickname} plan for $${planToChangeTo.price}/month?`}
          modalOpen={planChoiceConfirmationOpen}
          toggleModal={setPlanChangeConfirmationOpen}
          loading={loading}
          action={confirmProfessionalUpgrade}
        />
      )}
      {restorePlanModalOpen && (
        <ConfirmationModal
          title={`Restore Plan?`}
          text={`Your plan is scheduled to be cancelled by ${dayjs(user.user?.scheduledToBeCancelledAt).format(
            'YYYY-MM-DD'
          )}, in order to change your plan you need first to restore your plan`}
          modalOpen={restorePlanModalOpen}
          toggleModal={setRestorePlanModalOpen}
          loading={loading}
          action={confirmRestorePlan}
        />
      )}
      {openBillingAddressModal && (
        <BillingAddressModal
          setModalOpen={setOpenBillingAddressModal}
          modalIsOpen={openBillingAddressModal}
          mainActionHandler={() => changePlan(planToChangeTo)}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  cancelUserAccount,
  getUsageMetrics,
  restoreCanceledAccount,
  loadUserInfo,
  retrieveStripeCustomer
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    apiKeys: state.apiKeys
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsageCard);
