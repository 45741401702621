import { metricLimitInfo } from './enums';
import type { Metrics } from 'store/metrics/types';

const UNITS: { [key: string]: number } = {
  kb: 1,
  mb: 2,
  gb: 3
};

export const bytesToUnit = (bytes: number, unit: string) => {
  // default to gigabytes
  return bytes / 1000 ** (UNITS[unit] || 3);
};

export const formatTotal = (amount: number) => {
  return `$${(amount / 100).toFixed(2)}`;
};

const getBannerTypeByPercentage = (maxMetricPercentage: number) => {
  let bannerType = metricLimitInfo.PLAN_OK;

  if (maxMetricPercentage >= metricLimitInfo.PLAN_ABOVE_80.value) {
    bannerType = metricLimitInfo.PLAN_ABOVE_80;
  }
  if (maxMetricPercentage >= metricLimitInfo.PLAN_ABOVE_90.value) {
    bannerType = metricLimitInfo.PLAN_ABOVE_90;
  }
  if (maxMetricPercentage >= metricLimitInfo.PLAN_ABOVE_100.value) {
    bannerType = metricLimitInfo.PLAN_ABOVE_100;
  }
  if (maxMetricPercentage >= metricLimitInfo.PLAN_ABOVE_110.value) {
    bannerType = metricLimitInfo.PLAN_ABOVE_110;
  }
  if (maxMetricPercentage >= metricLimitInfo.PLAN_ABOVE_120.value) {
    bannerType = metricLimitInfo.PLAN_ABOVE_120;
  }
  if (maxMetricPercentage >= metricLimitInfo.PLAN_ABOVE_125.value) {
    bannerType = metricLimitInfo.PLAN_ABOVE_125;
  }
  return bannerType;
};

export const getMetricsLimitData = (metrics: Metrics) => {
  const { metricsAccount, metricsMonthly } = metrics;
  const sortedPercentages = Object.values({
    ...metricsAccount,
    ...metricsMonthly
  }).sort((a, b) => a.percentage - b.percentage);

  const highestPct = sortedPercentages.pop();

  if (highestPct) {
    if (highestPct.title !== 'Total Gateways' || highestPct.percentage > 100) {
      return getBannerTypeByPercentage(highestPct.percentage);
    } else {
      const nextHighestPct = sortedPercentages.pop();
      return getBannerTypeByPercentage(nextHighestPct?.percentage || 0);
    }
  }
  return metricLimitInfo.PLAN_OK;
};
