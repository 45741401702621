import { useAuth } from 'hooks/useAuth';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import EditAccountDetails from './EditAccountDetails';
import QRModal from './QRModal';
import { verifytTotp } from '../../hooks/useAuth';
import { Button } from '@mui/material';
import { Typography, Box, Card, CardContent } from '@mui/material';

const AccountDetailsCard = ({ user, setAlert, changeEmail }) => {
  const { getAuthenticatedUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [mfaConfigured, setMfaConfigured] = useState(false);
  const [qrModalOpen, setQRModal] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [authenticatorCode, setAuthenticatorCode] = useState('');
  const { getMFAOptions, setupTotp, setPreferredMFA } = useAuth();

  useEffect(() => {
    checkOnMFA();
  }, []);

  const checkOnMFA = async () => {
    const mfa = await getMFAOptions();
    if (mfa === 'NOMFA') {
      setMfaConfigured(false);
    } else {
      setMfaConfigured(true);
    }
  };

  const setupMFA = async () => {
    try {
      setLoading(true);
      const code = await setupTotp();
      setLoading(false);
      if (code) {
        setMfaCode(code);
        setQRModal(true);
      }
    } catch (error) {
      setLoading(false);
      setAlert(error.message, 'error');
    }
  };

  const disableMFA = async () => {
    try {
      setLoading(true);
      await setPreferredMFA('NOMFA');
      setLoading(false);
      checkOnMFA();
      setAlert('MFA Disabled', 'success');
    } catch (error) {
      setLoading(false);
      setAlert(error.message, 'error');
    }
  };

  const submitAuthenticatorCode = async () => {
    try {
      setQRModal(false);
      await verifytTotp(authenticatorCode);
      checkOnMFA();
      setAlert('MFA Configured!', 'success');
      setAuthenticatorCode('');
      setMfaCode('');
    } catch (error) {
      setAlert(error.message, 'error');
    }
  };

  useEffect(() => {
    getCognitoUser();
  }, [editing]);

  const getCognitoUser = async () => {
    const cogUser = await getAuthenticatedUser();
    setLoggedInUser(cogUser);
  };
  const getName = (nameType) => {
    if (nameType === 'first') {
      return loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName']
        ? loggedInUser.attributes['custom:firstName']
        : '';
    } else {
      return loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:lastName']
        ? loggedInUser.attributes['custom:lastName']
        : '';
    }
  };
  return (
    <Box sx={{ marginBottom: 5 }}>
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardContent>
                {editing ? (
                  <EditAccountDetails
                    changeEmail={changeEmail}
                    setEditing={setEditing}
                    setAlert={setAlert}
                    user={user}
                    loggedInUser={loggedInUser}
                  />
                ) : (
                  <Box>
                    <Typography variant="h5">Account Info</Typography>
                    <Typography variant="h6">Email</Typography>
                    <Typography variant="body2">
                      <i style={{ color: '#34c38f' }} className="far fa-check-circle"></i>
                      {user && user.email}
                    </Typography>
                    {loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName'] && (
                      <div>
                        <Typography variant="h6">Name</Typography>
                        <Typography variant="body2">
                          {getName('first')} {getName('last')}
                        </Typography>
                      </div>
                    )}
                    <Button onClick={() => setEditing(true)}>Edit Profile</Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5">Multi-Factor Authentication</Typography>
                {mfaConfigured ? (
                  <button onClick={disableMFA} className="btn btn-pinataSecondary">
                    {loading ? 'Disabling...' : 'Disable MFA'}
                  </button>
                ) : (
                  <Button onClick={setupMFA} color={'info'}>
                    {loading ? 'Loading MFA Code...' : 'Set Up MFA'}
                  </Button>
                )}
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
      {mfaCode && (
        <QRModal
          submitAuthenticatorCode={submitAuthenticatorCode}
          authenticatorCode={authenticatorCode}
          setAuthenticatorCode={setAuthenticatorCode}
          code={mfaCode}
          setQRModal={setQRModal}
          qrModalOpen={qrModalOpen}
          email={user.email}
        />
      )}
    </Box>
  );
};

export default AccountDetailsCard;
