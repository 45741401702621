import React from 'react';
import { useLocation } from 'react-router-dom';

import analytics from '../services/googleAnalytics';

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    analytics.init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    // every time the path changes, send a pageview event
    analytics.sendPageview(currentPath);
  }, [location]);
}
