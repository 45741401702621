import { combineReducers } from 'redux';
import Layout from './layout/reducer';
import user from './user/user.reducer';
import alert from './alert/alert.reducer';
import data from './data/data.reducer';
import apiKeys from './apiKeys/apikeys.reducers';
import billing from './billing/billing.reducers';
import gateways from './gateways/gatways.reducer';
import metrics from './metrics/metrics.reducer';
import { LOG_OUT } from 'actions/types';
import {PinataState} from "./types";

const appReducer = combineReducers<PinataState>({
  Layout,
  user,
  alert,
  data,
  apiKeys,
  billing,
  gateways,
  metrics
});

const rootReducer = (state = undefined, action: any) => {
  if (action.type === LOG_OUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
