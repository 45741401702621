import React from 'react';
import { connect } from 'react-redux';
import { Modal, Alert } from 'reactstrap';
import { metricLimitInfo } from 'helpers/enums';
import {PinataState} from "../../store/types";

interface GeneralModalProps {
  isOpen: boolean;
  setIsOpen: (newIsOpen: boolean) => void;
  handleAction: () => void;
  title: string;
  content: string;
  actionName: string;
  actionBtn: string;
  handleClose?: () => void;
  disabled?: boolean;
  userMetricLimit?: {
    title: string;
    type: string;
    text: string;
  };
}

function GeneralModal(props: GeneralModalProps) {
  const {
    isOpen,
    setIsOpen,
    handleAction,
    title,
    content,
    actionName,
    actionBtn,
    handleClose,
    disabled,
    userMetricLimit
  } = props;

  const handleCloseModal = () => {
    handleClose && handleClose();
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} centered={true} className={'general-modal'}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit?.type}>
          {userMetricLimit?.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button type="button" onClick={handleCloseModal} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h4 className={'content'}>{content || 'Are you sure?'}</h4>
        <button onClick={handleAction} className={`btn ${actionBtn}`} disabled={Boolean(disabled)}>
          {actionName}
        </button>
        <button onClick={handleCloseModal} className="btn btn-secondary">
          Cancel
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: PinataState) => ({
  userMetricLimit: state?.metrics?.metricsLimitData
});

export default connect(mapStateToProps, null)(GeneralModal);
