import React from 'react';
import { DOMAIN_PAGE } from './gatewayConstants';

const IntroPage = ({ setPage, gateways }) => {
  if (gateways && gateways.gateways && gateways.gateways.rows && gateways.gateways.rows.length > 0) {
    return (
      <div>
        <h3>Pricing</h3>
        <p>
          You will be charged <strong>$10 per month</strong> for each additional gateway added.
        </p>
        <p>Any additional gateway shares the 100GB of bandwidth included as part of your Picnic Plan.</p>
        <p className="pinata-muted">Each additional GB of bandwidth used by your gateways beyond 100GB is $0.05/GB.</p>
        <h4>Ready to get started?</h4>
        <button style={{ marginTop: 20 }} onClick={() => setPage(DOMAIN_PAGE)} className="btn btn-pinataSecondary">
          Next <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <h3>Pricing</h3>
        <h4>$20/month</h4>
        <ul>
          <li>Open or restricted gateway</li>
          <li>Custom domain</li>
          <li>100GB bandwidth per month</li>
        </ul>
        <p className="pinata-muted">* $0.05 per GB of bandwidth after 100GB</p>
        <h4>Ready to get started?</h4>
        <button style={{ marginTop: 20 }} onClick={() => setPage(DOMAIN_PAGE)} className="btn btn-pinataSecondary">
          Next <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    );
  }
};

export default IntroPage;
