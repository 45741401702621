import React from 'react';
import QRCode from 'qrcode.react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Button,
  Typography,
  useTheme
} from '@mui/material';

interface QRModalProps {
  qrModalOpen: boolean;
  setQRModal: (isOpen: boolean) => void;
  code: string;
  email: string;
  authenticatorCode: string | number;
  setAuthenticatorCode: (code: string) => void;
  submitAuthenticatorCode: () => void;
}
const QRModal = ({
  qrModalOpen,
  setQRModal,
  code,
  email,
  authenticatorCode,
  setAuthenticatorCode,
  submitAuthenticatorCode
}: QRModalProps) => {
  const uri = `otpauth://totp/Pinata:${email}?secret=${code}&issuer=Pinata&label=Pinata&`;
  const theme = useTheme();
  return (
    <Dialog open={qrModalOpen} onClose={() => setQRModal(false)}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        MFA Code
        <IconButton className="p-0" aria-label="close" onClick={() => setQRModal(false)}>
          <span aria-hidden="true">&times;</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container flexDirection="column" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="body2">
                Scan this code with the authenticator app of your choice enter type in the code in the box below.
              </Typography>
            </Grid>
            <Grid item>
              <QRCode fgColor={theme.palette.primary.main} size={256} includeMargin={true} value={uri} />
            </Grid>
            <Grid item>
              <TextField
                label="code"
                value={authenticatorCode}
                onChange={(e) => {
                  setAuthenticatorCode(e.target.value);
                }}
                placeholder="Enter code from authenticator app"
              />
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: 1 }} item container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth onClick={submitAuthenticatorCode} disabled={!authenticatorCode}>
                Submit
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={() => setQRModal(false)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default QRModal;
