import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { sendPasswordReset, forgotPasswordSubmit } from 'hooks/useAuth';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Alert, Box, TextField } from '@mui/material';
import ButtonWithState from 'components/Common/ButtonWithState';
import { codeFieldRules, emailFieldRules, passwordFieldRules } from 'helpers/formFieldValidations';
import logoImg from '../../assets/images/logo.svg';
import { connect } from 'react-redux';
import { setAlert } from '../../store/alert/alert.actions';

const ResetPasswordForm = ({ setAuthError, setAlert }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [showConfirmCode, setShowConfirmCode] = React.useState(false);
  const { control, handleSubmit, getFieldState, formState, getValues } = useFormContext();
  const history = useHistory();

  useEffect(() => {
    return () => {
      setAuthError(null);
      setSubmitting(false);
      setShowConfirmCode(false);
    };
  }, []);

  const emailState = getFieldState('email');
  const codeState = getFieldState('code');
  const passwordState = getFieldState('password');
  const passwordConfirmState = getFieldState('passwordConfirm');

  const canSubmit = !Object.keys(formState?.errors).length;

  async function sendPasswordResetCode(data) {
    try {
      await sendPasswordReset(data.email);
      // once user sent code -> show new password fields and code field
      setShowConfirmCode(true);
      setAlert('Reset password instructions sent to your email!', 'success');
      console.log('email sent');
    } catch (error) {
      console.log(error);
      // setAuthError(error.message);
      setAlert(error.message, 'error');
    } finally {
      setSubmitting(false);
    }
  }

  async function sendPasswordConfirm(data) {
    try {
      await forgotPasswordSubmit(data.email, data.code, data.password);
      setAlert('Password was successfully updated!', 'success');
      history.push('/signin');
    } catch (error) {
      console.log(error);
      // setAuthError(error.message);
      setAlert(error.message, 'error');
    } finally {
      setSubmitting(false);
    }
  }

  function handleFormSubmit(data) {
    setAuthError(null);
    setSubmitting(true);
    if (showConfirmCode) {
      sendPasswordConfirm(data);
    } else {
      sendPasswordResetCode(data);
    }
  }

  return (
    <Box
      component="form"
      sx={{
        width: '100%',
        '& .MuiTextField-root': {
          width: '100%',
          '& .MuiFormLabel-root': {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit'
          }
        },
        '& .MuiOutlinedInput-root': {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit'
        }
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Controller
        name="email"
        control={control}
        rules={emailFieldRules}
        render={({ field }) => (
          <TextField
            {...field}
            margin="normal"
            label="Email"
            disabled={!!showConfirmCode}
            fullWidth
            size="small"
            error={emailState.invalid}
            helperText={emailState.invalid && emailState.error.message}
          />
        )}
      />
      {showConfirmCode && (
        <>
          <Controller
            name="password"
            control={control}
            rules={{ ...passwordFieldRules, deps: 'passwordConfirm' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                type="password"
                label="Enter password"
                size="small"
                fullWidth
                error={passwordState.invalid}
                helperText={passwordState.invalid && passwordState.error.message}
                autoComplete="new-password"
              />
            )}
          />
          <Controller
            name="passwordConfirm"
            control={control}
            rules={{
              ...passwordFieldRules,
              deps: 'password',
              validate: (val) => val === getValues('password') || 'Passwords do not match'
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                type="password"
                label="Confirm password"
                size="small"
                fullWidth
                error={passwordConfirmState.invalid}
                helperText={passwordConfirmState.invalid && passwordConfirmState.error.message}
                autoComplete="new-password"
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            rules={codeFieldRules}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                label="Enter code"
                size="small"
                fullWidth
                error={codeState.invalid}
                helperText={codeState.invalid && codeState.error.message}
                autoComplete="one-time-code"
              />
            )}
          />
        </>
      )}
      <div className="my-2 w-100">
        <ButtonWithState disabled={!canSubmit} submitting={submitting} />
      </div>
    </Box>
  );
};

const ResetPassword = ({ setAlert }) => {
  const [authError, setAuthError] = React.useState(null);
  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
      code: '',
      passwordConfirm: ''
    },
    shouldUnregister: true
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div className="mt-5 text-center">
              <p style={{ fontSize: 16 }}>
                Need to register for an account?{' '}
                <Link to="/register" className="font-weight-medium text-primary">
                  Register
                </Link>
              </p>
            </div>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <h5 className="text-white p-4">Reset password</h5>
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logoImg} alt="" className="rounded-circle" height="34" />
                      </span>
                    </div>
                  </Link>
                </div>
                {authError && (
                  <Alert className="my-2" color="error">
                    {authError}
                  </Alert>
                )}
                <FormProvider {...methods}>
                  <ResetPasswordForm setAuthError={setAuthError} setAlert={setAlert} />
                </FormProvider>
                <Link to="/signin" className="btn btn-block text-primary">
                  Back to Sign In
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, { setAlert })(ResetPassword);
