import React, { useState } from 'react';
import CIDCheckUploader from './CIDCheckUploader';
import {Card, CardContent, Container, Typography} from "@mui/material";

const CIDVerifier = () => {
  const [cid, setCid] = useState('');
  return (
        <Container>
          <Typography variant="h3">IPFS CID Verifier</Typography>
          <div>
            <Card>
              <CardContent>
                <Typography variant="h6">Verify a File's Authenticity</Typography>
                <p>
                  With the IPFS CID Verifier, you can upload a file, receive an IPFS CID, and ensure the file is exactly
                  the one you expect. All without the file being added to the IPFS network.
                </p>
                <div style={{ marginTop: 20 }}>
                  {cid ? (
                    <div className="pinata-cid-verified">
                      <h4>Your File's CID</h4>
                      <h5>
                        <mark>{cid}</mark>
                      </h5>
                      <button onClick={() => setCid('')} className="btn btn-primary">
                        Check Another File
                      </button>
                    </div>
                  ) : (
                    <CIDCheckUploader setCid={setCid} cid={cid} />
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </Container>
  );
};

export default CIDVerifier;
