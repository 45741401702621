import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Box, Button, CardContent, Card, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Row, Col } from 'reactstrap';
import EditAccountDetails from './EditAccountDetails';
import ConfirmationModal from '../Gateway/ConfirmationModal';
import { useAuth } from 'hooks/useAuth';
import { planTypes } from '../../helpers/enums';
import { BillingState, Plan } from '../../store/billing/types';
import { User, UserState } from '../../store/user/types';

interface AccountManagementProps {
  user: UserState;
  setAlert: any;
  changeEmail: (newMail: string) => void;
  currentPlan: Plan | null;
  cancelUserAccount: () => void;
  restoreCanceledAccount: () => void;
  loadUserInfo: (user: User) => void;
  billing: BillingState;
}

const AccountManagementCard = (props: AccountManagementProps) => {
  const { user, setAlert, changeEmail, currentPlan, billing, cancelUserAccount, restoreCanceledAccount, loadUserInfo } =
    props;
  const { loggedInUser: cogUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(cogUser);
  const [mfaConfigured, setMfaConfigured] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getMFAOptions } = useAuth();
  const [cancelAccountConfirmationOpen, setCancelAccountConfirmationOpen] = useState(false);

  useEffect(() => {
    checkOnMFA();
  }, []);

  const checkOnMFA = async () => {
    const mfa = await getMFAOptions();
    if (mfa === 'NOMFA') {
      setMfaConfigured(false);
    } else {
      setMfaConfigured(true);
    }
  };

  const confirmAccountCancel = async () => {
    setLoading(true);
    try {
      await cancelUserAccount();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setCancelAccountConfirmationOpen(false);
      if (user?.user) {
        loadUserInfo(user.user);
      }
    }
  };

  const accountCancelationModalContent = React.useMemo(
    () => (
      <div className="d-flex flex-column justify-content-between">
        {currentPlan?.type === planTypes.FREE.type ? (
          <Typography variant="body1">This cannot be undone, and by cancelling, the following will occur:</Typography>
        ) : (
          <Typography variant="body1">
            By cancelling, the following will occur at the end of your current billing cycle:
          </Typography>
        )}
        <List dense>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="Account will be deleted" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="You will no longer be able to log in" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="All data will be unpinned from Pinata IPFS storage nodes" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="Private data will be deleted (if applicable)" />
          </ListItem>
        </List>
      </div>
    ),
    []
  );

  const handleRestoreAccount = async () => {
    try {
      await restoreCanceledAccount();
      if (user?.user) {
        loadUserInfo(user.user);
      }
    } catch (error) {
      setAlert('error', error);
    }
  };

  return (
    <Box sx={{ marginBottom: 5 }}>
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardContent>
                {editing ? (
                  <EditAccountDetails
                    changeEmail={changeEmail}
                    setEditing={setEditing}
                    setAlert={setAlert}
                    user={user}
                    loggedInUser={loggedInUser}
                  />
                ) : (
                  <div>
                    <Typography variant="h5">Account Info</Typography>
                    <Typography variant="h6">Current Plan</Typography>
                    <Typography variant="body2">
                      {(currentPlan?.type !== planTypes.ENTERPRISE.type )? currentPlan?.nickname : currentPlan?.name}
                    </Typography>{
                      user.user?.scheduledToBeCancelledAt ? (
                      <>
                        <Typography variant="h6">Plan expiration date</Typography>
                        <Typography variant="body2">{dayjs(billing.nextBillingDate).format('YYYY-MM-DD')}</Typography>
                        <Button onClick={handleRestoreAccount}>Restore Plan</Button>
                      </>
                    ) : (
                      <Button onClick={() => setCancelAccountConfirmationOpen(true)}>Cancel Plan</Button>
                    )}
                  </div>
                )}
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
      {cancelAccountConfirmationOpen && (
        <ConfirmationModal
          title="Are you sure you want to cancel your account?"
          modalOpen={cancelAccountConfirmationOpen}
          modalContent={accountCancelationModalContent}
          toggleModal={setCancelAccountConfirmationOpen}
          loading={loading}
          action={confirmAccountCancel}
          confirmButtonText="Cancel Account"
          cancelButtonText="Actually, I will keep my account"
          confirmButtonColor={'error'}
        />
      )}
    </Box>
  );
};

export default AccountManagementCard;
