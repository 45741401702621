import React from 'react';
import { Modal, Alert } from 'reactstrap';
import { metricLimitInfo } from 'helpers/enums';

const MakePublicModal = ({ hash, makePublicModalOpen, setMakePublicModalOpen, handleMakePublic, userMetricLimit }) => {
  return (
    <Modal isOpen={makePublicModalOpen} toggle={() => setMakePublicModalOpen(false)} centered={true}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit.type}>
          {userMetricLimit.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Stop Submarining</h5>
        <button
          type="button"
          onClick={() => setMakePublicModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h3>Are you sure you want to stop submarining this content and make it public?</h3>
        <p>CID: {hash}</p>
        <p>You will not be able to submarine it again once it is on the public IPFS network.</p>
        <p>How does this work?</p>
        <ul style={{ marginBottom: 50 }}>
          <li>Your content is added to a queue</li>
          <li>When it's your file's turn, it will be added to the public IPFS network</li>
          <li>The Pin Manager will show that the file is no longer submarined</li>
          <li>This process can take some time, so the status of your file will not update immediately</li>
        </ul>
        <button onClick={handleMakePublic} className="btn btn-primary">
          Make it public
        </button>
        <button onClick={() => setMakePublicModalOpen(false)} className="btn">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default MakePublicModal;
