import React from 'react'
import {
  Modal,
} from "reactstrap"

const DeleteKeyModal = ({ confirmDeleteKey, keyInfo, deleteKeyModalOpen, setDeleteKeyModalOpen }) => {
  return (
    <Modal
      isOpen={deleteKeyModalOpen}
      toggle={() => setDeleteKeyModalOpen(false)}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Revoke API Key</h5>
        <button
          type="button"
          onClick={() => setDeleteKeyModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div>
          <h3>Are you sure you want to revoke this key?</h3>
          <p>{keyInfo.key}</p>
          <p>This cannot be undone.</p>
        </div>
        
        <button onClick={confirmDeleteKey} className="btn btn-danger">Revoke Key</button>
        <button onClick={() => setDeleteKeyModalOpen(false)} className="btn">Cancel</button>
      </div>
    </Modal>
  )
}

export default DeleteKeyModal
