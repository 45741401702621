import { api, managedApi } from 'services/api';
import { setAlert } from '../alert/alert.actions';
import { API_KEYS_LOADED, V2_KEYS_LOADED } from '../../actions/types';
import {KeyPermissions} from "./types";

export const generateNewApiKey = (chosenPermissions: KeyPermissions, maxUses: string, keyName: string) => async (dispatch: any) => {
  try {
    if (chosenPermissions.admin === true) {
      delete chosenPermissions.endpoints;
    } else {
      delete chosenPermissions.admin;
    }
    const body = {
      permissions: chosenPermissions,
      maxUses: maxUses && parseInt(maxUses, 10) > 0 ? maxUses : null,
      keyName
    };

    const res = await api.post(`users/generateApiKey`, body);
    dispatch(fetchApiKeys(0));
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const revokeApiKey = (apiKey: string) => async (dispatch: any) => {
  try {
    const res = await api.put(`users/revokeApiKey`, {
      apiKey
    });
    dispatch(fetchApiKeys(0));
    dispatch(setAlert('Key Revoked!', 'success'));
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchApiKeys = (offset: number) => async (dispatch: any) => {
  try {
    const res = await api.get(`users/apiKeys?offset=${offset}`);
    dispatch({
      type: API_KEYS_LOADED,
      payload: res?.data
    });
  } catch (error) {
    console.log(error);
  }
};

export const getV2Keys = (offset?: number, limit?: number) => async (dispatch: any) => {
  try {
    const res = await managedApi.get(`auth/keys?offset=${offset}&limit=${limit}`);
    dispatch({
      type: V2_KEYS_LOADED,
      payload: res?.data
    });
  } catch (error) {
    console.log(error);
  }
};

export const createNewKey = () => async (dispatch: any) => {
  try {
    await managedApi.post('auth/keys', null);
    dispatch(getV2Keys());
  } catch (error) {
    console.log(error);
  }
};

export const deleteV2Key = (id: string) => async (dispatch: any) => {
  try {
    await managedApi.delete(`auth/keys/${id}`);
    dispatch(setAlert('Key revoked!', 'success'));
    dispatch(getV2Keys());
  } catch (error) {
    console.log(error);
  }
};
