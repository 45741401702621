import { api } from '../../services/api';

interface IStripeCustomerBillingAddress {
  country: string;
  postal_code: string;
}

export const updateStripeCustomerBillingAddress = async (
  stripeCustomerBillingAddress: IStripeCustomerBillingAddress
): Promise<any> => {
  try {
    const { data } = await api.put('users/stripeCustomerBillingAddress', stripeCustomerBillingAddress);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
