import React from 'react';
import 'toastr/build/toastr.min.css';
import { connect } from 'react-redux';
import {Alert as MuiAlert} from '@mui/material';
import {AlertColor} from "@mui/material/Alert/Alert";

interface AlertProps {
  alert: {
    alert: {
      timeOut: number;
      type: AlertColor;
      message: any;
    }
  }
}

const Alert = ({ alert }: AlertProps) => {
  const handleCloseClick = () => {
    localStorage.setItem('has-cleared-ipfs-notification', 'true');
  };

  return (
      alert.alert ? <MuiAlert severity={alert?.alert?.type}>{alert?.alert?.message}</MuiAlert> : null
  );
};

const mapStateToProps = (state: any) => {
  return {
    alert: state.alert
  };
};

export default connect(mapStateToProps)(Alert);
