import { makeDatePretty } from 'helpers/pretty';
import React, { useState } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import DeleteKeyModal from './DeleteKeyModal';
import NewKeyModal from './NewKeyModal';
import {TableCell, TableHead, TableRow, Table, TableBody, Button} from "@mui/material";
import {logDOM} from "@testing-library/react";

interface KeyTableProps {
  keys: any;
  pageLimit: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalKeys: number;
  setAlert: any;
  revokeApiKey: any;
}

const KeyTable = ({ keys, pageLimit, totalKeys, currentPage, setCurrentPage, setAlert, revokeApiKey }: KeyTableProps) => {
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const [keyInfo, setKeyInfo] = useState<any>(null);
  const [keyInfoModalOpen, setKeyInfoModalOpen] = useState(false);

  const handlePageChange = (pageToChangeTo: number) => {
    setCurrentPage(pageToChangeTo);
  };

  const renderPagination = () => {
    const pages = Math.ceil(totalKeys / pageLimit);
    let pageArray = [];
    for (let i = 0; i < pages; i++) {
      pageArray.push(i + 1);
    }
    return pageArray.map((p) => {
      return (
        <PaginationItem key={p} active={currentPage === p ? true : false}>
          <PaginationLink onClick={() => handlePageChange(p)}>{p}</PaginationLink>
        </PaginationItem>
      );
    });
  };

  const openKeyInfoModal = (keyInfo: any) => {
    setKeyInfo(keyInfo);
    setKeyInfoModalOpen(true);
  };

  const closeKeyInfoModal = () => {
    setKeyInfo(null);
    setKeyInfoModalOpen(false);
  };

  const openDeleteModal = (keyInfo: any) => {
    setKeyInfo(keyInfo);
    setDeleteKeyModalOpen(true);
  };

  const confirmDeleteKey = () => {
    revokeApiKey(keyInfo.key);
    setKeyInfo(null);
    setDeleteKeyModalOpen(false);
  };

  const renderRow = (row: {name: string, key: any, createdAt: any, max_uses: number, scopes: any, id: any}, key: any) => {
    return (
      <TableRow key={key}>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.key}</TableCell>
        <TableCell>{makeDatePretty(row.createdAt)}</TableCell>
        <TableCell>{row.max_uses === 0 || !row.max_uses ? <i className="fas fa-infinity"></i> : row.max_uses}</TableCell>
        <TableCell>
          {row.scopes.admin ? (
            <span>Admin</span>
          ) : (
            <span>
              <span
                onClick={() => openKeyInfoModal(row)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                id={`toolTipTop_${row.id}`}
              >
                Custom
              </span>
            </span>
          )}
        </TableCell>
        <TableCell>
          <Button onClick={() => openDeleteModal(row)}>
            Revoke
          </Button>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <React.Fragment>
      <div className="mt-4">
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell scope="col">Name</TableCell>
                <TableCell scope="col">Key</TableCell>
                <TableCell scope="col">Date Issued</TableCell>
                <TableCell scope="col">Max Uses</TableCell>
                <TableCell scope="col">Permissions</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{keys.map((k: any, key: any) => renderRow(k, key))}</TableBody>
          </Table>
          <Pagination className="my-4">{renderPagination()}</Pagination>
        </div>
      </div>
      {keyInfo && (
        <DeleteKeyModal
          keyName={keyInfo.name}
          setDeleteKeyModalOpen={setDeleteKeyModalOpen}
          deleteKeyModalOpen={deleteKeyModalOpen}
          confirmDeleteKey={confirmDeleteKey}
        />
      )}
      <NewKeyModal
        setNewKeyModalOpen={setKeyInfoModalOpen}
        newKeyModalOpen={keyInfoModalOpen}
        existingKey={true}
        keyInfo={keyInfo}
      />
    </React.Fragment>
  );
};

export default KeyTable;
