import { metricLimitInfo } from 'helpers/enums';
import React, { useState, useEffect } from 'react';
import { Spinner, Modal, Row, Col, CustomInput, Container, Alert } from 'reactstrap';
import { ROOT_DOMAIN } from './GatewayWizard/gatewayConstants';

const CustomDomainModal = ({
  customDomainModalOpen,
  setCustomDomainModalOpen,
  gatewayDetails,
  customDomains,
  setAlert,
  createCustomDomain,
  userMetricLimit
}) => {
  const [customDomain, setCustomDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [available, setAvailable] = useState(false);

  const handleChange = (value) => {
    setCustomDomain(value);
  };

  const handleCreate = async () => {
    if (customDomain.length < 6) {
      return setAlert('Custom domains must be at least 6 characters', 'error');
    }

    if (customDomain.length > 0) {
      if (validDomain(customDomain)) {
        setLoading(true);
        setDisplayResults(false);
        await createCustomDomain(gatewayDetails.id, customDomain);
        setLoading(false);
        setCustomDomainModalOpen(false);
      } else {
        setAvailable(false);
        setDisplayResults(false);
        setAlert('Invalid domain, please use this format: www.example.com', 'error');
      }
    }
  };

  const validDomain = (value) => {
    const tooLong = value.length > 103;
    const slashMatch = value.indexOf('/');
    const colonMatch = value.indexOf(':');
    const spaceMatch = value.indexOf(' ');
    const match = slashMatch > -1 || colonMatch > -1 || spaceMatch > -1 || tooLong;
    return !match;
  };

  const renderDomainModal = () => {
    if (gatewayDetails && gatewayDetails.customDomains && gatewayDetails.customDomains.length > 0) {
      const thisDomain = customDomains.find((c) => c.domain === gatewayDetails.customDomains[0].domain);

      const getSubdomain = (fullDomain) => {
        if (fullDomain.split('.').length > 2) {
          return fullDomain.split('.')[0];
        }
        return '@';
      };
      const subdomain = getSubdomain(gatewayDetails.customDomains[0].domain);

      const DNSInstructions = () => {
        return (
          <div>
            <div
              style={{
                width: '100%',
                background: '#282828',
                color: '#fff',
                padding: 20,
                borderRadius: 5
              }}
            >
              <code style={{ color: '#fff' }}>
                {subdomain} CNAME {`${gatewayDetails.domain}${ROOT_DOMAIN}.`}
              </code>
            </div>
          </div>
        );
      };

      return (
        <div className="modal-body">
          <div>
            {thisDomain &&
            thisDomain.sslValidationStatus === 'active' &&
            thisDomain.domainValidationStatus === 'active' ? (
              <div>
                <h5 style={{ marginBottom: 20 }}>
                  <i className="fas fa-check-circle text-success" style={{ marginRight: 5 }}></i>
                  Your custom domain is successfully configured!
                </h5>
                <h3 style={{ marginBottom: 20 }}>
                  {`${gatewayDetails.domain}${ROOT_DOMAIN}`} <i className="fas fa-arrow-right"></i>{' '}
                  {gatewayDetails.customDomains[0].domain}
                </h3>
                <DNSInstructions />
              </div>
            ) : (
              <div style={{ marginTop: 25 }}>
                <h3>Configure DNS</h3>
                <p>
                  For your custom domain to take effect, you'll need to update the DNS records with your domain
                  registrar. Log into your domain registrar and update the DNS records with the following info:{' '}
                </p>
                <h4>CNAME</h4>
                <p>
                  Point subdomain to <strong>{`${gatewayDetails.domain}${ROOT_DOMAIN}`}</strong>
                </p>
                <DNSInstructions />
                <div style={{ marginTop: 20 }}>
                  <p>Once you've entered your DNS records, you may need to wait up to 24 hours.</p>
                  <button onClick={() => setCustomDomainModalOpen(false)} className="btn btn-primary">
                    Got it
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-body">
          <div>
            <h4>Add a Custom Domain</h4>
            <Row>
              <div style={{ marginBottom: 40 }} className="form-group">
                <Container>
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-md-12 col-form-label">
                      Name
                    </label>
                    <div className="col-md-12 pinata-domain-input" data-placeholder="www.example.com">
                      <input
                        className="form-control"
                        placeholder="mycompanyname"
                        type="text"
                        id="domain-search"
                        onChange={(e) => handleChange(e.target.value)}
                        value={customDomain}
                        autoComplete="off"
                      />
                      {/* <p className="text-danger" style={{ marginTop: 25 }}>
                                            {domainError && 'Invalid subdomain'}
                                        </p> */}
                    </div>
                  </div>
                </Container>
              </div>
            </Row>

            <button
              disabled={!validDomain(customDomain)}
              onClick={handleCreate}
              className={validDomain(customDomain) ? 'btn btn-primary' : 'btn'}
            >
              {loading ? 'Adding domain...' : 'Add Custom Domain'}
            </button>
            <button onClick={() => setCustomDomainModalOpen(false)} className="btn">
              Cancel
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal isOpen={customDomainModalOpen} toggle={() => setCustomDomainModalOpen(false)} centered={true}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit.type}>
          {userMetricLimit.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Custom Domain</h5>
        <button
          type="button"
          onClick={() => setCustomDomainModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {renderDomainModal()}
    </Modal>
  );
};

export default CustomDomainModal;
