import React, { useState, useEffect } from 'react';
import {
  Pagination,
  UncontrolledDropdown,
  DropdownToggle,
  PaginationItem,
  PaginationLink,
  DropdownMenu,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { makeDatePretty } from 'helpers/pretty';
import ConfirmationModal from './ConfirmationModal';
import EditGateway from './EditGateway';
import { ROOT_DOMAIN } from './GatewayWizard/gatewayConstants';
import CustomDomainModal from './CustomDomainModal';
import { validateMetricsLimits } from '../../helpers/validations';
import {GatewayRow} from "../../store/gateways/types";
import {BillingState} from "../../store/billing/types";
import {MetricsState} from "../../store/metrics/types";
import {Box, TableContainer, TableHead, Typography, Table, TableRow, TableCell, TableBody} from "@mui/material";

interface GatewaysTableProps {
  gatewayAvailability: any [];
  gateways: GatewayRow [];
  totalRecords: number;
  currentPage: number;
  setCurrentPage: (newPage: number ) => void;
  deleteGateway: any;
  updateGateway: any;
  createCustomDomain: any;
  setAlert: (message: any, type: string) => void;
  getCustomDomainDNSStatus: any;
  deleteCustomDomain: any;
  metrics: MetricsState;
  billing: BillingState;
}

const GatewaysTable = ({
  gatewayAvailability,
  gateways,
  totalRecords,
  currentPage,
  setCurrentPage,
  deleteGateway,
  updateGateway,
  createCustomDomain,
  setAlert,
  getCustomDomainDNSStatus,
  deleteCustomDomain,
  metrics,
  billing
}: GatewaysTableProps) => {
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [gatewayId, setGatewayId] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [customDomainModalOpen, setCustomDomainModalOpen] = useState(false);
  const [gatewayDetails, setGatewayDetails] = useState<GatewayRow | null | undefined>(null);
  const [customDomains, setCustomDomains] = useState<{ item: any } []>([]);
  const [confirmRemoveDomainModalOpen, setConfirmRemoveDomainModalOpen] = useState(false);

  useEffect(() => {
    if (gateways && gateways.length > 0) {
      checkDNSStatus(gateways);
    }
  }, [gateways]);

  const checkDNSStatus = async (gateways: GatewayRow []) => {
    const dnsStatuses = [];
    for (const gateway of gateways) {
      if (gateway.customDomains.length > 0) {
        const status = await getCustomDomainDNSStatus(gateway.id, gateway.customDomains[0].id);
        // const sslStatus = status.data.sslValidationStatus === 'pending' ? false : true;
        dnsStatuses.push(status.data);
      }
    }

    setCustomDomains(dnsStatuses);
  };

  const handleOpenDeleteModal = (id: string) => {
    setGatewayId(id);
    setDeleteModalOpen(true);
  };

  const confirmDeleteGatedway = async () => {
    setLoading(true);

    await deleteGateway(gatewayId);

    setLoading(false);
    setDeleteModalOpen(false);
    setGatewayId(null);
  };

  const handleOpenEditModal = (domain: string) => {
    setGatewayDetails(gateways.find((gateway) => gateway.domain === domain));
    setEditModalOpen(true);
  };

  const handlePageChange = (pageToChangeTo: number ) => {
    localStorage.setItem('pinata-gateways-page', pageToChangeTo.toString());
    setCurrentPage(pageToChangeTo);
  };

  const renderPagination = () => {
    const pages = Math.ceil(totalRecords / 10);
    let pageArray: ('Back' | 'Forward' | number) [] = [];
    for (let i = 0; i < pages; i++) {
      pageArray.push(i + 1);
    }
    const pagesToShow = pageArray.slice(pageArray.indexOf(currentPage), currentPage + 9);
    if (currentPage > 1) {
      pagesToShow.unshift('Back');
      // pagesToShow.unshift("Start")
    }
    if (pages > pagesToShow[pagesToShow.length - 1]) {
      pagesToShow.push('Forward');
      // pagesToShow.push("End")
    }

    return pagesToShow.map((p: 'Back' | 'Forward' | number) => {
      if (p === 'Back') {
        return (
          <PaginationItem key={p} active={false}>
            <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
              <i className="fas fa-chevron-left"></i>
            </PaginationLink>
          </PaginationItem>
        );
      } else if (p === 'Forward') {
        return (
          <PaginationItem key={p} active={false}>
            <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
              <i className="fas fa-chevron-right"></i>
            </PaginationLink>
          </PaginationItem>
        );
      } else {
        return (
          <PaginationItem key={p} active={currentPage === p ? true : false}>
            <PaginationLink onClick={() => handlePageChange(p)}>{p}</PaginationLink>
          </PaginationItem>
        );
      }
    });
  };

  const getGatewayStatus = (gateway: GatewayRow) => {
    const index = gatewayAvailability.map((i: any) => i.id).indexOf(gateway.id);
    if (index > -1) {
      const availability = gatewayAvailability[index].available;
      if (availability === true) {
        return 'green';
      }
    }

    return '#ffcc00';
  };

  const handleOpenCustomDomainModal = (gateway: GatewayRow) => {
    setGatewayDetails(gateway);
    setCustomDomainModalOpen(true);
  };

  const getDNSStatus = (row: GatewayRow) => {
    if (row && customDomains.length > 0) {
      const domainStatus = customDomains.find(
        (customDomain) => customDomain.item.domain === row.customDomains[0].domain
      );
      if (
        domainStatus &&
        domainStatus.item.sslValidationStatus !== 'pending' &&
        domainStatus.item.domainValidationStatus !== 'pending_validation'
      ) {
        return (
          <span style={{ marginLeft: 20 }}>
            <i className="fas fa-check-circle"></i> <span>DNS Configured</span>
          </span>
        );
      } else {
        return (
          <button
            onClick={() => handleOpenCustomDomainModal(row)}
            style={{ marginLeft: 20 }}
            className="pinata-link-button"
          >
            <i style={{ marginRight: 5 }} className="fas fa-info-circle text-warning"></i>
            <u>Check DNS Configuration</u>
          </button>
        );
      }
    }
  };

  const handleRemoveDomain = async (row?: GatewayRow | null, confirm?: boolean) => {
    if (!confirm) {
      setGatewayDetails(row);
      setConfirmRemoveDomainModalOpen(true);
    } else {
      setLoading(true);
      if (row) {
        await deleteCustomDomain(row.id, row.customDomains[0].id);
      }
      setLoading(false);
      setConfirmRemoveDomainModalOpen(false);
    }
  };

  const validateMetrics = (rowDomain: string) => {
    const validMetrics = validateMetricsLimits(metrics?.metrics, billing);
    if (validMetrics) {
      setAlert(
        <span>
          This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
        </span>,
        'error'
      );
      return;
    }
    handleOpenEditModal(rowDomain);
  };

  const renderRow = (row: GatewayRow, key: any) => {
    return (
      <TableRow key={key}>
        {/*<TableCell style={{ width: 10 }}>*/}
          {/*Commented out until gateway check api where done. See https://gitlab.com/IPFSPinata/pinata-frontend-2/-/issues/68*/}
          {
            // <p
            //     id="statusIndicator"
            //     style={{
            //         height: 8,
            //         width: 8,
            //         background: getGatewayStatus(row),
            //         borderRadius: 1000,
            //         marginTop: 15
            //     }}
            // ></p>
            // {getGatewayStatus(row) !== 'green' && (
            //     <UncontrolledTooltip placement="top" target="statusIndicator">
            //         Your gateway is either being created or has stopped. If this is a new gateway, please give
            //         it a few moments.
            //     </UncontrolledTooltip>
            // )}
          }
        {/*</TableCell>*/}
        <TableCell>
          <Link to={`/gateway/${row.domain}`}>
            {row.customDomains && row.customDomains.length > 0
              ? row.customDomains[0].domain
              : `${row.domain}${ROOT_DOMAIN}`}
          </Link>
          {row.customDomains && row.customDomains.length > 0 && <span>{getDNSStatus(row)}</span>}
        </TableCell>
        <TableCell>{row.restrict ? 'Restricted' : 'Open'}</TableCell>
        <TableCell>{makeDatePretty(row.createdAt)}</TableCell>
        <TableCell>
          <UncontrolledDropdown className="mb-2" direction="left">
            <DropdownToggle color="white" className="btn font-size-16 btn-link text-muted mt-n2">
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>

            <DropdownMenu>
              {/* <Link to={`/gateway/${row.domain}`} className="dropdown-item">
                                View Usage
                            </Link> */}
              {row.customDomains && row.customDomains.length > 0 ? (
                <Link onClick={() => handleOpenCustomDomainModal(row)} className="dropdown-item" to="#">
                  Domain Info
                </Link>
              ) : (
                <Link onClick={() => handleOpenCustomDomainModal(row)} className="dropdown-item" to="#">
                  Add Custom Domain
                </Link>
              )}
              {row.customDomains && row.customDomains.length > 0 && (
                <Link onClick={() => handleRemoveDomain(row)} className="dropdown-item" to="#">
                  Remove Custom Domain
                </Link>
              )}
              <Link onClick={() => validateMetrics(row.domain)} className="dropdown-item" to="#">
                Edit Gateway
              </Link>
              <div className="dropdown-divider"></div>
              <Link onClick={() => handleOpenDeleteModal(row.id)} className="dropdown-item" to="#">
                Remove Gateway
              </Link>
            </DropdownMenu>
          </UncontrolledDropdown>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <Box>
      <TableContainer sx={{overflow: 'visible'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Access</TableCell>
              <TableCell>Created</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{gateways.map((gateway, key) => renderRow(gateway, key))}</TableBody>
        </Table>
        {editModalOpen && (
          <EditGateway
            setEditModalOpen={setEditModalOpen}
            editModalOpen={editModalOpen}
            gatewayDetails={gatewayDetails}
            updateGateway={updateGateway}
            currentPage={currentPage}
            userMetricLimit={metrics?.metricsLimitData}
          />
        )}
        {customDomainModalOpen && (
          <CustomDomainModal
            createCustomDomain={createCustomDomain}
            setCustomDomainModalOpen={setCustomDomainModalOpen}
            customDomainModalOpen={customDomainModalOpen}
            setAlert={setAlert}
            gatewayDetails={gatewayDetails}
            customDomains={customDomains}
            userMetricLimit={metrics?.metricsLimitData}
          />
        )}
        {deleteModalOpen && (
          <ConfirmationModal
            title="Remove Gateway"
            text="Are you sure you want to remove this gateway? It cannot be undone."
            loading={loading}
            modalOpen={deleteModalOpen}
            toggleModal={setDeleteModalOpen}
            action={confirmDeleteGatedway}
          />
        )}
        {confirmRemoveDomainModalOpen && (
          <ConfirmationModal
            title="Remove Custom Domain"
            text={`Are you sure you want to remove this custom domain (${
              gatewayDetails && gatewayDetails.customDomains.length > 0 && gatewayDetails.customDomains[0].domain
            })?`}
            loading={loading}
            modalOpen={confirmRemoveDomainModalOpen}
            toggleModal={setConfirmRemoveDomainModalOpen}
            action={() => handleRemoveDomain(gatewayDetails, true)}
          />
        )}
      </TableContainer>
      <Pagination size={'10'} className="my-4">
        {renderPagination()}
      </Pagination>
    </Box>
  );
};

export default GatewaysTable;
