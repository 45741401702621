const baseUrl = process.env.REACT_APP_PINATA_API_URL
export const apiBaseURL = baseUrl;
export const authHeaders = `
pinata_api_key: (put your personal pinata api key here)

pinata_secret_api_key: (put your personal pinata secret api key here) 
          
OR

Authorization: Bearer (put your pinata JWT here)`;
export const S3PreviewBucketURL = process.env.REACT_APP_S3_PREVIEW