export const ERROR = 'ERROR';
export const LOAD_USER = 'LOAD_USER';
export const LOG_OUT = 'LOG_OUT';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//Pins
export const USER_PIN_LIST_RETRIEVED = 'USER_PIN_LIST_RETRIEVED';
export const USER_PINNED_DATA_TOTALS_RECEIVED = 'USER_PINNED_DATA_TOTALS_RECEIVED';
export const PIN_CONTENT_PREVIEW_UPDATED = 'PIN_CONTENT_PREVIEW_UPDATED';
export const REGION_PERMISSIONS_RECEIVED = 'REGION_PERMISSIONS_RECEIVED';
export const PIN_JOBS = 'PIN_JOBS';
export const TRAVERSE_FOLDER = 'TRAVERSE_FOLDER';

//Keys
export const API_KEYS_LOADED = 'API_KEYS_LOADED';
export const NEW_API_KEY = 'NEW_API_KEY';
export const V2_KEYS_LOADED = 'V2_KEYS_LOADED';

//Usage
export const USER_DATA_USAGE_RETRIEVED = 'USER_DATA_USAGE_RETRIEVED';

//Gateways
export const LOAD_GATEWAYS = 'LOAD_GATEWAYS';
export const GATEWAY_REGIONS = 'GATEWAY_REGIONS';
export const GATEWAY_USAGE = 'GATEWAY_USAGE';
export const TOTAL_BANDWIDTH_MONTH = 'TOTAL_BANDWIDTH_MONTH';