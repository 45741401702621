import { planTypes } from 'helpers/enums';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAlert } from '../../store/alert/alert.actions';
import { useAuth } from '../../hooks/useAuth';

const Authenticate = ({ setAlert }) => {
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    let navigateTo = location;
    if (isAuthenticated) {
      const hasCleared = localStorage.getItem('has-cleared-ipfs-notification');
      if (!hasCleared) {
        setAlert('Remember, IPFS is a public network', 'warning', 7000, (close = true));
      }
      const signUpPlanName = localStorage.getItem('pinata-registration-plan-selection');
      if (
        signUpPlanName &&
        (signUpPlanName.toLocaleLowerCase() === planTypes.PICNIC.nickname.toLocaleLowerCase() ||
          signUpPlanName.toLocaleLowerCase() === planTypes.FIESTA.nickname.toLocaleLowerCase() ||
          signUpPlanName.toLocaleLowerCase() === planTypes.CARNIVAL.nickname.toLocaleLowerCase())
      ) {
        navigateTo = '/billing';
        return history.push({
          pathname: navigateTo,
          state: { desiredPlan: signUpPlanName, registerFromMarketing: true }
        });
      } else {
        navigateTo = '/pinmanager';
      }
    }
    history.push(navigateTo);
  }, [isAuthenticated]);

  return null;
};

export default connect(null, { setAlert })(Authenticate);
