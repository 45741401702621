import { metricLimitInfo, planTypes } from '../helpers/enums';
import { validationsRegex } from './formFieldValidations';
import { Metrics, SingleMetric } from '../store/metrics/types';
import { BillingState } from '../store/billing/types';

export const handleFileValidations = (files: any[], usageMetrics: Metrics, billing: BillingState) => {
  // we don't have limitations for custom planes
  if (billing?.activePricingPlan?.type === planTypes.ENTERPRISE.type || billing?.activePricingPlan?.isLegacy) {
    return { isValid: true, errorMsg: null };
  }
  const maxUploadSize = 25 * 1000 * 1000 * 1000;
  // TODO: need to avoid this billing dependency and send number instead of billing object
  let isLessThanMaxFileSize = false;
  let isLessThanMaxUploadSize = false;
  let isLessThanMaxFileCount = true;
  let maxByteSizeBytes = (usageMetrics.metricsAccount?.storageSize.limit || 0) * 1000 * 1000 * 1000; //Kb //Mb //Gb
  maxByteSizeBytes = maxByteSizeBytes * 0.25 + maxByteSizeBytes;
  const maxFileCount =
    usageMetrics.metricsAccount?.fileCount.limit * 0.25 + usageMetrics.metricsAccount?.fileCount.limit;
  const isDirectory = files.length > 1;
  if (isDirectory) {
    let totalSizeOfDirectory = 0;
    for (const file of files) {
      totalSizeOfDirectory = totalSizeOfDirectory + file.size;
    }

    isLessThanMaxFileSize = totalSizeOfDirectory + usageMetrics?.metricsAccount?.storageSize.value < maxByteSizeBytes;
    isLessThanMaxUploadSize = totalSizeOfDirectory < maxUploadSize;
    isLessThanMaxFileCount = files.length + usageMetrics.metricsAccount?.fileCount.value < maxFileCount;
  } else {
    const file = files[0];
    isLessThanMaxFileSize = file.size + usageMetrics?.metricsAccount?.storageSize.value < maxByteSizeBytes;
    isLessThanMaxUploadSize = file.size < maxUploadSize;
  }

  if (isLessThanMaxFileSize && isLessThanMaxFileCount && isLessThanMaxUploadSize) {
    return { isValid: true, errorMsg: null };
  }

  if (!isLessThanMaxUploadSize) {
    return { isValid: false, errorMsg: `Max file/folder size allowed is 25GB` };
  }

  let errorDetail = '';
  if (!isLessThanMaxFileSize) {
    errorDetail = 'File size is more than you have available';
  } else if (!isLessThanMaxFileCount) {
    errorDetail = 'File count is more than you have available';
  }
  return { isValid: false, errorMsg: `This upload would exceed your plan limit. ${errorDetail}` };
};

export const emailRequirements = (email: string) => {
  return validationsRegex.email.test(email);
};

export const validateMetricsLimits = (metrics: Metrics, billing: BillingState): boolean => {
  if (
    billing?.activePricingPlan?.isLegacy ||
    billing?.activePricingPlan?.type === planTypes.ENTERPRISE.type ||
    billing?.activePricingPlan?.type === planTypes.CARNIVAL.type
  ) {
    return false;
  }
  return Object.values({
    ...metrics.metricsAccount,
    ...metrics.metricsMonthly
  }).some((metric) => metric.percentage >= metricLimitInfo.PLAN_ABOVE_125.value);
};
