import React, { useState, useEffect } from 'react';
import { Modal, Col, Row, Alert } from 'reactstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { metricLimitInfo } from 'helpers/enums';
import { Link } from 'react-router-dom';

const EditPinPolicy = ({ open, toggleModal, pin, savePolicy, regionPermissions, userMetricsLimit }) => {
  const [migrate, setMigrate] = useState(false);
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    if (regionPermissions && regionPermissions.length > 0) {
      setRegions(regionPermissions);
    }
  }, [regionPermissions]);
  const canSubmit = () => {
    let oneRegionWithReps = false;
    regions.forEach((r) => {
      if (r.currentDesiredReplicationCount > 0) {
        oneRegionWithReps = true;
      }
    });
    return oneRegionWithReps;
  };

  const handleSave = () => {
    savePolicy(regions, migrate);
  };

  const handlePolicyChange = (reps, id) => {
    const clonedRegions = [...regions];
    const thisRegion = clonedRegions.find((r) => r.regionId === id);
    thisRegion.currentDesiredReplicationCount = reps;
    setRegions(clonedRegions);
  };

  const getModalBody = (pin) => {
    if (pin) {
      return (
        <div>
          <h3>Edit Pin Policy For This Pin</h3>
          <p>{pin.ipfs_pin_hash}</p>
        </div>
      );
    } else {
      <div>
        <h3>Edit Global Pin Policy</h3>
      </div>;
    }
  };

  const getSliderLabels = (allowedReplications) => {
    const max = allowedReplications;
    let labels = {};
    for (let i = 0; i < max + 1; i++) {
      labels[i] = i;
    }

    return labels;
  };

  return (
    <Modal isOpen={open} toggle={() => toggleModal(false)} centered={true}>
      {userMetricsLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricsLimit.type}>
          {userMetricsLimit.text} <Link to="/billing">Upgrade</Link>
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Edit Pin Policy</h5>
        <button
          type="button"
          onClick={() => toggleModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {getModalBody(pin)}
        <Row>
          <Col xs={12}>
            <div>
              <p>Current Replications</p>
            </div>
          </Col>
          {regions.length > 0 &&
            regions.map((r) => {
              return (
                <Col key={r.regionId} md={6} sm={12}>
                  <div className="p-3">
                    <h5 className="font-size-14 mb-3 mt-0">{r.regionId}</h5>
                    {/* <span className="float-left mt-4">0</span>{" "}
                    <span className="float-right  mt-4">
                      {r.allowedReplications}
                    </span> */}
                    <Slider
                      min={0}
                      max={r.allowedReplications}
                      step={1}
                      labels={getSliderLabels(r.allowedReplications)}
                      value={r.currentDesiredReplicationCount}
                      orientation="horizontal"
                      onChange={(value) => {
                        handlePolicyChange(value, r.regionId);
                      }}
                    />
                  </div>
                </Col>
              );
            })}
        </Row>
        {!pin && (
          <div className="custom-control custom-checkbox custom-checkbox-outline custom-checkbox-primary mb-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck-outlinecolor1"
              checked={migrate}
              onChange={() => {
                setMigrate(!migrate);
              }}
            />
            <label className="custom-control-label" htmlFor="customCheck-outlinecolor1">
              Migrate all pins to new policy
            </label>
          </div>
        )}
        <div style={{ marginTop: 20 }}>
          <button
            onClick={handleSave}
            disabled={!canSubmit()}
            className={canSubmit() ? 'btn btn-primary' : 'btn btn-secondary'}
          >
            Save
          </button>
          <button onClick={() => toggleModal(false)} className="btn">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditPinPolicy;
