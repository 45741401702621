export const INTRO_PAGE = 'INTRO_PAGE';
export const DOMAIN_PAGE = 'DOMAIN_PAGE';
export const DETAILS_PAGE = 'DETAILS_PAGE';
export const GATEWAY_REGIONS = 'GATEWAY_REGIONS';
let domains = [
    {
        appdomain: ['app.pinata.cloud'],
        rootdomain: '.mypinata.cloud'
    },
    {
        appdomain: ['develop-app.pinata.cloud', 'localhost'],
        rootdomain: '.devmypinata.cloud'
    },
    {
        appdomain: ['staging-app.pinata.cloud'],
        rootdomain: '.testmypinata.cloud'
    }
];
export const ROOT_DOMAIN = domains.find((d) => d.appdomain.indexOf(process.env.REACT_APP_COOKIE_DOMAIN)!= -1).rootdomain;