import { managedApi, metricsApi, api } from 'services/api';
import { setAlert } from '../alert/alert.actions';
import { LOAD_GATEWAYS, GATEWAY_USAGE } from '../../actions/types';
import {GatewayRow} from "./types";
import {getErrorMessage} from "../../helpers/error.helper";

export const getAllGateways = (page: number) => async (dispatch: any) => {
  try {
    const res = await managedApi.get(`gateways?page=${page}`);
    if (res && res?.data) {
      dispatch({
        type: LOAD_GATEWAYS,
        payload: res.data.items
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkSubdomain = (subdomain: string) => async (dispatch: any) => {
  try {
    const res = await managedApi.get(`gateways/${subdomain.toLowerCase()}/exists`);
    const isTaken = res?.data?.exists;
    return isTaken;
  } catch (error) {
    console.log(error);
  }
};

export const createCustomDomain = (gatewayId: string | number, domain: string) => async (dispatch: any) => {
  try {
    const body = {
      domain
    };
    const res = await managedApi.post(`gateways/${gatewayId}/custom_domain`, body);
    dispatch(getAllGateways(1));
    dispatch(setAlert('Added custom domain!', 'success'));
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createGateway = (gatewayInfo: {subdomain: string, restricted: boolean}, update: boolean) => async (dispatch: any) => {
  try {
    const body = {
      domain: gatewayInfo.subdomain.toLowerCase(),
      restrict: gatewayInfo.restricted
    };
    const res = await managedApi.post(`gateways`, body);
    if (!update && res?.data) {
      dispatch(setAlert('Gateway created!', 'success'));
      dispatch(getAllGateways(1));
    }
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCustomDomainDNSStatus = (gatewayId: string | number, customDomainId: string) => async () => {
  try {
    return await managedApi.get(`gateways/${gatewayId}/custom_domain/${customDomainId}?updateStatus=yes`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteGateway = (gatewayId: string | number, update: boolean) => async (dispatch: any) => {
  try {
    const res = await managedApi.delete(`gateways/${gatewayId}`);
    if (!update) {
      dispatch(setAlert('Removed gateway', 'success'));
      dispatch(getAllGateways(1));
    }
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkUsage = (domain: string, dateRange: {today: Date, past: Date}, gateways: any) => async (dispatch: any) => {
  try {
    const allStats: { domain: any; requests: any; bandwidth: any; }[] = [];
    const endDate = dateRange.today.toISOString();
    const startDate = dateRange.past.toISOString();

    const url = `metrics/gateways?start=${startDate}&end=${endDate}`;
    const resBandwidth = await metricsApi.get(url);

    gateways?.gateways?.rows?.forEach((gateway: any) => {
      // Get current gateway
      if(gateway?.domain.split('.')[0] === domain) {
        // Get bandwidth usage for current gateway
        const selectedBandwidth = resBandwidth?.data?.find((bandwidth: any) => bandwidth.domain.split('.')[0] === gateway.domain);
        if(selectedBandwidth) {
          allStats.push({
            domain: selectedBandwidth.domain,
            requests: selectedBandwidth.reqCount,
            bandwidth: selectedBandwidth.transferBytes,
          });
        }
        // Get Custom Domain bandwidth usage of current gateway
        if( gateway?.customDomains[0]) {
          const selectedBandwidthCustomDomain = resBandwidth?.data?.find((bandwidth: any) => bandwidth.domain === gateway?.customDomains[0]?.domain);
          if(selectedBandwidthCustomDomain) {
            allStats.push({
              domain: selectedBandwidthCustomDomain.domain,
              requests: selectedBandwidthCustomDomain.reqCount,
              bandwidth: selectedBandwidthCustomDomain.transferBytes,
            });
          }  
        }
      }
    });

    if (allStats.length > 0) {
      dispatch({
        type: GATEWAY_USAGE,
        payload: allStats
      });
      return allStats;
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkMonthlyBandwidth = async () => {
  const currentDate = new Date();
  let startDate: string | Date = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
  let endDate: string | Date = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1));
  startDate = startDate.toISOString();
  endDate = endDate.toISOString();
  const gatewayResults = await managedApi.get(`organizations/billing?startDate=${startDate}&endDate=${endDate}`);
  let bandwidth = 0;
  if (gatewayResults && gatewayResults.data) {
    for (const gateway of gatewayResults.data.item.gateways) {
      bandwidth = bandwidth + gateway.usage.bandwidth;
    }
  }

  return bandwidth;
};

export const updateGateway = (gatewayInfo: {subdomain: string, restricted: boolean, id: string}, page: number) => async (dispatch: any) => {
  try {
    const body = {
      restrict: gatewayInfo.restricted
    };
    await managedApi.put(`gateways/${gatewayInfo.id}`, body);
    dispatch(getAllGateways(page));
    dispatch(setAlert('Gateway updated!', 'success'));
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCustomDomain = (gatewayId: string | number, customDomainId: string) => async (dispatch: any) => {
  try {
    await managedApi.delete(`gateways/${gatewayId}/custom_domain/${customDomainId}`);
    dispatch(getAllGateways(1));
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const killGateways = (gateways: GatewayRow []) => async (dispatch: any) => {
  try {
    for (const gateway of gateways) {
      await managedApi.delete(`gateways/${gateway.id}`);
    }
    dispatch(getAllGateways(1));
  } catch (error) {
    console.log(error);
  }
};

export const setRootContent = (gatewayId: string, pin: any) => async (dispatch: any) => {
  try {
    const setContentRes = await api.put(`v2/gateways/${gatewayId}/pin/${pin}`, null);
    return setContentRes;
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch(setAlert(message, 'error'));
  }
}

export const getRootContent = (gatewayId: string) => async (dispatch: any) => {
  try {
    const getContentRes = await api.put(`v2/gateways/${gatewayId}/pin`);
    return getContentRes;
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch(setAlert(message, 'error'));
  }
}