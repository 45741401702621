import { Link } from 'react-router-dom';
import { Navbar as NavBar, Nav, NavItem, NavLink, Container, Collapse } from 'reactstrap';
import CodeIcon from '@mui/icons-material/Code';

import { connect } from 'react-redux';
import {PinataState} from "../../store/types";
import {UserState} from "../../store/user/types";

interface NavbarProps {
  menuOpen: boolean;
  user: UserState;
}

const Navbar = (props: NavbarProps) => {
  return (
    <Container fluid>
      <NavBar expand="lg" className="topnav-menu">
        <Collapse navbar isOpen={props.menuOpen}>
          <Container fluid className="m-0">
            <Nav navbar>
              <NavItem className="navItem">
                <NavLink tag={Link} to="/pinmanager">
                  <i className="fas fa-file mr-2" />
                  Files
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink tag={Link} to="/gateway">
                  <i className="fas fa-bolt mr-2" />
                  Gateway
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink tag={Link} to="/cidchecker">
                  <i className="fas fa-check-double mr-2" /> File CID Verifier
                </NavLink>
              </NavItem>
              {props.user.user?.featureFlags?.developers &&
                <NavItem className="navItem">
                  <NavLink tag={Link} to="/developers">
                    <CodeIcon/> Developers
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </Container>
        </Collapse>
      </NavBar>
    </Container>
  );
};

const mapStatetoProps = (state: PinataState) => {
  const { leftMenu } = state.Layout;
  return { leftMenu, user: state.user };
};

export default connect(mapStatetoProps)(Navbar);
