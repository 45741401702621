import { planTypes } from 'helpers/enums';

export function handleIfUserIsMarketingSignUp(): void {
  const hasPlanToChange: boolean = window.location.search.includes('planName');
  const planName: string = window.location.search.split('planName=')[1];
  const isPaidPlan: boolean =
    planName === planTypes.PICNIC.nickname ||
    planName === planTypes.FIESTA.nickname ||
    planName === planTypes.CARNIVAL.nickname;

  if (hasPlanToChange && isPaidPlan) {
    localStorage.setItem('pinata-registration-plan-selection', planName);
  }
}
