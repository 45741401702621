import React, { useEffect } from 'react';
import { connect } from 'react-redux';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import AccountSecurityCard from './AccountSecurityCard';
import AccountDetailsCard from './AccountDetailsCard';
import AccountManagementCard from './AccountManagement';
import AccountSettingsCard from './AccountSettingsCard';
import { setAlert } from '../../store/alert/alert.actions';
import { cancelUserAccount, changeEmail, loadUserInfo, restoreCanceledAccount } from '../../store/user/user.actions';
import { regionPermissions, userPinPolicy } from '../../actions/pin.actions';
import ReactGA from 'react-ga';
import { CardsBox } from '../../components/Common/MuiComponents';
import {Container, Typography} from "@mui/material";
import {MetricsState} from "../../store/metrics/types";
import {BillingState} from "../../store/billing/types";
import {PinataState} from "../../store/types";

interface AccountProps {
  changeEmail: any;
  user: any;
  setAlert: (message: string, type: string) => void;
  regionPermissions: any;
  data: any;
  userPinPolicy: any;
  metrics: MetricsState;
  billing: BillingState;
  cancelUserAccount: any;
  restoreCanceledAccount: any;
  loadUserInfo: any;
}

const Account = ({
  changeEmail,
  user,
  setAlert,
  regionPermissions,
  data: { pinRegionPermissions },
  userPinPolicy,
  metrics,
  billing,
  cancelUserAccount,
  restoreCanceledAccount,
  loadUserInfo
}: AccountProps) => {
  useEffect(() => {
    regionPermissions();
    ReactGA.pageview('account');
  }, []);
  return (
        <Container>
          <Typography variant="h3">Profile</Typography>
          <CardsBox>
            <AccountDetailsCard
              changeEmail={changeEmail}
              setAlert={setAlert}
              user={user.user}
            />
            <AccountSecurityCard setAlert={setAlert}/>
            <AccountSettingsCard setAlert={setAlert} />
            <AccountManagementCard
              setAlert={setAlert}
              user={user}
              currentPlan={billing.activePricingPlan}
              cancelUserAccount={cancelUserAccount}
              restoreCanceledAccount={restoreCanceledAccount}
              loadUserInfo={loadUserInfo}
              billing={billing}
              changeEmail={changeEmail}
            />
          </CardsBox>
        </Container>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    user: state.user,
    data: state.data,
    metrics: state.metrics,
    billing: state.billing
  };
};

export default connect(mapStateToProps, {
  changeEmail,
  setAlert,
  regionPermissions,
  userPinPolicy,
  cancelUserAccount,
  restoreCanceledAccount,
  loadUserInfo
})(Account);
