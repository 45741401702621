import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-125823119-1';

function init(): void {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV !== 'production';
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

function sendEvent(payload: ReactGA.EventArgs): void {
  ReactGA.event(payload);
}

function sendPageview(path: string): void {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview
};
