import React from 'react'
import {
  Modal,
} from "reactstrap"

interface DeleteKeyModalProps {
  confirmDeleteKey: () => void;
  keyName: string;
  deleteKeyModalOpen: boolean;
  setDeleteKeyModalOpen: (open: boolean) => void;
}

const DeleteKeyModal = ({ confirmDeleteKey, keyName, deleteKeyModalOpen, setDeleteKeyModalOpen }: DeleteKeyModalProps) => {
  return (
    <Modal
      isOpen={deleteKeyModalOpen}
      toggle={() => setDeleteKeyModalOpen(false)}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Revoke API Key</h5>
        <button
          type="button"
          onClick={() => setDeleteKeyModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div>
          <h3>Are you sure you want to revoke the key named <strong>"{keyName}"</strong>?</h3>
          <p>This cannot be undone.</p>
        </div>
        
        <button onClick={confirmDeleteKey} className="btn btn-danger">Revoke Key</button>
        <button onClick={() => setDeleteKeyModalOpen(false)} className="btn">Cancel</button>
      </div>
    </Modal>
  )
}

export default DeleteKeyModal
