import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../store';
import { setAlert } from 'store/alert/alert.actions';
import { LOG_OUT } from 'actions/types';
import { fetchSession } from '../hooks/useAuth';
import { checkForUnauthorized, getErrorMessage } from 'helpers/error.helper';

const createInstance = (config: AxiosRequestConfig) => {
    return axios.create(config);
};

const api: AxiosInstance = createInstance({
    baseURL: process.env.REACT_APP_PINATA_API_URL,
    withCredentials: true
});

const managedApi: AxiosInstance = createInstance({
    baseURL: process.env.REACT_APP_MANAGED_API,
    withCredentials: true
});

const metricsApi: AxiosInstance = createInstance({
    baseURL: process.env.REACT_APP_METRICS_API,
    withCredentials: false
});

api.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const sessionData = await fetchSession();
    config.headers.Authorization = `Bearer ${sessionData?.accessToken}`;
    config.headers.Source = 'login';
    return Promise.resolve(config);
});

managedApi.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const sessionData = await fetchSession();
    config.headers.Authorization = `Bearer ${sessionData?.accessToken}`;
    return Promise.resolve(config);
});

metricsApi.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const sessionData = await fetchSession();
    config.headers.Authorization = `${sessionData?.accessToken}`;
    return Promise.resolve(config);
});

api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        const isUnauthorized = checkForUnauthorized(error);
        if (isUnauthorized) {
            store.dispatch({ type: LOG_OUT, payload: null });
            store.dispatch(setAlert('Unauthorized', 'error'));
        } else if (error?.response?.data?.message) {
            store.dispatch(setAlert(error.response.data.message, 'error'));
            return;
        } else {
            const message = getErrorMessage(error);
            store.dispatch(setAlert(message, 'error'));
        }
        return Promise.reject(error);
    }
);

managedApi.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        const isUnauthorized = checkForUnauthorized(error);
        if (isUnauthorized) {
            store.dispatch({ type: LOG_OUT, payload: null });
            store.dispatch(setAlert('Unauthorized', 'error'));
        } else if (error?.response?.data?.message) {
            store.dispatch(setAlert(error.response.data.message, 'error'));
            return;
        } else {
            const message = getErrorMessage(error);
            store.dispatch(setAlert(message, 'error'));
        }
        return Promise.reject(error);
    }
);

export { api, managedApi, metricsApi };
