import { makeDatePretty } from 'helpers/pretty';
import React from 'react';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import {Box, Card, CardContent, FormControl, MenuItem, Select, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

interface UsageNavProps {
  defaultDates: {past: Date, today: Date} | null;
  range: number;
  handleDateRangeChange: (e: any) => void;
}

const NoLegendSelect = styled(Select)(({theme}) => ({
  '& legend': {
    display: 'none',
  }
}))

const UsageNav = ({ defaultDates, handleDateRangeChange, range }: UsageNavProps) => {
    return (
        <Box>
            <Typography>Date Range</Typography>
            {defaultDates && (
                <Row>
                    <Col>
                        <p>
                            <strong>
                                {makeDatePretty(defaultDates.past)} - {makeDatePretty(defaultDates.today)} - Last{' '}
                                {range > 1 && range} day
                                {range > 1 && 's'}
                            </strong>
                        </p>
                    </Col>
                    <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Box>
                            <FormControl>
                              <NoLegendSelect
                                labelId="usage-range-select"
                                id="usage-range"
                                value={range}
                                onChange={handleDateRangeChange}
                              >
                                <MenuItem value="1">Last 24 Hours</MenuItem>
                                <MenuItem value="7">Last 7 Days</MenuItem>
                                <MenuItem value="14">Last 14 Days</MenuItem>
                                <MenuItem value="30">Last 30 Days</MenuItem>
                              </NoLegendSelect>
                            </FormControl>
                        </Box>
                    </Col>
                </Row>
            )}
        </Box>
    );
};

export default UsageNav;
