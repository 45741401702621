import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';
import { Typography } from '@mui/material';

const Breadcrumb = (props) => {
  return (
    // show when less then md
    <Row className="d-none d-md-block my-2">
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <Typography variant="h4">{props.breadcrumbItem}</Typography>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Typography component="span" variant="body2">
                  {props.title}
                </Typography>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Typography component="span" variant="body2">
                  {props.breadcrumbItem}
                </Typography>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
};

export default Breadcrumb;
