import React, { useState, useEffect, useMemo } from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import GatewaysTable from './GatewaysTable';
import TableNav from './TableNav';
import GatewayWizard from './GatewayWizard/index';
import { setAlert } from '../../store/alert/alert.actions';
import {
  checkSubdomain,
  createGateway,
  getAllGateways,
  deleteGateway,
  updateGateway,
  createCustomDomain,
  getCustomDomainDNSStatus,
  deleteCustomDomain
} from '../../store/gateways/gateway.actions';
import { connect } from 'react-redux';
import UpgradeCard from './UpgradeCard';
import { retrieveStripeCustomer } from '../../store/billing/billing.actions';
import BandwidthUsageChart from './BandwidthUsageChart';
import { planTypes } from 'helpers/enums';
import {BillingState} from "../../store/billing/types";
import {GatewayRow, GatewaysState} from "../../store/gateways/types";
import {MetricsState} from "../../store/metrics/types";
import {PinataState} from "../../store/types";
import {CardContent, Card, Typography} from "@mui/material";
import {CardsBox} from "../../components/Common/MuiComponents";

interface GatewayProps {
  setAlert: (message: any, type: string) => void;
  checkSubdomain: (subdomain: string) => void;
  createGateway: (gatewayInfo: {subdomain: string, restricted: boolean}, update: boolean) => void;
  getAllGateways: (page: number) => void;
  billing: BillingState;
  gateways: GatewaysState;
  deleteGateway: (gatewayId: string | number, update: boolean) => void;
  updateGateway: (gatewayInfo: {subdomain: string, restricted: boolean, id: string}, page: number) => void;
  retrieveStripeCustomer: () => void;
  createCustomDomain: (gatewayId: string | number, customDomainId: string) => void;
  getCustomDomainDNSStatus: (gatewayId: string | number, customDomainId: string) => void;
  deleteCustomDomain: (gatewayId: string | number, customDomainId: string) => void;
  metrics: MetricsState;
}

const Gateway = ({
  setAlert,
  checkSubdomain,
  createGateway,
  getAllGateways,
  billing,
  gateways,
  deleteGateway,
  updateGateway,
  createCustomDomain,
  getCustomDomainDNSStatus,
  deleteCustomDomain,
  metrics
}: GatewayProps) => {
  const [existingGateways, setGateways] = useState<GatewayRow []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [wizardModalOpen, setWizardModalOpen] = useState(false);
  const [gatewayAvailability, setGatewayAvailability] = useState<{id: string, available: boolean} []>([]);

  useEffect(() => {
    if (gateways.gateways && gateways.gateways.rows && gateways.gateways.rows.length > 0) {
      localStorage.setItem('pinata-gateways-page', currentPage.toString());
      setGateways(gateways.gateways.rows);
      setGatewayAvailability(
        gateways.gateways.rows.map((g: GatewayRow) => {
          return {
            id: g.id,
            available: false
          };
        })
      );
      /* Commented out until gateway check api where done. See https://gitlab.com/IPFSPinata/pinata-frontend-2/-/issues/68 */
      // canGatewaysBeReached(gateways.gateways.rows);
    } else {
      setGateways([]);
    }
  }, [gateways.gateways]);

  useEffect(() => {
    getAllGateways(currentPage);
  }, [currentPage]);

  const canAccessGateways = useMemo(() => {
    return (
      (billing?.activePricingPlan && billing?.activePricingPlan?.type !== planTypes.FREE.type) ||
      billing?.activePricingPlan?.isLegacy
    );
  }, [billing]);

  return (
    <React.Fragment>
        <Container>
          <Typography variant="h3">Gateways</Typography>
          <CardsBox>
            <Card>
              <CardContent>
                {canAccessGateways ? (
                  <>
                    <BandwidthUsageChart
                      bandwidth={metrics?.metrics?.metricsMonthly?.transferBytes.value}
                      percentage={metrics?.metrics.metricsMonthly?.transferBytes.percentage}
                      limit={metrics?.metrics.metricsMonthly?.transferBytes.limit}
                      currentPlan={billing?.activePricingPlan}
                    />
                    <TableNav
                      setWizardModalOpen={setWizardModalOpen}
                      metrics={metrics?.metrics}
                      setAlert={setAlert}
                      billing={billing}
                    />
                    <GatewaysTable
                      setAlert={setAlert}
                      createCustomDomain={createCustomDomain}
                      gatewayAvailability={gatewayAvailability}
                      deleteGateway={deleteGateway}
                      totalRecords={gateways && gateways.gateways ? gateways.gateways.count : 0}
                      gateways={existingGateways}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      updateGateway={updateGateway}
                      getCustomDomainDNSStatus={getCustomDomainDNSStatus}
                      deleteCustomDomain={deleteCustomDomain}
                      metrics={metrics}
                      billing={billing}
                    />
                  </>
                ) : (
                  <UpgradeCard />
                )}
              </CardContent>
            </Card>
            <Card sx={{ textAlign: 'center', paddingTop: 2 }}>
              <CardContent>
                <Typography>
                  Want to learn more about Pinata Gateways?{' '}
                  <a
                    href="https://knowledge.pinata.cloud/en/collections/3090434-gateways"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Check out the docs!
                  </a>
                </Typography>
              </CardContent>
            </Card>
          </CardsBox>
        </Container>
      {setWizardModalOpen && (
        <GatewayWizard
          userMetricLimit={metrics?.metricsLimitData}
          gateways={gateways}
          setAlert={setAlert}
          checkSubdomain={checkSubdomain}
          setWizardModalOpen={setWizardModalOpen}
          wizardModalOpen={wizardModalOpen}
          createGateway={createGateway}
          billing={billing}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    gateways: state.gateways,
    billing: state.billing,
    metrics: state?.metrics
  };
};
export default connect(mapStateToProps, {
  setAlert,
  checkSubdomain,
  createGateway,
  getAllGateways,
  deleteGateway,
  updateGateway,
  retrieveStripeCustomer,
  createCustomDomain,
  getCustomDomainDNSStatus,
  deleteCustomDomain
})(Gateway);
