import { FC, useEffect } from 'react';
import { Modal } from 'reactstrap';
import BillingAddressForm from './BillingAddressForm';

type Props = {
  modalIsOpen: boolean;
  setModalOpen: any;
  mainActionHandler?: any;
};

const BillingAddressModal: FC<Props> = ({ modalIsOpen, setModalOpen, mainActionHandler = () => {} }) => {
  return (
    <Modal isOpen={modalIsOpen} toggle={() => setModalOpen(false)} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Billing Address</h5>
        <button
          type="button"
          onClick={() => setModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <BillingAddressForm
          setModalOpen={setModalOpen}
          mainActionHandler={mainActionHandler}
        />
      </div>
    </Modal>
  );
};

export default BillingAddressModal;
