import React, { useState, useEffect } from 'react';
import {KeyPermissions} from "../../store/apiKeys/types";
import {
  Accordion, AccordionDetails,
  AccordionSummary, Box,
  Button, Collapse,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Switch, TextField, Tooltip,
  Typography
} from "@mui/material";
import {PinataDialogTitle} from "../../components/Common/MuiComponents";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface NewKeyModalProps {
  newKeyModalOpen: any;
  setNewKeyModalOpen: any;
  setAlert?: (...props: any) => void;
  generateNewKey?: (chosenPermissions: KeyPermissions, maxUses: string, keyName: string) => any;
  existingKey?: any;
  keyInfo?: {max_uses: boolean, name: string, scopes: KeyPermissions};
}

enum DataAccess {
  PinningAccess = 'PinningAccess',
  DataAccess = 'DataAccess',
  PsaPins = 'psa/pins',
}

const NewKeyModal = ({
  newKeyModalOpen,
  setNewKeyModalOpen,
  setAlert = () => {},
  generateNewKey = () => {},
  existingKey,
  keyInfo
}: NewKeyModalProps) => {
  const [pinningAccordionOpen, setPinningAccordionOpen] = useState(false);
  const [dataAcordionOpen, setDataAccordionOpen] = useState(false);
  const [psaAccordionOpen, setPSAAccordionOpen] = useState(false);
  const [psaPinsAccordionOpen, setPSAPinsAccordionOpen] = useState(false);
  const [adminKey, setAdminKey] = useState(false);
  const [maxUseLimited, setMaxUseLimited] = useState(false);
  const [maxUses, setMaxUses] = useState(1);
  const [keyName, setKeyName] = useState('');
  const [pinningAccess, setPinningAccess] = useState({
    hashMetadata: false,
    pinByHash: false,
    pinFileToIPFS: false,
    pinJobs: false,
    pinJSONToIPFS: false,
    unpin: false
  });
  const [dataAccess, setDataAccess] = useState({
    pinList: false,
    userPinnedDataTotal: false
  });
  const [psaPinsAccess, setPSAPinsAccess] = useState({
    addPinObject: false,
    getPinObject: false,
    listPinObjects: false,
    removePinObject: false,
    replacePinObject: false
  });
  const [maxUseToolTip, setMaxUseToolTipOpen] = useState(false);

  useEffect(() => {
    if (adminKey) {
      bulkUpdatePermissions(true);
    } else {
      bulkUpdatePermissions(false);
    }
  }, [adminKey]);

  useEffect(() => {
    if (existingKey && keyInfo) {
      if (keyInfo.max_uses) {
        setMaxUseLimited(true);
        setMaxUseLimited(keyInfo.max_uses);
      }
      setKeyName(keyInfo.name);
      const scopes = keyInfo.scopes;
      if (scopes && scopes.endpoints) {
        const endpoints = scopes.endpoints;
        setPSAPinsAccess(endpoints.psa ? endpoints.psa.pins : psaPinsAccess);
        setDataAccess(endpoints.data ? endpoints.data : dataAccess);
        setPinningAccess(endpoints.pinning ? endpoints.pinning : pinningAccess);
        setPinningAccordionOpen(true);
        setPSAPinsAccordionOpen(true);
        setDataAccordionOpen(true);
        setPSAPinsAccordionOpen(true);
      }
    }
  }, [keyInfo]);

  const bulkUpdatePermissions = (bool: boolean) => {
    let pinningAccessObject = deepCloneObject(pinningAccess);
    let dataAccessObject = deepCloneObject(dataAccess);
    let psaPinsAccessObject = deepCloneObject(psaPinsAccess);
    Object.keys(pinningAccessObject).forEach((key) => {
      pinningAccessObject[key] = bool;
    });
    Object.keys(dataAccessObject).forEach((key) => {
      dataAccessObject[key] = bool;
    });
    Object.keys(psaPinsAccessObject).forEach((key) => {
      psaPinsAccessObject[key] = bool;
    });
    setPinningAccess(pinningAccessObject);
    setDataAccess(dataAccessObject);
    setPSAPinsAccess(psaPinsAccessObject);
    setAdminKey(bool);
    if (bool === false) {
      setMaxUseLimited(bool);
    }
  };

  const deepCloneObject = (object: any) => {
    return JSON.parse(JSON.stringify(object));
  };

  const isEndpointChecked = (endpointParent: DataAccess, endpointName: string) => {
    let endpointObject: any = {};
    switch (endpointParent) {
      case 'PinningAccess':
        endpointObject = deepCloneObject(pinningAccess);
        break;
      case 'DataAccess':
        endpointObject = deepCloneObject(dataAccess);
        break;
      case 'psa/pins':
        endpointObject = deepCloneObject(psaPinsAccess);
        break;
      default:
        break;
    }
    return endpointObject[endpointName];
  };

  const toggleEndpoint = (endpointParent: DataAccess, endpointName: string) => {
    let endpointObject: any = {};
    switch (endpointParent) {
      case 'PinningAccess':
        endpointObject = deepCloneObject(pinningAccess);
        endpointObject[endpointName] = !endpointObject[endpointName];
        setPinningAccess(endpointObject);
        break;
      case 'DataAccess':
        endpointObject = deepCloneObject(dataAccess);
        endpointObject[endpointName] = !endpointObject[endpointName];
        setDataAccess(endpointObject);
        break;
      case 'psa/pins':
        endpointObject = deepCloneObject(psaPinsAccess);
        endpointObject[endpointName] = !endpointObject[endpointName];
        setPSAPinsAccess(endpointObject);
        break;
      default:
        break;
    }
  };

  const maxUsesChange = (e: any) => {
    if (e.target.value > -1) {
      const uses = parseInt(e.target.value, 10).toFixed(0);
      setMaxUses(Number(uses === '0' ? 1 : uses));
    } else {
      setMaxUses(0);
    }
  };

  const handleKeyCreation = () => {
    const chosenPermissions = {
      admin: adminKey,
      endpoints: {
        pinning: pinningAccess,
        data: dataAccess,
        psa: {
          pins: psaPinsAccess
        }
      }
    };

    const uses = maxUseLimited ? maxUses : 0;
    const validPerms = validatePermissions(chosenPermissions);
    if (validPerms) {
      generateNewKey(chosenPermissions, String(uses), keyName);
      bulkUpdatePermissions(false);
      setKeyName('');
    } else {
      setAlert('Please make sure you select at least one permission', 'error');
    }
  };

  const validatePermissions = (perms: KeyPermissions) => {
    if (perms.admin) {
      return true;
    } else {
      const pinningEndpointKeys = Object.keys(perms.endpoints?.pinning || {});
      for (const key of pinningEndpointKeys) {
        // @ts-ignore
        if (perms.endpoints?.pinning[key] === true) {
          return true;
        }
      }
      const dataEndpointKeys = Object.keys(perms.endpoints?.data || {});
      for (const key of dataEndpointKeys) {
        // @ts-ignore
        if (perms.endpoints?.data[key] === true) {
          return true;
        }
      }
      const psaEndpointKeys = Object.keys(perms.endpoints?.psa?.pins || {});
      for (const key of psaEndpointKeys) {
        // @ts-ignore
        if (perms.endpoints?.psa?.pins[key] === true) {
          return true;
        }
      }
      return false;
    }
  };

  const canSubmit = () => {
    if (maxUseLimited && !maxUses) {
      return false;
    }

    if (!keyName) {
      return false;
    }

    if (maxUses > 1000) {
      return false;
    }

    return true;
  };

  return (
    <Dialog open={newKeyModalOpen} onClose={() => setNewKeyModalOpen(false)}>
      <PinataDialogTitle onClose={() => setNewKeyModalOpen(false)}>
        {existingKey ? 'View Key Info' : 'Create New API Key'}
      </PinataDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h5>Admin</h5>
          </Grid>
          <Grid item xs={6} sx={{display: "flex"}}>
            <Switch
              disabled={existingKey ? true : false}
              checked={adminKey}
              onChange={() => setAdminKey(!adminKey)}
            />
            <label>Admin Keys have access to all endpoints and account settings</label>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <h5>API Endpoint Access</h5>
          </Grid>
          <Grid item xs={6}>
            <Accordion defaultExpanded={pinningAccordionOpen}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={"apikeys-newkeymodal-pinning"}>
                <Typography>Pinning</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.PinningAccess, 'hashMetadata')}
                    onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'hashMetadata')}
                  />
                  <label>hashMetadata</label>
                </div>
                {/*<div>*/}
                {/*    <ToggleSwitch*/}
                {/*        disabled={existingKey ? true : false}*/}
                {/*        checked={isEndpointChecked('pinningAccess', 'hashPinPolicy')}*/}
                {/*        toggleChecked={() => toggleEndpoint('pinningAccess', 'hashPinPolicy')}*/}
                {/*        label="hashPinPolicy"*/}
                {/*    />*/}
                {/*</div>*/}
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.PinningAccess, 'pinByHash')}
                    onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinByHash')}
                  />
                  <label>pinByHash</label>
                </div>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.PinningAccess, 'pinFileToIPFS')}
                    onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinFileToIPFS')}
                  />
                  <label>pinFileToIPFS</label>
                </div>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.PinningAccess, 'pinJobs')}
                    onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinJobs')}
                  />
                  <label>pinJobs</label>
                </div>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.PinningAccess, 'pinJSONToIPFS')}
                    onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinJSONToIPFS')}
                  />
                  <label>pinJSONToIPFS</label>
                </div>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.PinningAccess, 'unpin')}
                    onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'unpin')}
                  />
                  <label>unpin</label>
                </div>
                {/* <div>
                  <ToggleSwitch
                    checked={isEndpointChecked("pinningAccess", "userPinPolicy")}
                    toggleChecked={() => toggleEndpoint("pinningAccess", "userPinPolicy")}
                    label="userPinPolicy"
                  />
                </div> */}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={dataAcordionOpen}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={"apikeys-newkeymodal-data"}>
                <Typography>Data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.DataAccess, 'pinList')}
                    onChange={() => toggleEndpoint(DataAccess.DataAccess, 'pinList')}
                  />
                  <label>pinList</label>
                </div>
                <div>
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={isEndpointChecked(DataAccess.DataAccess, 'userPinnedDataTotal')}
                    onChange={() => toggleEndpoint(DataAccess.DataAccess, 'userPinnedDataTotal')}
                  />
                  <label>userPinnedDataTotal</label>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={psaAccordionOpen}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={"apikeys-newkeymodal-pinservices"}>
                <Typography>Pinning Services API</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Accordion defaultExpanded={psaPinsAccordionOpen}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} id={"apikeys-newkeymodal-pinservices-pins"}>
                    <Typography>Pins</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PsaPins, 'addPinObject')}
                        onChange={() => toggleEndpoint(DataAccess.PsaPins, 'addPinObject')}
                      />
                      <label>addPinObject</label>
                    </div>
                    <div>
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PsaPins, 'getPinObject')}
                        onChange={() => toggleEndpoint(DataAccess.PsaPins, 'getPinObject')}
                      />
                      <label>getPinObject</label>
                    </div>
                    <div>
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PsaPins, 'listPinObjects')}
                        onChange={() => toggleEndpoint(DataAccess.PsaPins, 'listPinObjects')}
                      />
                      <label>listPinObjects</label>
                    </div>
                    <div>
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PsaPins, 'removePinObject')}
                        onChange={() => toggleEndpoint(DataAccess.PsaPins, 'removePinObject')}
                      />
                      <label>removePinObject</label>
                    </div>
                    <div>
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PsaPins, 'replacePinObject')}
                        onChange={() => toggleEndpoint(DataAccess.PsaPins, 'replacePinObject')}
                      />
                      <label>replacePinObject</label>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <h5>Limit Max Uses</h5>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{display: "flex"}}>
              <Switch
                disabled={existingKey ? true : false}
                checked={maxUseLimited}
                onChange={() => setMaxUseLimited(!maxUseLimited)}
              />
              <label>When enabled, you can set the maximum number of times a key can be used</label>
            </Box>
              <Collapse in={maxUseLimited}>
                <Tooltip
                  title={"Limited use keys can have a maximum of 1,000 uses."}
                  placement={"right"}
                >
                  <TextField
                    id="maxUse"
                    type="number"
                    value={maxUses}
                    onChange={existingKey ? () => {} : maxUsesChange}
                    color={maxUses > 1000 ? 'error' : 'primary'}
                    label={"Max Uses"}
                  />
                </Tooltip>
              </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <h5>Key Name</h5>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Key Name"}
              disabled={existingKey ? true : false}
              value={keyName}
              onChange={existingKey ? () => {} : (e) => setKeyName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            {existingKey ? (
              <Button onClick={() => setNewKeyModalOpen(false)}>
                Done
              </Button>
            ) : (
              <Button
                disabled={!canSubmit()}
                onClick={handleKeyCreation}
              >
                Create Key
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NewKeyModal;
