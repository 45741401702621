import React from 'react';
import PropTypes from 'prop-types';
import { BillingPlan, Plan } from '../../store/billing/types';
import {Button, Card, CardContent, Typography} from "@mui/material";

interface CardPricingProps {
  plan: BillingPlan;
  action: any;
  currentPlan: Plan | null;
  nextPlan: Plan | null;
}

//pinata-pricing-cards-active

const CardPricing = ({ plan, action, currentPlan, nextPlan }: CardPricingProps) => {
  return (
    <li>
      <Card sx={{height: "100%"}} className={`${currentPlan?.type === plan.type ? 'pinata-pricing-cards-active' : ''}`}>
        <CardContent sx={{height: "100%"}}>
          <div className="d-flex h-100 flex-column align-items-center justify-content-between">
            <div className="text-center">
              <Typography variant="h4">{plan.nickname}</Typography>
              <Typography variant="caption">{plan.subtitle}</Typography>
              <p className="d-flex flex-column text-muted text-weight-700 text-center mh-25">
                <span style={{ fontSize: '4em' }}>${plan.price}</span>
                <span style={{ fontSize: '1em' }}>/month</span>
              </p>
              <div className="d-flex flex-column align-items-center">
                <ul className="font-size-16 list-unstyled text-left">
                  {plan?.features?.length > 0 &&
                    plan.features.map((feature, index) => {
                      return (
                        <li key={`feature_${index}`} className="mb-1">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-check mr-3 text-primary align-self-baseline mt-1" />
                            <span>{feature}</span>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            {currentPlan?.type !== plan.type && nextPlan?.type !== plan.type && (
              <Button
                disabled={currentPlan?.type === plan.type}
                onClick={() => action(plan)}
                size="large"
                sx={{marginTop: 2}}
              >
                Select {plan.nickname}
              </Button>
            )}
            {/* do not allow user click one more time to the already chosen plan */}
            {nextPlan?.type === plan.type && currentPlan?.type !== plan.type && (
              <Button disabled={nextPlan?.type === plan.type} size="large" sx={{marginTop: 2}}>
                Your Next Plan
              </Button>
            )}
            {
              currentPlan?.type === plan.type && nextPlan && nextPlan.type !== plan.type && <>
                <Typography variant="caption" sx={{marginTop: 2}}>Your plan will be downgraded</Typography>
                <Button size="large" onClick={() => action(plan)}>
                  Keep my current plan
                </Button>
              </>
            }
          </div>
        </CardContent>
      </Card>
    </li>
  );
};

CardPricing.propTypes = {
  pricing: PropTypes.object
};

export default CardPricing;
