import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import {
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

import RemovePinModal from './RemovePinModal';
import MetadataModal from './MetadataModal';
import EditPinPolicy from '../../components/Common/EditPinPolicy';
import GenerateAccessTokenModal from './GenerateAccessTokenModal';
import MakePublicModal from './MakePublicModal';
import ConfirmationModal from 'pages/Gateway/ConfirmationModal';
import SelectGatewayModal from '../Gateway/SelectGatewayModal';
import ContentPreviewModal from './ContentPreviewModal';

import { setAlert } from '../../store/alert/alert.actions';
import { getAllGateways, setRootContent } from '../../store/gateways/gateway.actions';
import {
  putHashMetadata,
  removePin,
  changeHashPinPolicy,
  generateAccessToken,
  listFolderContents,
  makeSubmarinedFilePublic,
  setPinContentPreviewOptions,
  removePinContentPreviewOptions
} from '../../actions/pin.actions';
import type { MetricsState } from '../../store/metrics/types';
import type { BillingState } from '../../store/billing/types';
import type { PinData } from '../../store/data/types';
import type { GatewaysState } from '../../store/gateways/types';

import { planTypes } from 'helpers/enums';
import { validateMetricsLimits } from '../../helpers/validations';
import { makeDatePretty, prettySize } from 'helpers/pretty';

interface PinTableProps {
  files: any;
  fileCount: number;
  setAlert: (message: string | React.ReactElement, type: string) => void;
  putHashMetadata: any;
  removePin: any;
  currentPage: number;
  setCurrentPage: (pageChangeTo: number) => void;
  submitTableFilters: () => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  changeHashPinPolicy: (
    hash: string,
    policy: {
      version: number;
      regions: any[];
    }
  ) => void;
  gatewayToUse: string;
  selectedPinStatus: 'pinned' | 'unpinned' | 'all';
  generateAccessToken: (time: number, id: string) => void;
  listFolderContents: any;
  folderDetails: any;
  makeSubmarinedFilePublic: (pinInfo: any) => any;
  billing: BillingState;
  metrics: MetricsState;
  setPinContentPreviewOptions: (config: any) => void;
  removePinContentPreviewOptions: (id: string) => void;
  gateways: GatewaysState;
  getAllGateways: any;
  setRootContent: (gatewayId: string, pin: any) => any;
}

const PinTable = (props: PinTableProps) => {
  const {
    files,
    fileCount,
    setAlert,
    putHashMetadata,
    removePin,
    currentPage,
    setCurrentPage,
    submitTableFilters,
    rowsPerPage,
    setRowsPerPage,
    changeHashPinPolicy,
    gatewayToUse,
    selectedPinStatus,
    generateAccessToken,
    listFolderContents,
    folderDetails,
    makeSubmarinedFilePublic,
    billing,
    metrics,
    setPinContentPreviewOptions,
    removePinContentPreviewOptions,
    gateways,
    getAllGateways,
    setRootContent
  } = props;
  const [showEditMetaDataModal, setShowEditMetaDataModal] = useState<boolean>(false);
  const [keyValues, setKeyValues] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const [removePinModalOpen, setRemovePinModalOpen] = useState<boolean>(false);
  const [pinInfo, setPin] = useState<any | null>(null);
  const [pinPolicyModalOpen, setPinPolicyModalOpen] = useState<boolean>(false);
  const [pinRegionPermissions, setPinRegionPermissions] = useState<any[]>([]);
  const [v2Id, setV2Id] = useState<string | null>(null);
  const [cidAccessModalOpen, setCIDAccessModalOpen] = useState<boolean>(false);
  const [accessTime, setAccessTime] = useState<string>('3600');
  const [makePublicModalOpen, setMakePublicModalOpen] = useState<boolean>(false);
  const [loadingFolderFiles, setLoadingFolderFiles] = useState<boolean>(false);
  const [contentPreviewModalOpen, setContentPreviewModalOpen] = useState<boolean>(false);
  const [openConfirmDeletionDialog, setOpenConfirmDeletionDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectGatewayModalOpen, setSelectGatewayModalOpen] = useState<boolean>(false);

  const [moreModalAnchorEl, setMoreModalAnchorEl] = useState<null | HTMLElement>(null);
  const isMoreMenuOpen = Boolean(moreModalAnchorEl);

  const canAddNewKeyValue = useMemo(() => newKey && newValue, [newKey, newValue]);
  const isAnyMetricLimitReached = useMemo(() => validateMetricsLimits(metrics.metrics, billing), [metrics?.metrics, billing]);

  const getFileName = (row: PinData) => {
    //  This would mean we are inside of a V2 directory
    if (folderDetails && row.originalname) {
      const items = row.originalname.split('/');
      if (items.length > 1) {
        items.shift();
      }
      return items.join('/');
    } else {
      if (row.name) {
        return row.name;
      }
      if (row.metadata && typeof row.metadata === 'string') {
        const parsed = JSON.parse(row.metadata);
        if (parsed.name) {
          return parsed.name;
        }
      } else if (row.metadata && row.metadata.name) {
        return row.metadata.name;
      }
    }

    return 'No name set';
  };

  const copyHash = (hash: string) => {
    navigator.clipboard.writeText(hash);
    setAlert('Copied!', 'success');
  };

  const handleMetadata = (row: PinData) => {
    setKeyValues([]);
    setHash(row.ipfs_pin_hash);
    setV2Id(row.v2 ? row.id : null);
    if (row.metadata && row.metadata.keyvalues) {
      const keys = Object.keys(row.metadata.keyvalues);
      let keyValArray = [];
      for (const key of keys) {
        const newObject = {
          id: uuidv4(),
          key: key,
          value: row.metadata.keyvalues[key]
        };
        keyValArray.push(newObject);
      }
      setKeyValues(keyValArray);
    } else if (row.metadata) {
      const keys = Object.keys(row.metadata);
      let keyValArray = [];
      for (const key of keys) {
        if (key !== 'name') {
          const newObject = {
            id: uuidv4(),
            key: key,
            value: row.metadata.keyvalues
          };
          keyValArray.push(newObject);
        }
      }
      setKeyValues(keyValArray);
    }
    setFileName(getFileName(row));
    setShowEditMetaDataModal(true);
  };

  const handleDeleteKeyValue = (id: any) => {
    const updatedKeyValues = [...keyValues];
    const index = updatedKeyValues.map((k) => k.id).indexOf(id);
    if (index > -1) {
      updatedKeyValues[index].value = null;
    }
    setKeyValues(updatedKeyValues);
  };

  const handleAddKeyValue = () => {
    if (canAddNewKeyValue) {
      const keyValuesToUpdate = [...keyValues];
      keyValuesToUpdate.push({ key: newKey, value: newValue, id: uuidv4() });
      setKeyValues(keyValuesToUpdate);
      setNewKey('');
      setNewValue('');
    } else {
      setAlert('Enter a key and a value', 'error');
    }
  };

  const handleSaveMetaData = () => {
    const metadataObj: any = {
      name: fileName
    };
    if (keyValues.length > 0) {
      const keyValObj: any = {};
      for (const item of keyValues) {
        keyValObj[item.key] = item.value;
      }
      metadataObj['keyvalues'] = keyValObj;
    }
    putHashMetadata(hash, metadataObj, submitTableFilters, v2Id);
    setV2Id(null);
    setShowEditMetaDataModal(false);
  };

  const handleRemovePin = () => {
    const contentId = pinInfo.v2 ? pinInfo.id : null;
    removePin(hash, submitTableFilters, contentId);
    setAlert('Removing content...', 'info');
    setRemovePinModalOpen(false);
    setPin(null);
  };

  const handleRemovePinModal = (row: PinData) => {
    setHash(row.ipfs_pin_hash);
    setPin(row);
    setRemovePinModalOpen(true);
  };

  const handleSavePinPolicy = (regions: any[]) => {
    const newPinPolicy: any = {
      version: 1,
      regions: regions.map((el) => {
        return {
          id: el.regionId,
          desiredReplicationCount: el.currentDesiredReplicationCount
        };
      })
    };

    changeHashPinPolicy(pinInfo.ipfs_pin_hash, newPinPolicy);
    setPin(null);
    setPinPolicyModalOpen(false);
  };

  const setMetricsRestrictionAlert = () => {
    setAlert(
      <span>
        This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
      </span>,
      'error'
    );
  };

  const generateToken = async (row: PinData) => {
    if (isAnyMetricLimitReached) {
      setMetricsRestrictionAlert();
      return;
    }
    setPin(row);
    if (gatewayToUse === 'gateway.pinata.cloud') {
      setAlert('You need to create a dedicated gateway first', 'error');
    } else {
      setV2Id(row.id);
      setCIDAccessModalOpen(true);
    }
  };

  const handleSetPreview = (row: PinData) => {
    if (isAnyMetricLimitReached) {
      setMetricsRestrictionAlert()
      return;
    }
    setPin(row);
    setContentPreviewModalOpen(true);
  };

  const handlePreviewDeletion = (row: PinData) => {
    setPin(row);
    setOpenConfirmDeletionDialog(true);
  };

  const handleFolderSelect = async (row: PinData) => {
    setPin(row);
    setLoadingFolderFiles(true);
    await listFolderContents(row, 0);
  };

  const getPath = (row: PinData) => {
    if (row?.v2 && folderDetails) {
      return `${folderDetails.ipfs_pin_hash}/${getFileName(row)}`;
    }
    return row?.ipfs_pin_hash;
  };

  const handleSetGatewayRoot = (row: PinData) => {
    setPin(row);
    setSelectGatewayModalOpen(true);
  };

  const generateLinkAndGo = async (row: PinData) => {
    if (gatewayToUse === 'gateway.pinata.cloud') {
      setAlert('You need to create a dedicated gateway first', 'error');
    } else {
      setAlert('Generating access token...', 'info');
      let link;
      let id;
      let token;
      if (folderDetails) {
        id = row.id;
        token = await generateAccessToken(parseInt(accessTime, 10), id);
        link = `https://${gatewayToUse}${row.uri}?accessToken=${token}`;
      } else {
        id = row.id;
        token = await generateAccessToken(parseInt(accessTime, 10), id);
        link = `https://${gatewayToUse}/ipfs/${row.ipfs_pin_hash}?accessToken=${token}`;
      }

      window.open(link);
    }
  };

  const stopSubmarining = (row: PinData) => {
    setPin(row);
    setHash(row.ipfs_pin_hash);
    setMakePublicModalOpen(true);
  };

  const setContentPreviewSettings = async (config: any) => {
    try {
      setLoading(true);
      await setPinContentPreviewOptions(config);
      setContentPreviewModalOpen(false);
      setPin(null);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmPreviewDeletion = async () => {
    try {
      setLoading(true);
      await removePinContentPreviewOptions(pinInfo.id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpenConfirmDeletionDialog(false);
      setPin(null);
    }
  };

  const handleMakePublic = async () => {
    setAlert('Making file public...', 'info');
    setMakePublicModalOpen(false);
    const success = await makeSubmarinedFilePublic(pinInfo);
    if (success) {
      setAlert('File will be added to the IPFS network soon!', 'success');
    }
  };

  const isPreviewContentAvailable = useMemo(() => {
    return (gatewayToUse !== 'gateway.pinata.cloud' && billing?.activePricingPlan?.type) || 0 > planTypes.FREE.type;
  }, [billing?.activePricingPlan]);

  const renderRow = (row: PinData, key: any) => {
    return (
      <TableRow key={key}>
        <TableCell className="max-width-2">
          {row.type === 'D' && row.v2 ? (
            <Link to="#" onClick={() => handleFolderSelect(row)}>
              {getFileName(row)}
            </Link>
          ) : (folderDetails && row.type === 'C') || (row.type === 'F' && row.v2) ? (
            <Link to="#" onClick={() => generateLinkAndGo(row)}>
              {getFileName(row)} <i className="fas fa-eye"></i>
            </Link>
          ) : (
            <a
              href={`https://${gatewayToUse}/ipfs/${getPath(row)}${isPreviewContentAvailable ? '?preview=1' : ''}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrap"
            >
              {getFileName(row)} <i className="fas fa-eye" />
            </a>
          )}{' '}
          <br />
          <span className="text-muted">
            {makeDatePretty(row.date_pinned)} {prettySize(row.size, true)}
          </span>
        </TableCell>
        <TableCell>
          <Link to="#" className="pinata-link-button pinata-mono" onClick={() => copyHash(row.ipfs_pin_hash)}>
            {row.ipfs_pin_hash} <i className="fas fa-copy"></i>
          </Link>
        </TableCell>
        <TableCell className="text-center">{row.submarined ? 'True' : 'False'}</TableCell>
        {selectedPinStatus !== 'pinned' && (
          <TableCell>{row.date_unpinned ? makeDatePretty(row.date_unpinned) : 'Currently Pinned'}</TableCell>
        )}

        <TableCell>
          <Link
            to="#"
            className="font-size-12 text-primary"
            onClick={(event) => {
              setPin(row);
              setMoreModalAnchorEl(event.currentTarget);
            }}
            aria-controls={isMoreMenuOpen ? 'more-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMoreMenuOpen ? 'true' : undefined}
          >
            More
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="pinata-table-head">
              <TableCell>Name</TableCell>
              <TableCell>CID</TableCell>
              <TableCell>Submarined</TableCell>
              {selectedPinStatus !== 'pinned' && <TableCell>Date Unpinned</TableCell>}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file: any) => renderRow(file, file.id))}
            {pinInfo && (
              <Menu anchorEl={moreModalAnchorEl} open={isMoreMenuOpen} onClose={() => {
                setMoreModalAnchorEl(null);
                setPin(null);
              }}>
                {!folderDetails && (
                  <MenuItem
                    onClick={() => {
                      setMoreModalAnchorEl(null);
                      handleMetadata(pinInfo);
                    }}
                  >
                    Edit Details
                  </MenuItem>
                )}
                {!pinInfo?.v2 && billing?.activePricingPlan?.type !== planTypes.FREE.type && (
                  <MenuItem>
                    <span
                      onClick={() => {
                        setMoreModalAnchorEl(null);
                        handleSetPreview(pinInfo);
                      }}
                    >
                      Preview Options
                    </span>
                    <IconButton
                      onClick={() => {
                        setMoreModalAnchorEl(null);
                        handlePreviewDeletion(pinInfo);
                      }}
                    >
                      <i className="fa fa-trash text-danger" aria-hidden="true" />
                    </IconButton>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setMoreModalAnchorEl(null);
                    generateToken(pinInfo);
                  }}
                >
                  Share
                </MenuItem>
                {pinInfo?.v2 && pinInfo?.type !== 'C' && (
                  <MenuItem
                    onClick={() => {
                      setMoreModalAnchorEl(null);
                      stopSubmarining(pinInfo);
                    }}
                  >
                    Stop Submarining
                  </MenuItem>
                )}
                {billing?.activePricingPlan?.name !== 'Free' && (
                  <MenuItem
                    onClick={() => {
                      setMoreModalAnchorEl(null);
                      handleSetGatewayRoot(pinInfo);
                    }}
                  >
                    Set as gateway root
                  </MenuItem>
                )}
                {!pinInfo?.date_unpinned && !folderDetails && <Divider />}
                {!pinInfo?.date_unpinned && !folderDetails && (
                  <MenuItem
                    onClick={() => {
                      setMoreModalAnchorEl(null);
                      handleRemovePinModal(pinInfo);
                    }}
                  >
                    Delete File
                  </MenuItem>
                )}
              </Menu>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={fileCount}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              display: 'none',
            }
          }}
        />
      </TableContainer>
      {showEditMetaDataModal && (
        <MetadataModal
          userMetricsLimit={metrics?.metricsLimitData}
          newKey={newKey}
          setNewKey={setNewKey}
          newValue={newValue}
          setNewValue={setNewValue}
          handleDeleteKeyValue={handleDeleteKeyValue}
          handleSaveMetaData={handleSaveMetaData}
          handleAddKeyValue={handleAddKeyValue}
          fileName={fileName}
          setFileName={setFileName}
          keyValues={keyValues}
          showEditMetaDataModal={showEditMetaDataModal}
          setShowEditMetaDataModal={setShowEditMetaDataModal}
        />
      )}
      {removePinModalOpen && (
        <RemovePinModal
          userMetricsLimit={metrics?.metricsLimitData}
          hash={hash}
          handleRemovePin={handleRemovePin}
          setRemovePinModalOpen={setRemovePinModalOpen}
          removePinModalOpen={removePinModalOpen}
        />
      )}
      {pinPolicyModalOpen && (
        <EditPinPolicy
          userMetricsLimit={metrics?.metricsLimitData}
          regionPermissions={pinRegionPermissions}
          open={pinPolicyModalOpen}
          toggleModal={setPinPolicyModalOpen}
          pin={pinInfo}
          savePolicy={handleSavePinPolicy}
        />
      )}
      {cidAccessModalOpen && (
        <GenerateAccessTokenModal
          userMetricsLimit={metrics?.metricsLimitData}
          getPath={getPath}
          setAlert={setAlert}
          gatewayToUse={gatewayToUse}
          setPin={setPin}
          pinInfo={pinInfo}
          cidAccessModalOpen={cidAccessModalOpen}
          setCIDAccessModalOpen={setCIDAccessModalOpen}
        />
      )}
      {contentPreviewModalOpen && (
        <ContentPreviewModal
          userMetricsLimit={metrics?.metricsLimitData}
          setAlert={setAlert}
          contentPreviewModalOpen={contentPreviewModalOpen}
          toggleModal={setContentPreviewModalOpen}
          loading={loading}
          action={setContentPreviewSettings}
          pinInfo={pinInfo}
          currentPlan={billing?.activePricingPlan}
        />
      )}
      {openConfirmDeletionDialog && (
        <ConfirmationModal
          title="Remove Preview Options?"
          text="After deletion you can set options again."
          modalOpen={openConfirmDeletionDialog}
          toggleModal={setOpenConfirmDeletionDialog}
          loading={loading}
          action={confirmPreviewDeletion}
        />
      )}
      {selectGatewayModalOpen && (
        <SelectGatewayModal
          modalOpen={selectGatewayModalOpen}
          toggleModal={setSelectGatewayModalOpen}
          pinInfo={pinInfo}
          gateways={gateways}
          getAllGateways={getAllGateways}
          setRootContent={setRootContent}
        />
      )}
      {makePublicModalOpen && (
        <MakePublicModal
          userMetricLimit={metrics?.metricsLimitData}
          makePublicModalOpen={makePublicModalOpen}
          setMakePublicModalOpen={setMakePublicModalOpen}
          hash={hash}
          handleMakePublic={handleMakePublic}
        />
      )}
    </>
  );
};

export default connect(null, {
  setAlert,
  putHashMetadata,
  removePin,
  changeHashPinPolicy,
  generateAccessToken,
  listFolderContents,
  makeSubmarinedFilePublic,
  setPinContentPreviewOptions,
  removePinContentPreviewOptions,
  getAllGateways,
  setRootContent
})(PinTable);
