import { makeDatePretty } from 'helpers/pretty';

import React, { useState } from 'react';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DeleteKeyModal from './DeleteKeyModal';
import {Button, TableCell, TableHead, TableRow, Table, TableBody, Box, Typography} from "@mui/material";

interface KeyTableProps {
  keys: any;
  pageLimit: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalKeys: number;
  setAlert: any;
  deleteV2Key: any;
}

const KeyTable = ({ keys, pageLimit, totalKeys, currentPage, setCurrentPage, setAlert, deleteV2Key }: KeyTableProps) => {
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const [keyInfo, setKeyInfo] = useState<any | null>(null);

  const handlePageChange = (pageToChangeTo: number) => {
    setCurrentPage(pageToChangeTo);
  };

  const renderPagination = () => {
    const pages = Math.ceil(totalKeys / pageLimit);
    let pageArray = [];
    for (let i = 0; i < pages; i++) {
      pageArray.push(i + 1);
    }
    return pageArray.map((p) => {
      return (
        <PaginationItem key={p} active={currentPage === p ? true : false}>
          <PaginationLink onClick={() => handlePageChange(p)}>{p}</PaginationLink>
        </PaginationItem>
      );
    });
  };

  const openDeleteModal = (keyInfo: any) => {
    setKeyInfo(keyInfo);
    setDeleteKeyModalOpen(true);
  };

  const confirmDeleteKey = async () => {
    setDeleteKeyModalOpen(false);
    setAlert('Revoking key...', 'info');
    await deleteV2Key(keyInfo.id);
    setKeyInfo(null);
  };

  const copyKey = (key: string) => {
    navigator.clipboard.writeText(key);
    setAlert('Copied!');
  };

  const renderRow = (row: { key: any, created_at: any }, key: any) => {
    return (
      <TableRow key={key}>
        <TableCell>
          <Box className="pinata-link-button pinata-mono" onClick={() => copyKey(row.key)}>
            <Typography variant="body2"> {row.key} <i style={{ marginLeft: 5 }} className="fas fa-copy"></i></Typography>
          </Box>
        </TableCell>
        <TableCell>{makeDatePretty(new Date(row.created_at * 1000).toISOString())}</TableCell>
        <TableCell>
          <Button onClick={() => openDeleteModal(row)} >
            Revoke
          </Button>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <React.Fragment>
      <div className="mt-4">
        <div className="table-responsive file-manager pinata-responsive-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell scope="col">Key</TableCell>
                <TableCell scope="col">Date Issued</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{keys.map((k: any, key: any) => renderRow(k, key))}</TableBody>
          </Table>
          <Pagination className="my-4">{renderPagination()}</Pagination>
        </div>
      </div>
      {keyInfo && (
        <DeleteKeyModal
          keyInfo={keyInfo}
          setDeleteKeyModalOpen={setDeleteKeyModalOpen}
          deleteKeyModalOpen={deleteKeyModalOpen}
          confirmDeleteKey={confirmDeleteKey}
        />
      )}
    </React.Fragment>
  );
};

export default KeyTable;
