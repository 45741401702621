import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ setAlert, handleAddCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsDisabled(true);
      if (!stripe || !elements) return;
      const cardElement = elements?.getElement(CardElement);
      // Use your card Element with other Stripe.js APIs
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        setAlert(error.message, 'error');
        return;
      }

      if (token.id) {
        handleAddCard(token.id);
      } else {
        setAlert('Invalid Payment Method Submitted', 'error');
      }
    } catch (error) {
      setAlert(e.message, 'error');
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <form className="my-2" onSubmit={handleSubmit}>
      <CardElement options={{ hidePostalCode: true }} />
      <button className="btn btn-primary btn-block mt-4" type="submit" disabled={!stripe || isDisabled}>
        Add Credit Card
      </button>
    </form>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const PaymentMethod = ({ setAlert, handleAddCard }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm handleAddCard={handleAddCard} setAlert={setAlert} />
  </Elements>
);

export default PaymentMethod;
