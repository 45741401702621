import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import UsageCard from './UsageCard';
import { userPinnedDataTotal } from '../../store/data/data.actions';
import {
    retrieveStripeCustomer,
    createStripePaymentSource,
    detachStripeSourceFromCustomer,
    setDefaultCard,
    changePlan,
    getBillingHistory
} from '../../store/billing/billing.actions';
import { setAlert } from '../../store/alert/alert.actions';
import { getAllGateways, killGateways, checkUsage } from 'store/gateways/gateway.actions';
import {BillingState} from "../../store/billing/types";
import {MetricsState} from "../../store/metrics/types";
import {Container, Typography} from "@mui/material";
import {useLocation} from "react-router-dom";
import {planTypes} from "../../helpers/enums";

interface BillingProps {
    userPinnedDataTotal: any;
    data: any;
    retrieveStripeCustomer: any;
    billing: BillingState;
    setAlert: any;
    changePlan: any;
    gateways: { gateways: any },
    getAllGateways: any;
    killGateways: any;
    metrics: MetricsState;
}

const index = (props: BillingProps) => {
    const {
        userPinnedDataTotal,
        data,
        billing,
        setAlert,
        changePlan,
        gateways: { gateways },
        getAllGateways,
        killGateways,
        metrics
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tabHasFocus, setTabHasFocus] = useState(true);
    const {search} = useLocation();
    const upgradeToEnterprise = new URLSearchParams(search).get('upgradeToEnterprise');

    const handleEnterpriseContact = () => {
        window.Intercom('showNewMessage', "I'd like to upgrade to the enterprise plan");
        window.Intercom('update', {
            enterpriseUpgradeRequested: true
        });
    }

    useEffect(() => {
        if (upgradeToEnterprise) {
            location.href="#enterprise-banner";
            handleEnterpriseContact();
        }
        userPinnedDataTotal();
        getAllGateways(1);
        setIsModalOpen(true);
    }, []);

    useEffect(() => {
        const handleFocus = () => {
            setTabHasFocus(true);
        };

        const handleBlur = () => {
            setTabHasFocus(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    return (
            <Container maxWidth={"xl"}>
                <div>
                    {/* Show modal-notification for users who're using old-plan system */}
                    {billing?.activePricingPlan?.isLegacy && (
                        <Modal isOpen={isModalOpen} fullscreen="true" centered>
                            <ModalHeader tag="h4" className="d-flex flexalign-items-center justify-content-center">
                                Pinata's Pricing is Changing
                            </ModalHeader>
                            <ModalBody className="text-center font-size-15">
                                <p>
                                    As we mentioned in our email to all customers, Pinata's pricing is changing. These
                                    changes are to support continued growth and development as well as help customers
                                    better manage their usage. No one likes a surprise bill so we've created a
                                    tiered pricing system that makes everything more predictable.
                                </p>
                                <Typography><strong>You need to select a new plan</strong></Typography>
                            </ModalBody>
                            <ModalFooter>
                                <Button block color="primary" onClick={() => setIsModalOpen(false)}>
                                    I understand
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )}
                    {/* Render Breadcrumbs */}
                    <Typography variant="h3">Billing</Typography>
                    { (billing?.activePricingPlan?.name || billing?.activePricingPlan?.isLegacy) && (
                        <UsageCard
                            data={data}
                            billing={billing}
                            setAlert={setAlert}
                            changePlan={changePlan}
                            gateways={gateways}
                            killGateways={killGateways}
                            metrics={metrics}
                            tabHasFocus={tabHasFocus}
                        />
                    )}
                    { billing?.activePricingPlan?.type !== planTypes.ENTERPRISE.type &&
                        <div className={'enterprise-banner-container'} id={'enterprise-banner'}>
                            <div className={'enterprise-banner-content'}>
                                <p>GET A PERSONALIZED PLAN</p>
                                <h2>Enterprise</h2>
                                <p className={'enterprise-description'}>For serious projects, brands, and organizations
                                    that require enterprise-grade features, custom packaging, and 1:1 support.</p>
                                <Button onClick={handleEnterpriseContact} color={'primary'}>Contact Us</Button>
                            </div>
                        </div>
                    }
                </div>
            </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        data: state.data,
        billing: state.billing,
        gateways: state.gateways,
        metrics: state.metrics,
    };
};

export default connect(mapStateToProps, {
    userPinnedDataTotal,
    retrieveStripeCustomer,
    createStripePaymentSource,
    detachStripeSourceFromCustomer,
    setAlert,
    setDefaultCard,
    changePlan,
    getAllGateways,
    killGateways,
    checkUsage,
    getBillingHistory
})(index);
