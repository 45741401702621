import {CardsBox} from "../../components/Common/MuiComponents";
import {Box, Card, CardContent, Container, Tab, Tabs, Typography} from "@mui/material";
import React, {SyntheticEvent, useEffect, useState} from "react";
import APIKeys from "../APIKeys";
import V2APIKeys from "../V2APIKeys";
import GatewaySettings from "./GatewaySettings/GatewaySettings";
import {GatewaysState} from "../../store/gateways/types";
import {PinataState} from "../../store/types";
import {connect} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {getAllGateways} from "../../store/gateways/gateway.actions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

const tabsMapping = ['api-keys', 'v2-api-keys', 'gateway-settings'];

interface DevelopersProps {
  gateways: GatewaysState;
  getAllGateways: (page: number) => void;
}

function Developers(props: DevelopersProps) {
  const {gateways, getAllGateways} = props;
  const [tabValue, setTabValue] = useState(0);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleChange = (event: SyntheticEvent, newTabIndex: number) => {
    history.push(`/developers/${tabsMapping[newTabIndex]}`);
    setTabValue(newTabIndex);
  };

  useEffect(() => {
    tabsMapping.forEach((tab, index) => {
      if (pathname.includes(tab)) {
        setTabValue(index);
      }
    })
  }, [pathname]);

  return (
    <Container>
      <Typography variant="h3">Developers</Typography>
      <CardsBox>
        <Card>
          <CardContent>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Api Keys"  />
              <Tab label="Submarine Keys"  />
              <Tab label="Gateway Settings"  />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <APIKeys />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <V2APIKeys />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <GatewaySettings gateways={gateways.gateways} getAllGateways={getAllGateways}/>
            </TabPanel>
          </CardContent>
        </Card>
      </CardsBox>
    </Container>
  );
}

const mapStateToProps = (state: PinataState) => {
  return {
    gateways: state.gateways
  }
}

export default connect(mapStateToProps, {getAllGateways})(Developers);