import React, { useState, useEffect } from 'react';
import { Spinner, Alert } from 'reactstrap';
import pinataSub from "./pinataSub.png";
const IPFS = require('ipfs-core');
let ipfs = null;

const CIDCheckUploader = ({ cid, setCid }) => {
    const [fileList, setFileList] = useState(null);
    const [cidChecked, setCidChecked] = useState({
        0: true,
        1: false
    });
    const [ipfsReady, setIpfsReady] = useState(false);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (window.ipfs && window.ipfs.enable) {
            handleExistingIpfsWindow();
        } else if (!ipfs) {
            startIpfs();
        } else {
            setIpfsReady(true);
        }
        return () => {
            stopIpfs();
        };
    }, []);

    const handleExistingIpfsWindow = async () => {
        ipfs = await window.ipfs.enable({ commands: ['id'] });
        setIpfsReady(true);
    };

    const startIpfs = async () => {
        ipfs = await IPFS.create({
            offline: true,
            start: false
        });
        setIpfsReady(true);
    };

    const stopIpfs = async () => {
        if (ipfs && ipfs.stop) {
            ipfs.stop().catch((err) => console.error(err));
            ipfs = null;
            setIpfsReady(false);
        }
    };

    const toggleCidChecked = (version) => {
        const clonedState = JSON.parse(JSON.stringify(cidChecked));
        if (version === 'v0') {
            clonedState['0'] = true;
            clonedState['1'] = false;
        } else {
            clonedState['0'] = false;
            clonedState['1'] = true;
        }
        setCidChecked(clonedState);
    };

    const getCheckedCidVersion = () => {
        const keys = Object.keys(cidChecked);
        for (const key of keys) {
            if (cidChecked[key]) {
                return key;
            }
        }
    };

    const handleFile = async (e) => {
        setFileList(e.target.files);
        setUploading(true);
        await getCid(e.target.files);
        setUploading(false);
    };

    const getCid = async (files) => {
        const versionToUse = getCheckedCidVersion();
        setUploading(true);
        let cid = await ipfs.add(files[0], {
            onlyHash: true,
            cidVersion: parseInt(versionToUse, 10)
        });
        setCid(cid.cid.toString());
        setUploading(false);
    };

    return (
        <div>
            {ipfsReady ? (
                <div>
                    <div>
                        <Alert color="info" className="alert-dismissible fade show" role="alert">
          
                                <img style={{ height: 15, marginTop: "-3px", marginRight: 5 }} src={pinataSub} alt="Image of a yellow submarine" /> Pinata uses CID Version 0 by default
         
                        </Alert>
                        <div className="form-check mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="cidV0"
                                id="v0"
                                value="v0"
                                onChange={() => toggleCidChecked('v0')}
                                checked={cidChecked['0']}
                            />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                CID Version 0 (Qm..)
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="cidV1"
                                id="v1"
                                value="v1"
                                onChange={() => toggleCidChecked('v1')}
                                checked={cidChecked['1']}
                            />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                CID Version 1 (ba..)
                            </label>
                        </div>
                    </div>
                    {uploading ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '85%',
                                margin: 'auto',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Spinner />
                        </div>
                    ) : (
                        <div>
                            <label className="dropzone pinata-cid-upload-custom" htmlFor="pinata-cid-upload">
                                <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Click to upload</h4>
                            </label>
                            <input id="pinata-cid-upload" type="file" name="file" onChange={handleFile} />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <h3>Waiting for IPFS...</h3>
                </div>
            )}
        </div>
    );
};

export default CIDCheckUploader;
