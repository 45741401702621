import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { deleteUserPreview, getUserPreview, setUserPreview } from '../../store/user/user.actions';
import { S3PreviewBucketURL } from '../Documentation/templates/constants';
import GeneralModal from '../../components/Common/GeneralModal';
import { getErrorMessage } from '../../helpers/error.helper';
import { MAX_THUMBNAIL_SIZE } from '../../common/constants';
import {Box, Button, Card, CardContent, TextareaAutosize, TextField, Typography} from '@mui/material';

interface AccountSettingsCardProps {
  setAlert: (message: string, type: string) => void;
}

interface CurrentData {
  title: string;
  description: string;
  preview_url: string;
}

const AccountSettingsCard = ({ setAlert } : AccountSettingsCardProps) => {
  const [editing, setEditing] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [currentData, setCurrentData] = useState<CurrentData | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const fileInput = useRef<any>(null);

  const fetchUserSettings = async () => {
    const userSettingsRes = await getUserPreview();
    setCurrentData(userSettingsRes.data);
  };

  const handleSave = () => {
    setSaving(true);
    if (!thumbnail || !newTitle || !newDescription) {
      setAlert('All fields are required', 'error');
      setSaving(false);
      return;
    }
    setUserPreview({ file: thumbnail[0], title: newTitle, description: newDescription })
      .then((res) => {
        setAlert('Success!', 'success');
        fetchUserSettings();
      })
      .catch((e) => {
        setAlert(getErrorMessage(e), 'error');
        console.error(e);
      })
      .finally(() => {
        setSaving(false);
        setEditing(false);
      });
  };

  const deleteSettings = () => {
    setSaving(true);
    deleteUserPreview()
      .then((res) => {
        setCurrentData(null);
      })
      .catch((e) => {
        setAlert(getErrorMessage(e), 'error');
        console.error(e);
      })
      .finally(() => {
        setSaving(false);
        setEditing(false);
        setModalOpen(false);
      });
  };

  const handleFileInput = async (e: any) => {
    setUploading(true);
    const files = e.target.files;
    if (files && files[0].size < MAX_THUMBNAIL_SIZE) {
      setThumbnail(files);
      setUploading(false);
    } else {
      setAlert('File too big', 'error');
      setUploading(false);
    }
  };

  useEffect(() => {
    fetchUserSettings().catch((e) => console.error(e));
  }, []);

  return (
    <Box>
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardContent>
                <div>
                  <Typography variant="h5">Account Preview Settings</Typography>
                  {currentData && <Typography variant="h6">Title</Typography>}
                  <p>
                    {editing ? (
                      <TextField
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        type="text"
                        label="Title"
                        id="formrow-firstname-Input"
                      />
                    ) : (
                      <Typography variant="body2">{currentData?.title}</Typography>
                    )}
                  </p>
                  {currentData && <Typography variant="h6">Description</Typography>}
                  <p>
                    {editing ? (
                      <TextareaAutosize
                        id="textarea"
                        minRows={3}
                        value={newDescription}
                        placeholder="This textarea has a limit of 225 chars."
                        className="position-relative"
                        onChange={(e) => setNewDescription(e.target.value)}
                        style={{width: '100%'}}
                      />
                    ) : (
                      <Typography variant="body2">{currentData?.description}</Typography>
                    )}
                  </p>
                  {currentData && <Typography variant="h6">Thumbnail</Typography>}
                  {editing ? (
                    <div className="pinata-file-uploader settings">
                      <input
                        accept="image/*"
                        id="thumbnail"
                        ref={fileInput}
                        className="d-none"
                        type="file"
                        onChange={handleFileInput}
                      />
                      <div className="d-flex align-items-center">
                        <Button onClick={(e) => fileInput.current && fileInput.current?.click()} color={'info'}>
                          {uploading ? 'Uploading...' : 'Upload a file'}
                        </Button>

                        <p className="text-decoration-underline m-0 ml-3">
                          {thumbnail?.length > 0 && thumbnail[0].name}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img src={(S3PreviewBucketURL || '') + currentData?.preview_url} alt="" height={80} />
                    </div>
                  )}
                  {editing ? (
                    <Button onClick={() => handleSave()}>{saving ? 'Saving...' : 'Save'}</Button>
                  ) : currentData ? (
                    <Button onClick={() => setModalOpen(true)} color={'error'}>
                      Delete Settings
                    </Button>
                  ) : (
                    <Button onClick={() => setEditing(true)}>Set Default Settings</Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
      <GeneralModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleAction={deleteSettings}
        title={'Confirm'}
        content={'Delete Preview Settings?'}
        actionName={saving ? 'Deleting...' : 'Delete'}
        actionBtn={'danger'}
      />
    </Box>
  );
};

export default AccountSettingsCard;
