import { metricLimitInfo } from 'helpers/enums';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Label, Input, Spinner, Alert } from 'reactstrap';
import { api, managedApi } from 'services/api';

function getTimeInMiliseconds(tokenDate) {
  const { hours, days, months } = tokenDate;
  const hourSeconds = hours * 60 * 60;
  const daysSeconds = days * 86_400;
  const monthSeconds = months * 2_628_288; //This is the average seconds in a month
  const tokenTimeInSeconds = monthSeconds + hourSeconds + daysSeconds;
  return tokenTimeInSeconds;
}

const GenerateAccessTokenModal = ({
  getPath,
  setAlert,
  gatewayToUse,
  setPin,
  pinInfo,
  cidAccessModalOpen,
  setCIDAccessModalOpen,
  userMetricsLimit
}) => {
  const [tokenDate, setTokenDate] = useState({
    hours: 1,
    days: 0,
    months: 0
  });

  const [linkToShare, setlinkToShare] = useState('');
  const [accessTime, setAccessTime] = useState(0);
  const [generating, setGenerating] = useState(false);

  const handleDateChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;

    setTokenDate((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!pinInfo.v2) {
      getShareLink(getTimeInMiliseconds(tokenDate));
    }
  }, []);

  const closeModal = () => {
    setPin(null);
    setCIDAccessModalOpen(false);
  };

  const calculateAccessTimeInSeconds = () => {
    const tokenTimeInSeconds = getTimeInMiliseconds(tokenDate);
    setAccessTime(tokenTimeInSeconds);
    getShareLink(tokenTimeInSeconds);
  };

  const copyShareLink = async () => {
    navigator.clipboard.writeText(linkToShare);
    linkToShare && setAlert('Copied!');
    closeModal();
  };

  const getShareLink = async (tokenTimeInSeconds) => {
    let data = null;

    try {
      setGenerating(true);
      if (pinInfo.v2 && pinInfo.submarined) {
        data = await managedApi.post(`content/${pinInfo.id}/shorten`, {
          domain: gatewayToUse,
          jwtExpireSeconds: tokenTimeInSeconds
        });
      } else {
        data = await api.post(`v2/pins/${pinInfo.id}/shorten`, {
          domain: gatewayToUse
        });
      }
    } catch (error) {
      console.log(error);
      setAlert("Can't generate share link. Try again later", 'error');
      closeModal();
    } finally {
      setGenerating(false);
      setlinkToShare(data?.data?.short_url ?? '');
    }
  };

  const renderShareLink = () => (
    <div style={{ marginTop: 20, cursor: 'pointer' }}>
      <div onClick={() => copyShareLink()} className="my-3 align-items-center d-flex justify-content-center w-100">
        {generating && <Spinner />}
        <pre className="text-center">
          <code>{linkToShare}</code>
        </pre>
      </div>

      <button onClick={closeModal} className="btn btn-primary w-100">
        Close
      </button>
    </div>
  );

  return (
    <Modal isOpen={cidAccessModalOpen} toggle={closeModal} centered={true}>
      {userMetricsLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricsLimit.type}>
          {userMetricsLimit.text} <Link to="/billing">Upgrade</Link>
        </Alert>
      )}
      <div className="modal-header">
        <div className="d-flex align-items-center">
          <i className="fa fa-paper-plane mr-2" aria-hidden="true" />
          <h5 className="modal-title mt-0">Share File</h5>
        </div>

        <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {pinInfo && pinInfo.submarined ? (
          <>
            <h3 className="text-center">You're about to share a submarined file</h3>
            {accessTime ? (
              renderShareLink()
            ) : (
              <div>
                <p className="mt-3 mb-3">{getPath(pinInfo)}</p>
                <p className="font-size-16">
                  Select how long you'd like the share link to be valid for (default: 1 hour).
                </p>
                <div className="mt-3 mb-3">
                  <Label for="months">Months</Label>
                  <Input
                    type="number"
                    min="1"
                    onChange={handleDateChange}
                    name="months"
                    max="36"
                    value={tokenDate.months}
                  />
                </div>
                <div className="mt-3 mb-3">
                  <Label for="days">Days</Label>
                  <Input
                    type="number"
                    min="1"
                    onChange={handleDateChange}
                    name="days"
                    max="365"
                    value={tokenDate.days}
                  />
                </div>
                <div className="mt-3 mb-3">
                  <Label for="hours">Hours</Label>
                  <Input
                    type="number"
                    min="1"
                    onChange={handleDateChange}
                    name="hours"
                    max="24"
                    value={tokenDate.hours}
                  />
                </div>
                <button onClick={calculateAccessTimeInSeconds} className="btn btn-primary">
                  {generating ? 'Generating share link...' : 'Share'}
                </button>
                <button onClick={closeModal} className="btn">
                  Cancel
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            <h3 className="text-center">You're about to share a public file</h3>
            {renderShareLink()}
          </>
        )}
      </div>
    </Modal>
  );
};

export default GenerateAccessTokenModal;
