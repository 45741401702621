import React from 'react';
import { metricLimitInfo } from 'helpers/enums';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from '@mui/material';

type PossibleButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

interface ConfirmationModalProps {
  title: string;
  text?: string | React.ReactElement;
  modalOpen: boolean;
  toggleModal: (defaultStatus: boolean) => void;
  loading: boolean;
  action: () => void;
  modalContent?: any;
  confirmButtonText?: string;
  confirmButtonColor?: PossibleButtonColor | any;
  cancelButtonText?: string;
  cancelButtonColor?: PossibleButtonColor | any;
  userMetricLimit?: { title: string; text: string; type: string };
}

const ConfirmationModal = ({
  title,
  text,
  modalOpen,
  toggleModal,
  loading,
  action,
  modalContent,
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'primary',
  cancelButtonText = 'Cancel',
  cancelButtonColor = 'inherit',
  userMetricLimit
}: ConfirmationModalProps) => {
  return (
    <Dialog fullWidth open={modalOpen} onClose={() => toggleModal(false)}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit?.type}>
          {userMetricLimit?.text}
        </Alert>
      )}

      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {title}
        <IconButton className="p-0" aria-label="close" onClick={() => !loading && toggleModal(false)}>
          <span aria-hidden="true">&times;</span>
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            {text && (
              <Grid item xs={12}>
                {text}
              </Grid>
            )}
            {modalContent && (
              <Grid item xs={12}>
                {modalContent}
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={action} color={confirmButtonColor} disabled={loading}>
          {confirmButtonText}
        </Button>
        <Button fullWidth onClick={() => toggleModal(false)} color={cancelButtonColor} disabled={loading}>
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  userMetricLimit: state?.metrics?.metricsLimitData
});

export default connect(mapStateToProps, null)(ConfirmationModal);
