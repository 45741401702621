import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { makeDatePretty } from 'helpers/pretty';
import { getBillingHistory } from '../../store/billing/billing.actions';
import {BillingHistory as BillingHistoryInterface} from '../../store/billing/types';
import {CardContent, Typography, Card, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

interface BillingHistoryProps {
  billingHistory: BillingHistoryInterface [];
  getBillingHistory: any;
  hasMore: boolean;
}

const BillingHistory = ({ billingHistory, getBillingHistory, hasMore }: BillingHistoryProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startingBefore, setStartingBefore] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    getBillingHistory(LIMIT, 0, 0);
  }, []);

  const handlePageChange = (newPage: number) => {
    if (newPage < currentPage) {
      const firstRecord = billingHistory[0];
      const record = firstRecord && firstRecord.id ? firstRecord.id : startingBefore;
      getBillingHistory(LIMIT, 0, record);
    } else {
      const lastRecord = billingHistory[billingHistory.length - 1];
      if (lastRecord && lastRecord.id) {
        setStartingBefore(lastRecord.id);
      }
      getBillingHistory(LIMIT, lastRecord.id, 0);
    }
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    if (currentPage > 1) {
      return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <PaginationItem active={true}>
            <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
              <i className="fas fa-chevron-left"></i>
            </PaginationLink>
          </PaginationItem>
          {hasMore && (
            <PaginationItem active={true}>
              <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                <i className="fas fa-chevron-right"></i>
              </PaginationLink>
            </PaginationItem>
          )}
        </div>
      );
    } else {
      return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: 20 }} />
          {hasMore && (
            <PaginationItem active={true}>
              <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                <i className="fas fa-chevron-right"></i>
              </PaginationLink>
            </PaginationItem>
          )}
        </div>
      );
    }
  };
  const formatTotal = (amount: number) => {
    return `$${(amount / 100).toFixed(2)}`;
  };
  const renderInvoice = (row: BillingHistoryInterface) => {
    return (
      <TableRow key={row.id}>
        <TableCell>{makeDatePretty(row.date * 1000)}</TableCell>
        <TableCell>{formatTotal(row.total)}</TableCell>
        <TableCell>
          <a style={{ marginRight: 10 }} href={row.invoiceUrl} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-eye"></i>
          </a>
          <a style={{ marginLeft: 10 }} href={row.invoicePdf} target="_blank" rel="noopener noreferrer">
            <i className="far fa-file-pdf"></i>
          </a>
        </TableCell>
      </TableRow>
    );
  };
  if (billingHistory?.length > 0) {
    return (
      <Card className="m-0">
        <CardContent>
          <Typography variant="h6">Billing History</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell scope="col">Date</TableCell>
                <TableCell scope="col">Amount</TableCell>
                <TableCell scope="col"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{billingHistory.filter((b) => b.total > 0).map((h) => renderInvoice(h))}</TableBody>
          </Table>
          <Pagination className="my-4">{renderPagination()}</Pagination>
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: any) => ({
  billingHistory: state?.billing?.billing_history,
  hasMore: state?.biling?.hasMore
});

const mapDispatchToProps = {
  getBillingHistory: getBillingHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
