import { LOAD_GATEWAYS, GATEWAY_REGIONS, GATEWAY_USAGE, TOTAL_BANDWIDTH_MONTH } from 'actions/types';
import {GatewaysState} from "./types";

const initialState: GatewaysState = {
    gateways: {count: 0, rows: []},
    gatewayRegions: [],
    gatewayUsage: null,
    bandwidth: 0
};

export default function (state: GatewaysState = initialState, action: any) {
    const { type, payload } = action;
    switch (type) {
        case LOAD_GATEWAYS:
            return {
                ...state,
                gateways: payload
            };
        case GATEWAY_REGIONS:
            return {
                ...state,
                gatewayRegions: payload
            };
        case GATEWAY_USAGE:
            return {
                ...state,
                gatewayUsage: payload
            };
        case TOTAL_BANDWIDTH_MONTH:
            return {
                ...state,
                bandwidth: payload
            };
        default:
            return state;
    }
}
