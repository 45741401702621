import React, { useMemo, useState, useEffect } from 'react';
import FilterModal from './FilterModal';
import PendingPinsModal from './PendingPinsModal';

import { isMobileDevice } from '../../helpers/mobileDetection';
import { Grid, Select, InputLabel, FormControl, MenuItem, Button, Menu, Fade, Badge, Typography } from '@mui/material';
import { BillingState } from 'store/billing/types';
import { MetricsState } from 'store/metrics/types';
import { validateMetricsLimits } from '../../helpers/validations';

const isSupportsWebKitDirectory = (): boolean => {
  const userAgentString = navigator.userAgent;
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;
  let IExplorerAgent = userAgentString.indexOf('MSIE') > -1;
  let safariAgent = userAgentString.indexOf('Safari') > -1;
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  if (IExplorerAgent || safariAgent) {
    return false;
  } else {
    return true;
  }
};

interface TableNavProps {
  selectedPinStatus: string;
  setSelectedPinStatus: (status: 'pinned' | 'unpinned' | 'all') => void;
  setFileUploadModalOpen: (open: boolean) => void;
  setFolderUploadModalOpen: (open: boolean) => void;
  submitTableFilters: () => void;
  setHashContains: (hashContains: string) => void;
  hashContains: string;
  nameContains: string;
  setNameContains: (nameContains: string) => void;
  pinStartDate: Date | undefined;
  pinEndDate: Date | undefined;
  unpinStartDate: Date | undefined;
  unpinEndDate: Date | undefined;
  setPinStartDate: (pinStartDate: Date) => void;
  setPinEndDate: (pinEndDate: Date) => void;
  setUnpinStartDate: (unpinStartDate: Date) => void;
  setUnpinEndDate: (unpinEndDate: Date) => void;
  clearFilters: () => void;
  setPinByHashOpen: (open: boolean) => void;
  pinJobs: any;
  setAlert: (message: string | React.ReactElement, type: string) => void;
  gateways: any;
  handleGatewayToUse: (event: any) => void;
  gatewayToUse: string;
  getGatewayValue: (gateway: string) => string;
  submarined: boolean;
  setSubmarined: (submarined: boolean) => void;
  folderDetails: any;
  metrics: MetricsState;
  billing: BillingState;
}

const TableNav = ({
  selectedPinStatus,
  setSelectedPinStatus,
  setFileUploadModalOpen,
  setFolderUploadModalOpen,
  submitTableFilters,
  setHashContains,
  hashContains,
  nameContains,
  setNameContains,
  pinStartDate,
  pinEndDate,
  unpinStartDate,
  unpinEndDate,
  setPinStartDate,
  setPinEndDate,
  setUnpinStartDate,
  setUnpinEndDate,
  clearFilters,
  setPinByHashOpen,
  pinJobs,
  setAlert,
  gateways,
  handleGatewayToUse,
  gatewayToUse,
  getGatewayValue,
  submarined,
  setSubmarined,
  folderDetails,
  metrics,
  billing
}: TableNavProps) => {
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [pinJobsModalOpen, setPinJobsModalOpen] = useState<boolean>(false);

  const [uploadModalAnchorEl, setUploadModalAnchorEl] = useState<any>(null);
  const isUploadModalOpen = Boolean(uploadModalAnchorEl);

  const supportsWebKitDirectory = useMemo(() => isSupportsWebKitDirectory(), []);
  const isMobile = useMemo(() => isMobileDevice(), []);

  const handlePinStatusChange = (e: any): void => {
    setSelectedPinStatus(e.target.value);
  };

  const updateFilter = (): void => {
    setFiltersApplied(true);
    setFilterModalOpen(false);
    submitTableFilters();
  };

  const clearAppliedFilters = (): void => {
    setFiltersApplied(false);
    clearFilters();
    setSelectedPinStatus('pinned');
  };

  const isAnyMetricIsInvalid = useMemo(() => validateMetricsLimits(metrics?.metrics, billing), [metrics?.metrics]);

  const showInvalidMetricAlert = () => {
    setAlert(
      <span>
        This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
      </span>,
      'error'
    );
  };

  const handleFolderUploadModal = () => {
    setUploadModalAnchorEl(null);
    if (isAnyMetricIsInvalid) {
      showInvalidMetricAlert();
      return;
    }
    if (supportsWebKitDirectory) {
      setFolderUploadModalOpen(true);
    } else {
      setAlert("Your browser doesn't support directory uploads", 'error');
    }
  };

  const handleFileUploadModal = () => {
    setUploadModalAnchorEl(null);
    if (isAnyMetricIsInvalid) {
      showInvalidMetricAlert();
      return;
    }
    setFileUploadModalOpen(true);
  };

  const handlePinModal = () => {
    setUploadModalAnchorEl(null);
    if (isAnyMetricIsInvalid) {
      showInvalidMetricAlert();
      return;
    }
    setPinByHashOpen(true);
  };

  const backOutOfFolder = () => {
    clearFilters();
    setSelectedPinStatus('pinned');
  };

  const renderGatewayOptions = useMemo(() => {
    if (gateways?.gateways?.rows?.length) {
      return gateways.gateways.rows.map((g: any, index: number) => {
        return (
          <MenuItem key={`g-${index}`} value={getGatewayValue(g)}>
            {getGatewayValue(g)}
          </MenuItem>
        );
      });
    }
  }, [gateways?.gateways?.rows?.length]);

  return (
    <>
      <Typography variant="h6">My Files</Typography>
      <Grid container spacing={2} justifyContent="space-between" className="my-2">
        <Grid item container xs={12} sm={4} md={3} spacing={2}>
          {folderDetails ? (
            <Grid item xs={12}>
              <a className="mr-2" onClick={backOutOfFolder} href="#">
                <i className="fas fa-arrow-left" />
              </a>
              {`Files/${folderDetails.name}`}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Button
                fullWidth
                color="info"
                onClick={(event) => setUploadModalAnchorEl(event.currentTarget)}
                id="fade-button"
                aria-controls={isUploadModalOpen ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isUploadModalOpen ? 'true' : undefined}
              >
                Upload
                <i className="mdi mdi-plus mr-1" />
              </Button>
              <Menu
                id="fade-menu"
                anchorEl={uploadModalAnchorEl}
                open={isUploadModalOpen}
                onClose={() => setUploadModalAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                TransitionComponent={Fade}
              >
                {!isMobile && (
                  <MenuItem onClick={handleFolderUploadModal}>
                    <i className="bx bx-folder mr-1" /> Folder
                  </MenuItem>
                )}
                <MenuItem onClick={handleFileUploadModal}>
                  <i className="bx bx-file mr-1" /> File
                </MenuItem>
                <MenuItem onClick={handlePinModal}>
                  <i className="fas fa-asterisk mr-1" /> CID
                </MenuItem>
              </Menu>
            </Grid>
          )}
          {!folderDetails && pinJobs?.length > 0 && (
            <Grid item xs={12}>
              <Badge
                className="d-block"
                badgeContent={pinJobs.length}
                color="primary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <Button fullWidth variant="contained" onClick={() => setPinJobsModalOpen(true)} color="info">
                  Pin By CID Queue
                </Button>
              </Badge>
            </Grid>
          )}
        </Grid>
        {!folderDetails && (
          <Grid container item spacing={2} alignItems="center" xs={12} sm={6} md={6}>
            <Grid item md={6} lg={4} flexGrow={1}>
              <FormControl fullWidth>
                <InputLabel id="pin-filter">Pin Status</InputLabel>
                <Select
                  size="small"
                  labelId="pin-filter"
                  onChange={handlePinStatusChange}
                  value={selectedPinStatus}
                  label="Pin Status"
                >
                  <MenuItem value={'pinned'}>Pinned</MenuItem>
                  <MenuItem value={'unpinned'}>Unpinned</MenuItem>
                  <MenuItem value={'all'}>All</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {gateways?.gateways?.rows?.length > 0 && (
              <Grid item md={6} lg={4} flexGrow={1}>
                <FormControl fullWidth>
                  <InputLabel id="gateway-filter">Gateway to Use</InputLabel>
                  <Select
                    size="small"
                    labelId="gateway-filter"
                    onChange={handleGatewayToUse}
                    value={gatewayToUse}
                    label="Gateway to use"
                  >
                    {renderGatewayOptions}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} lg={4}>
              {filtersApplied ? (
                <Button fullWidth onClick={clearAppliedFilters}>
                  Clear Filters
                </Button>
              ) : (
                <Button fullWidth onClick={() => setFilterModalOpen(true)}>
                  <i className="fas fa-search font-size-18 mx-2" />
                  Search Files
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {filterModalOpen && (
        <FilterModal
          pinStatus={selectedPinStatus}
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
          hashContains={hashContains}
          setHashContains={setHashContains}
          nameContains={nameContains}
          setNameContains={setNameContains}
          pinStartDate={pinStartDate}
          pinEndDate={pinEndDate}
          unpinStartDate={unpinStartDate}
          unpinEndDate={unpinEndDate}
          setUnpinStartDate={setUnpinStartDate}
          setUnpinEndDate={setUnpinEndDate}
          setPinStartDate={setPinStartDate}
          setPinEndDate={setPinEndDate}
          updateFilter={updateFilter}
          submarined={submarined}
          setSubmarined={setSubmarined}
          setAlert={setAlert}
        />
      )}
      {pinJobsModalOpen && (
        <PendingPinsModal
          userMetricLimit={metrics?.metricsLimitData}
          pinJobs={pinJobs}
          pinJobsModalOpen={pinJobsModalOpen}
          setPinJobsModalOpen={setPinJobsModalOpen}
          setAlert={setAlert}
        />
      )}
    </>
  );
};

export default TableNav;
