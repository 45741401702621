import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { codeFieldRules } from 'helpers/formFieldValidations';

const ConfirmCode = ({ mfa, children }) => {
  const { control, getFieldState } = useFormContext();

  const codeState = getFieldState('code');

  return (
    <>
      {children}
      <Controller
        name="code"
        control={control}
        rules={codeFieldRules}
        render={({ field }) => (
          <FormGroup>
            <Input
              {...field}
              label={mfa ? 'Enter multi-factor auth code from your device' : 'Confirmation Code'}
              placeholder="Enter code"
              invalid={codeState.invalid}
              autoComplete="one-time-code"
            />
            {codeState.invalid && <FormFeedback>{codeState?.error?.message}</FormFeedback>}
          </FormGroup>
        )}
      />
    </>
  );
};

export default ConfirmCode;
