import {
  Box,
  Button,
  Container, MenuItem,
  OutlinedInput, Select,
  Switch, TextField,
  Typography
} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {ThemeContext} from "../../services/globalContext";
import {PinataAlert} from "../../components/Common/VerticalAlert";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useTheme } from '@mui/material/styles';

function Components () {
  const themeContext = useContext(ThemeContext);
  const theme = useTheme();

  const Divider = ({title}: {title: string}) => {
    return <Box sx={{marginBottom: 5, marginTop: 5}}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{borderBottom: `1px solid ${theme.palette.divider}`}}/>
    </Box>
  }

  useEffect(() => {
    themeContext.setLayout("vertical");
  }, []);

  const handleToggleTheme = () => {
    themeContext.setThemeName(themeContext.themeName === 'dark' ? 'light' : 'dark');
  }

  return  <Container sx={{'& > *': {marginBottom: 1}}}>
    <Box>
      <Typography variant="body1">Toggle Theme</Typography>
      <Brightness4Icon sx={{color: theme.palette.text.primary}}/>
      <Switch onChange={handleToggleTheme} />
    </Box>
    <Box sx={{'& > *': {marginBottom: 1}}}>
      <Divider title={"Typography"}/>
      <Typography variant="h1">Heading 1</Typography>
      <Typography variant="h2">Heading 2</Typography>
      <Typography variant="h3">Heading 3</Typography>
      <Typography variant="h4">Heading 4</Typography>
      <Typography variant="h5">Heading 5</Typography>
      <Typography variant="h6">Heading 6</Typography>
      <Typography variant="body1">Body 1</Typography>
    </Box>
    <Box sx={{'& > *': {marginBottom: 1}, display: 'flex', flexDirection: 'column'}}>
      <Divider title={"Buttons"}/>
      <Button>Pinata Button</Button>
      <Button  disabled={true}>Disabled Button</Button>
    </Box>
    <Box sx={{'& > *': {marginBottom: 1}}}>
      <Divider title={"Alerts"}/>
      <PinataAlert alert={{alert: {type: "info", timeOut: 0, message: 'Here is something you might like to know.'}}} />
      <PinataAlert alert={{alert: {type: "success", timeOut: 0, message: 'Here is something that went well.'}}} />
      <PinataAlert alert={{alert: {type: "warning", timeOut: 0, message: 'Here is something that went wrong.'}}} />
      <PinataAlert alert={{alert: {type: "error", timeOut: 0, message: 'Here is something that failed.'}}} />
    </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}} component={"form"}>
        <Divider title={"Form Elements"}/>
        <TextField sx={{marginBottom: 2}} placeholder="Input"/>
        <TextField sx={{marginBottom: 2}} placeholder="Disabled Input" disabled/>
        <TextField sx={{marginBottom: 2}} placeholder="Error" error/>
        <TextField sx={{marginBottom: 2}} placeholder="Error with helper text" error helperText={"Something went wrong"}/>
        <Select
        labelId="usage-range-select"
        id="usage-range"
        value={"1"}
        onChange={() => {}}
        >
          <MenuItem value="1">Last 24 Hours</MenuItem>
          <MenuItem value="7">Last 7 Days</MenuItem>
          <MenuItem value="14">Last 14 Days</MenuItem>
          <MenuItem value="30">Last 30 Days</MenuItem>
        </Select>
      </Box>
  </Container>
}

export default Components;