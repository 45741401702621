import { FC } from 'react';
import { Spinner } from 'reactstrap';

interface ButtonWithStateprops {
  submitting: boolean;
  type: 'submit' | 'button' | 'reset';
  children: React.ReactChild;
}

const ButtonWithState: FC<ButtonWithStateprops> = ({ submitting, type = 'submit', children, ...props }) => (
  <button {...props} className="btn btn-primary btn-block waves-effect waves-light mt-4" type={type}>
    {submitting ? <Spinner color="light" size="sm" /> : children ? children : 'Submit'}
  </button>
);

export default ButtonWithState;
