import React from 'react';
import { Modal, Col, Row, Input, Alert } from 'reactstrap';
import { metricLimitInfo } from 'helpers/enums';
import { Link } from 'react-router-dom';

// Metadata Limits
// Max 100 key/value pairs
//  Key length max 255
// Value max length 1000

const MetadataModal = ({
  setShowEditMetaDataModal,
  showEditMetaDataModal,
  keyValues,
  fileName,
  handleAddKeyValue,
  setFileName,
  handleSaveMetaData,
  handleDeleteKeyValue,
  newKey,
  newValue,
  setNewKey,
  setNewValue,
  userMetricsLimit
}) => {
  return (
    <Modal isOpen={showEditMetaDataModal} toggle={() => setShowEditMetaDataModal(false)} centered={true}>
      {userMetricsLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricsLimit.type}>
          {userMetricsLimit.text} <Link to="/billing">Upgrade</Link>
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Edit File Details</h5>
        <button
          type="button"
          onClick={() => setShowEditMetaDataModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <label>Name</label>
        <Input onChange={(e) => setFileName(e.target.value)} value={fileName} placeholder="name.png" />
        <div className="pinata-keyvalue-table">
          <div className="pinata-key-value-table-header">
            <Row>
              <Col>
                <label>Key</label>
              </Col>
              <Col>
                <label>Value</label>
              </Col>
              <Col></Col>
            </Row>
          </div>
          <div className="pinata-key-value-table-body">
            {keyValues &&
              keyValues.length > 0 &&
              keyValues
                .filter((kv) => kv.value !== null)
                .map((kv) => {
                  return (
                    <Row key={kv.id}>
                      <Col>
                        <p>{kv.key}</p>
                      </Col>
                      <Col>
                        <p>{kv.value}</p>
                      </Col>
                      <Col>
                        <p style={{ cursor: 'pointer' }} onClick={() => handleDeleteKeyValue(kv.id)}>
                          Delete
                        </p>
                      </Col>
                    </Row>
                  );
                })}
          </div>
        </div>
        <label>New Key/Value</label>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <Input value={newKey} maxLength="250" onChange={(e) => setNewKey(e.target.value)} placeholder="key" />
          </Col>
          <Col>
            <Input value={newValue} maxLength="250" onChange={(e) => setNewValue(e.target.value)} placeholder="value" />
          </Col>
          <Col>
            <button onClick={handleAddKeyValue} className="btn btn-pinataSecondary">
              Add
            </button>
          </Col>
        </Row>
        <button onClick={handleSaveMetaData} style={{ marginTop: 20 }} className="btn btn-primary">
          Save
        </button>
      </div>
    </Modal>
  );
};

export default MetadataModal;
