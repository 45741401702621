import { debounce } from 'common/data/debounce';
import React, { useState, useCallback, useEffect } from 'react';
import { DETAILS_PAGE, GATEWAY_REGIONS, INTRO_PAGE, ROOT_DOMAIN } from './gatewayConstants';

const SelectDomain = ({ setPage, subdomain, setSubdomain, checkSubdomain, setAlert, gateways }) => {
  const [checking, setChecking] = useState(false);
  const [available, setAvailable] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [domainError, setDomainError] = useState(false);

  const checkDomain = async () => {
    if (subdomain.length > 0) {
      const isTaken = await checkSubdomain(subdomain);
      if (isTaken) {
        setAvailable(false);
      } else {
        setAvailable(true);
      }
      setDisplayResults(true);
      setChecking(false);
    }
  };

  const handleChange = (value) => {
    setDomainError(false);
    setSubdomain(value);
  };

  const onKeyUp = ({ key }) => {
    if (key === 'Enter') {
      search();
    }
  };

  const search = () => {
    if (subdomain.length < 1) {
      return setAlert('Subdomains must be at least 1 character', 'error');
    }

    if (subdomain.length > 0) {
      if (validDomain(subdomain)) {
        setDisplayResults(false);
        setChecking(true);
        checkDomain();
      } else {
        setAvailable(false);
        setDisplayResults(false);
        setDomainError(true);
      }
    } else {
      setDomainError(true);
    }
  };

  const validDomain = (value) => {
    const tooLong = value.length > 63;
    const slashMatch = value.indexOf('/');
    const colonMatch = value.indexOf(':');
    const periodMatch = value.indexOf('.');
    const spaceMatch = value.indexOf(' ');
    const match = slashMatch > -1 || colonMatch > -1 || periodMatch > -1 || spaceMatch > -1 || tooLong;
    return !match;
  };

  return (
    <div>
      <h3>Give your gateway a name</h3>
      <div style={{ marginTop: 20 }} className="form-group row">
        <label htmlFor="example-text-input" className="col-md-3 col-form-label">
          Name
        </label>
        <div className="col-md-6 pinata-domain-input" data-placeholder={ROOT_DOMAIN}>
          <input
            style={domainError ? { border: 'red 1px solid' } : {}}
            className="form-control"
            placeholder="mycompanyname"
            type="text"
            id="domain-search"
            onChange={(e) => handleChange(e.target.value)}
            onKeyUp={onKeyUp}
            value={subdomain}
            autoComplete="off"
          />
          <p className="text-danger" style={{ marginTop: 25 }}>
            {domainError && 'Invalid subdomain'}
          </p>
        </div>
        <div className="col-md-3">
          <i className="fas fa-search"></i>
          <button onClick={search} className="btn">
            Search
          </button>
        </div>
        <div className="pinata-domain-availability">
          {checking && <p>Checking availability...</p>}
          {displayResults && available && (
            <p>
              <i className="fas fa-check text-success"></i> Available!
            </p>
          )}
          {displayResults && !available && (
            <p>
              <i className="fas fa-times text-danger"></i> Domain unavailable 😔, try again
            </p>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <button
          disabled={!available}
          onClick={() => setPage(DETAILS_PAGE)}
          className={available ? 'btn btn-pinataSecondary' : 'btn'}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectDomain;
