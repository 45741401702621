import React, { useEffect, useState } from 'react';
import { Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import GatewayVirtualList from './GatewayVirtualList';
import { getErrorMessage } from '../../helpers/error.helper';
import {GatewayRow, GatewaysState} from "../../store/gateways/types";

interface SelectGatewayModalProps {
  modalOpen: any;
  toggleModal: any;
  pinInfo: any;
  gateways: GatewaysState;
  getAllGateways: any;
  setRootContent: any;
}

const SelectGatewayModal = (props: SelectGatewayModalProps) => {
  const { modalOpen, toggleModal, pinInfo, gateways, getAllGateways, setRootContent } = props;
  const [loading, setLoading] = useState(false);
  const [gatewaysLoading, setGatewaysLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [gatewayUpdateStatus, setGatewayUpdateStatus] = useState<string | null>(null);

  const loadGateways = async (itemNum: number) => {
    if (itemNum > 0) {
      setGatewaysLoading(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      await getAllGateways(newPage);
      setGatewaysLoading(false);
    }
  };

  const handleModalClose = async () => {
    if (loading) {
      return;
    }
    getAllGateways(1);
    setCurrentPage(1);
    setGatewayUpdateStatus(null);
    toggleModal(false);
  };

  const handleGatewaySelect = async (gateway: GatewayRow) => {
    setGatewayUpdateStatus('UPDATING');
    try {
      await setRootContent(gateway.id, pinInfo.id);
      setGatewayUpdateStatus('SUCCESS');
    } catch (e) {
      setGatewayUpdateStatus(getErrorMessage(e));
    } finally {
      setGatewaysLoading(false);
    }
  };

  return (
    <Modal isOpen={modalOpen} toggle={handleModalClose} centered={true}>
      <ModalHeader toggle={handleModalClose}>Select Gateway</ModalHeader>
      <ModalBody>
        {gatewayUpdateStatus ? (
          <div>
            <h3>{gatewayUpdateStatus}</h3>
            {gatewayUpdateStatus === 'SUCCESS' && (
              <button onClick={handleModalClose} className={'btn btn-primary'} disabled={loading}>
                Close
              </button>
            )}
          </div>
        ) : (
          <>
            <div>
              <GatewayVirtualList
                gateways={gateways?.gateways?.rows || []}
                loadGateways={loadGateways}
                totalGateways={gateways?.gateways?.count}
                gatewaysLoading={gatewaysLoading}
                handleGatewaySelect={handleGatewaySelect}
              />
            </div>
            <div>
              <button onClick={handleModalClose} className="btn btn-danger" disabled={loading}>
                Cancel
              </button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SelectGatewayModal;
