import React, { useState, useEffect } from 'react';
import { metricLimitInfo } from 'helpers/enums';
import { Modal, Spinner, Alert } from 'reactstrap';
import { DETAILS_PAGE, DOMAIN_PAGE, GATEWAY_REGIONS } from './gatewayConstants';
import IntroPage from './IntroPage';
import PrivacyPage from './PrivacyPage';
import SelectDomain from './SelectDomain';

const index = ({
  setWizardModalOpen,
  wizardModalOpen,
  setAlert,
  checkSubdomain,
  createGateway,
  gateways,
  userMetricLimit,
  billing
}) => {
  const [page, setPage] = useState(DOMAIN_PAGE);
  const [subdomain, setSubdomain] = useState('');
  const [restricted, setRestricted] = useState(true);
  const [gatewayCreating, setGatewayCreating] = useState(false);
  useEffect(() => {
    if (!wizardModalOpen) {
      clearWizardFields();
    } else if (gateways && gateways.gateways && gateways.gateways.rows && gateways.gateways.rows.length === 0) {
      setPage(DOMAIN_PAGE);
    }
  }, [wizardModalOpen]);

  const clearWizardFields = () => {
    setSubdomain('');
    setRestricted(true);
    setPage(DOMAIN_PAGE);
  };

  const createNewGateway = async () => {
    setGatewayCreating(true);
    await createGateway({ subdomain, restricted });
    setGatewayCreating(false);
    setWizardModalOpen(false);
    setPage(DOMAIN_PAGE);
    setSubdomain('');
  };

  const renderWizardPage = () => {
    switch (page) {
      case DOMAIN_PAGE:
        return (
          <SelectDomain
            gateways={gateways}
            checkSubdomain={checkSubdomain}
            setAlert={setAlert}
            setPage={setPage}
            subdomain={subdomain}
            createNewGateway={createNewGateway}
            setSubdomain={setSubdomain}
          />
        );
      case DETAILS_PAGE:
        return (
          <PrivacyPage
            createNewGateway={createNewGateway}
            setPage={setPage}
            restricted={restricted}
            setRestricted={setRestricted}
          />
        );
      default:
        return <IntroPage setPage={setPage} />;
    }
  };
  return (
    <Modal isOpen={wizardModalOpen} toggle={() => setWizardModalOpen(false)} centered={true}>
      {!billing?.activePricingPlan?.isLegacy && userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit.type}>
          {userMetricLimit.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Create New Gateway</h5>
        <button
          type="button"
          onClick={() => setWizardModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{gatewayCreating ? <Spinner /> : <div>{renderWizardPage()}</div>}</div>
    </Modal>
  );
};

export default index;
