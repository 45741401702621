import React from 'react';

const ToggleSwitch = ({ checked, toggleChecked, label, disabled }) => {
  return (
    <div className="custom-control custom-switch mb-2" dir="ltr">
      <input
        type="checkbox"
        className="custom-control-input"
        // id="customSwitch1"
        disabled={disabled}
        checked={checked}
        onChange={() => {}}
      />
      <label
        className="custom-control-label"
        htmlFor="customSwitch1"
        onClick={disabled ? null : () => toggleChecked(!checked)}
      >
        {label}
      </label>
    </div>
  );
};

export default ToggleSwitch;
