import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { changeLayout, changeTopbarTheme, changeLayoutWidth } from '../../store/actions';

import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { Container } from 'reactstrap';
import Alert from "../Common/Alert";

interface LayoutProps {
    changeLayout: any;
    Layout: any;
    changeTopbarTheme: any;
    changeLayoutWidth: any;
    children: any;
}

function Layout(props: LayoutProps) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let currentage = location.pathname.replace('/', '');
    currentage = currentage.charAt(0).toUpperCase() + currentage.slice(1);

    document.title = `${currentage} | Pinata`;

    props.changeLayout('horizontal');
    if (props?.Layout?.topbarTheme) {
      props.changeTopbarTheme(props?.Layout?.topbarTheme);
    }
    if (props?.Layout?.layoutWidth) {
      props.changeLayoutWidth(props?.Layout?.layoutWidth);
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div id="layout-wrapper">
        <div className="alert-container">
            <Alert />
        </div>
      <Header toggleMenu={() => setIsMenuOpened(!isMenuOpened)} />
      <div className="topnav">
        <Navbar menuOpen={isMenuOpened} />
      </div>
      <Container fluid className="mt-4">
        <div className="p-0">{props.children}</div>
      </Container>
      <Container fluid>
        <Footer />
      </Container>
    </div>
  );
}

const mapStatetoProps = ({ Layout }: any) => ({ Layout });

const mapDispatchToProps = {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth
};
export default connect(mapStatetoProps, mapDispatchToProps)(Layout);
