import React, { useEffect, useState } from "react"

//Import Components
import Section from "./HeroSection/Section"
import CardsMini from "./HeroSection/cards-mini"
import Features from "./Features/features"

const CryptoIcoLanding = () => {
  const [imglight, setimglight] = useState(true)
  const [navClass, setnavClass] = useState("")

  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  })

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }

  return (
    <React.Fragment>
      <div className="pinata-extra-contain">
      <Section />
      {/* <CardsMini />
      <Features /> */}
      </div>      
    </React.Fragment>
  )
}

export default CryptoIcoLanding
