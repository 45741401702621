import { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface ICOUNTRY_REGEX_OVERWRITE {
  [key: string]: string;
}

const COUNTRY_REGEX_OVERWRITE: ICOUNTRY_REGEX_OVERWRITE = {
  ca: '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d$'
};

type SelectedCountryType =
  | {
      name: {
        common: string;
        official: string;
        nativeName: {
          spa: {
            official: string;
          };
        };
      };
      flag: string;
      fifa: string;
      postalCode: {
        format: string;
        regex: string;
      };
      cca2: string;
    }
  | undefined;

export default function SelectCountry(props: { billingAddress: { country: string; postalCode: string } }) {
  const methods = useFormContext();
  const [countriesList, setCountriesList] = useState<SelectedCountryType[]>([]);
  const [country, setCountry] = useState<SelectedCountryType | null>(null);

  const countryState = methods.getFieldState('countryName', methods.formState);
  const zipState = methods.getFieldState('zipCode', methods.formState);

  function selectCorrectCountry(countryValue: SelectedCountryType | string) {
    // check if input is acceptable for country list
    if (countryValue && countriesList.length) {
      const selectedCountry: SelectedCountryType = countriesList?.find(
        (el) => el === countryValue || el?.name?.common === countryValue
      );
      if (selectedCountry) {
        // for some countries, the postal code is not required and postalCode object is empty
        selectedCountry.postalCode.regex = selectedCountry?.postalCode?.regex?.trim() || '';

        // We should use a different Regex for Canada
        const countryCode = selectedCountry.cca2.toLowerCase();
        const countryRegex = COUNTRY_REGEX_OVERWRITE[countryCode];
        selectedCountry.postalCode.regex = countryRegex ? countryRegex : selectedCountry?.postalCode?.regex;
        setCountry(selectedCountry);
      }
      return !!selectedCountry?.name?.common || 'Wrong Country Name';
    }
  }

  function checkIsValidZip(zipCode: string) {
    if (country?.postalCode?.regex) {
      const regex = new RegExp(country.postalCode.regex);
      return regex.test(zipCode) || 'Zip Code is not valid';
    }
  }

  async function fetchCountriesInfo(): Promise<void> {
    try {
      // fetch a list of countries names
      const countries = await (
        await fetch('https://restcountries.com/v3.1/all?fields=name,postalCode,fifa,flag,cca2')
      ).json();
      countries.length && setCountriesList(countries);
    } catch (error) {
      console.log("can't load countries list", error);
    }
  }

  // fetch a list of countriÎes
  useEffect(() => {
    fetchCountriesInfo();
    return () => {
      setCountriesList([]);
      setCountry(null);
    };
  }, []);

  useEffect(() => {
    if (countriesList.length && props.billingAddress.country) {
      const defaultCountry = countriesList.find((el) => el?.name?.common === props?.billingAddress?.country);
      setCountry(defaultCountry);
    }
  }, [props?.billingAddress, countriesList.length]);

  return (
    <div className="d-flex mt-4">
      <Controller
        name="countryName"
        control={methods.control}
        rules={{
          required: {
            value: true,
            message: 'Field is required'
          },
          validate: (val) => selectCorrectCountry(val)
        }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            value={country}
            onChange={(_, value) => field.onChange(value)}
            size="small"
            sx={{ width: 300 }}
            options={countriesList || ''}
            autoHighlight
            getOptionLabel={(option) => option?.name?.common ?? ''}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option?.name?.common}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                fullWidth
                label="Choose a country"
                autoComplete="new-password"
                error={countryState.invalid}
                helperText={countryState.invalid && countryState?.error?.message}
                inputProps={{
                  ...params.inputProps
                }}
              />
            )}
          />
        )}
      />
      <Controller
        name="zipCode"
        control={methods.control}
        rules={{
          required: {
            value: true,
            message: 'Field is required'
          },
          validate: (val) => checkIsValidZip(val)
        }}
        render={({ field }) => (
          <TextField
            {...field}
            margin="normal"
            label="Zip/Postal Code"
            placeholder={country?.postalCode?.format || 'Zip/Postal Code'}
            autoComplete="postal-code"
            size="small"
            error={zipState.invalid}
            helperText={zipState.invalid && zipState?.error?.message}
          />
        )}
      />
    </div>
  );
}
