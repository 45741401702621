import { API_KEYS_LOADED, NEW_API_KEY, V2_KEYS_LOADED } from 'actions/types';

const initialState = {
  keys: {
    keys: [],
    count: 0
  },
  newKey: null,
  v2Keys: []
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case API_KEYS_LOADED:
      return {
        ...state,
        keys: payload
      };
    case NEW_API_KEY:
      return {
        ...state,
        newKey: payload
      };
    case V2_KEYS_LOADED:
      return {
        ...state,
        v2Keys: payload.items
      };
    default:
      return state;
  }
}
