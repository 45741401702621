import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { checkUsage, getAllGateways } from '../../../store/gateways/gateway.actions';
import { ROOT_DOMAIN } from '../GatewayWizard/gatewayConstants';
import { Link } from 'react-router-dom';
import Bandwidth from './Bandwidth';
import UsageNav from './UsageNav';
import {GatewaysState} from "../../../store/gateways/types";
import {PinataState} from "../../../store/types";
import {CardContent, Card, Typography, CardHeader, Box, Container} from "@mui/material";

interface DateRange {
  today: Date, past: Date
}

interface UsageProps {
  gateways: GatewaysState;
  getAllGateways: (props: any) => void;
  checkUsage: (domain: string, dateRange: DateRange, gateways: any) => any;
}

const Usage = ({ gateways, getAllGateways, checkUsage }: UsageProps) => {
  const [range, setRange] = useState(1);
  const [defaultDates, setDefaultDates] = useState<DateRange | null>(null);
  const [alreadyCalled, setAlreadyCalled] = useState(false);
  const [domain, setDomain] = useState('');
  const [usageData, setUsageData] = useState([]);
  const [customDomain, setCustomDomain] = useState('');
  const location = useLocation();
  useEffect(() => {
    const page = localStorage.getItem('pinata-gateways-page');
    getAllGateways(parseInt(page || '', 10));
    getDateRange(range);
  }, []);
  useEffect(() => {
    const gatewayDomain = location.pathname.split('gateway/')[1];
    if (gatewayDomain && gateways && gateways.gateways && gateways.gateways.rows && gateways.gateways.rows.length > 0) {
      const thisGateway = gateways.gateways.rows.find((g) => g.domain === gatewayDomain);
      if (thisGateway && thisGateway.customDomains?.length > 0) {
        setCustomDomain(thisGateway?.customDomains[0].domain);
      }
      setDomain(gatewayDomain);
      const defaultDateRange = getDateRange(1);
      if (!alreadyCalled) {
        checkGatewayUsage(gatewayDomain, defaultDateRange, gateways);
      }
      setAlreadyCalled(true);
    }
  }, [gateways.gateways]);

  useEffect(() => {
    return () => {
      setAlreadyCalled(false);
      setUsageData([]);
    };
  }, []);

  const checkGatewayUsage = async (domain: string, dateRange: DateRange, gateways: any) => {
    const gatewayUsage = await checkUsage(domain, dateRange, gateways);
    setUsageData(gatewayUsage);
  };

  const getDateRange = (daysPast: number) => {
    const today = new Date();
    const past = new Date(Date.now() - daysPast * 24 * 60 * 60 * 1000);
    setDefaultDates({ today, past });
    setRange(daysPast);
    return { today, past };
  };

  const handleDateRangeChange = (e: any) => {
    const dateRange = getDateRange(parseInt(e.target.value, 10));
    checkGatewayUsage(domain, dateRange, gateways);
  };

  return (
        <Container>
          <Typography variant="h3">Pinata Gateway</Typography>
          <Card>
            <CardContent>
              <Typography variant="h5">
                {customDomain ? customDomain : `${location.pathname.split('gateway/')[1]}${ROOT_DOMAIN}`}
              </Typography>
              <Box>
                <UsageNav defaultDates={defaultDates} handleDateRangeChange={handleDateRangeChange} range={range} />
              </Box>
              {usageData && usageData.length > 0 && (
                <div>
                  <Bandwidth usageData={usageData} />
                  {/* <Requests
                                        usageData={usageData}                                        
                                        getTotalRequests={getTotalRequests}
                                    /> */}
                </div>
              )}
            </CardContent>
          </Card>
        </Container>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    gateways: state.gateways
  };
};

export default connect(mapStateToProps, { getAllGateways, checkUsage })(Usage);
