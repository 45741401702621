import {styled} from "@mui/material/styles";
import {Box, DialogTitle, IconButton} from "@mui/material";
import React from "react";

export const CardsBox = styled(Box)(({ theme }) => ({
  '& > *': {
    marginBottom: 20
  }
}));

interface PinataDialogTitleProps {
  onClose: () => void;
  children: React.ReactNode;
}

export const PinataDialogTitle = (props: PinataDialogTitleProps) => {
  return (
    <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 0}}>
      {props.children}
      <IconButton sx={{padding: 0}} aria-label="close" onClick={props.onClose}>
        <span aria-hidden="true">&times;</span>
      </IconButton>
    </DialogTitle>
  );
}