import React, { useState } from 'react';
import { Modal, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { pinHash } from '../../actions/pin.actions';
import { metricLimitInfo } from 'helpers/enums';

const PinByHashModal = ({ pinByHashOpen, setPinByHashOpen, pinHash, userMetricLimit }) => {
  const [customPinName, setCustomPinName] = useState('');
  const [cid, setCid] = useState('');
  const closeModal = () => {
    setPinByHashOpen(false);
  };
  const handleSubmit = () => {
    setCustomPinName('');
    setCid('');
    pinHash(cid, customPinName);
    closeModal();
  };
  return (
    <Modal isOpen={pinByHashOpen} toggle={closeModal} centered={true}>
      {userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit.type}>
          {userMetricLimit.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Pin By CID</h5>
        <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>
          Provide a Content Identifier(CID), also known as a hash, to pin and an optional name for that pin. Pinata will
          then add the CID to the queue and start searching for your content. Once your content has been found, it will
          be pinned.
        </p>
        <p>
          Please note: The IPFS network is big, and it might take quite some time to find / retrieve content. Please be
          patient as our nodes search for your content. It is also possible that the content is no longer available on
          the network. In that scenario, your pin by CID action will fail.
        </p>
        <div className="col-md-8">
          <label htmlFor="example-text-input" className="col-md-6 col-form-label">
            IPFS CID To Pin
          </label>
          <input
            className="form-control"
            placeholder="Qma3h..."
            type="text"
            onChange={(e) => setCid(e.target.value)}
            value={cid}
          />
          <label htmlFor="example-text-input" className="col-md-8 col-form-label">
            Custom Name For Pin
          </label>

          <input
            className="form-control"
            placeholder="Optional pin name"
            type="text"
            onChange={(e) => setCustomPinName(e.target.value)}
            value={customPinName}
          />
          <div style={{ marginTop: 20 }}>
            <button onClick={handleSubmit} className="btn btn-primary">
              Search and Pin
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(null, { pinHash })(PinByHashModal);
