import React from 'react';
import { Modal, Alert } from 'reactstrap';
import Upload from 'pages/PinManager/Upload';
import { metricLimitInfo } from 'helpers/enums';

const FolderUploadModal = ({ folderUploadOpen, setFolderUploadModalOpen, userMetricLimit, currentPlan }) => {
  const closeModal = () => {
    setFolderUploadModalOpen(false);
  };
  return (
    <Modal isOpen={folderUploadOpen} toggle={() => setFolderUploadModalOpen(false)} centered={true} backdrop="static">
      {!currentPlan?.isLegacy && userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricLimit.type}>
          {userMetricLimit.text}
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Upload Folder</h5>
        <button
          type="button"
          onClick={() => setFolderUploadModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/* <div className="pinata-flex-column">
          <div className="custom-file">
            <input onChange={handleUploadChange} type="file" className="custom-file-input" id="customFile" webkitdirectory="" directory="" mozdirectory="" />
            <label className="custom-file-label" htmlFor="customFile">
              Choose file
            </label>
          </div>
          <button className="btn btn-primary">Upload</button>
        </div> */}
        {/* <FormUpload
          closeModal={() => setFolderUploadModalOpen(false)}
          uploadType={"folder"}
        />         */}
        <Upload closeModal={closeModal} />
      </div>
    </Modal>
  );
};

export default FolderUploadModal;
