import React, {useEffect, useMemo, useState} from 'react';

import { Switch, BrowserRouter as Router, Route, useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from './store/alert/alert.actions';
// hooks
import { fetchSession, useAuth } from './hooks/useAuth';
import { useAnalytics } from 'hooks/useAnalytics';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

//  User actions
import { loadUserInfo } from './store/user/user.actions';

// layouts Format
import VerticalLayout from './components/VerticalLayout';
import HorizontalLayout from './components/HorizontalLayout';
import NonAuthLayout from './components/NonAuthLayout';

// pages
import PinManager from 'pages/PinManager';
import Keys from 'pages/APIKeys/index';
import Account from 'pages/Account/index';
import Billing from 'pages/Billing/index';
import PinataSync from 'pages/PinataSync';
import Authenticate from 'pages/Authentication/Authenticate';
import CIDChecker from 'pages/CIDChecker';
import Gateway from 'pages/Gateway';
import Usage from 'pages/Gateway/Usage/index';
import Register from 'pages/Authentication/Register';
import SignIn from 'pages/Authentication/SignIn';
import V2APIKeys from 'pages/V2APIKeys';
import BillingAddressModal from 'pages/Billing/BillingAddressModal';
import Developers from "./pages/Developers";

// Import scss
import { getUsageMetrics } from './store/metrics/metrics.actions';
import { getAllBillingPlans, retrieveStripeCustomer } from './store/billing/billing.actions';
import { ThemeProvider } from '@mui/material';
import { BillingState } from './store/billing/types';
import { MetricsState } from './store/metrics/types';
import { handleIfUserIsMarketingSignUp } from 'helpers/handleMarketingSignUp';
import ResetPassword from 'pages/Authentication/ResetPassword';
import './assets/scss/theme.scss';
import {ThemeContext} from "./services/globalContext";
import {Themes} from "./themes";
import {Theme} from "@mui/material/styles/createTheme";
import Components from "./pages/Components/Components";
import {UserState} from "./store/user/types";
import {GatewaysState} from "./store/gateways/types";

interface AppProps {
  user: UserState;
  billing: BillingState;
  data: any;
  gateways: GatewaysState;
  apiKeys: any;
  loadUserInfo: any;
  metrics: MetricsState;
  retrieveStripeCustomer: any;
  getUsageMetrics: any;
  layout: any;
  getAllBillingPlans: () => void;
}

const App = (props: AppProps) => {
  const {
    user,
    billing,
    data,
    gateways,
    apiKeys,
    loadUserInfo,
    metrics,
    retrieveStripeCustomer,
    getUsageMetrics,
    getAllBillingPlans
  } = props;
  const [openBillingAddressModal, setOpenBillingAddressModal] = React.useState(false);
  const { isAuthenticated, loggedInUser, handleSession }: any = useAuth();
  const userAnalyticsData = { user, billing, data, gateways, apiKeys, metrics };
  const { updateIntercom, loadFullStory } = useAnalytics(userAnalyticsData);
  const [focused, setFocused] = useState(false);
  const [currentThemeName, setCurrentThemeName] = useState('legacy');
  const [theme, setTheme] = useState<Theme>(Themes.legacy);
  const [currentLayout, setCurrentLayout] = useState('horizontal');
  process.env.NODE_ENV !== 'development' && useGoogleAnalytics();

  // Reset intercom when metrics are ok
  useEffect(() => {
    if (metrics?.metricsLimitData.title === 'under-limit') {
      window.Intercom('shutdown');
      const reloadTimeout = setTimeout(() => {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID
        });
      }, 1500);
      return () => clearTimeout(reloadTimeout);
    }
  }, [metrics?.metricsLimitData]);

  useEffect(() => {
    handleIfUserIsMarketingSignUp();
    if (isAuthenticated) {
      loadFullStory();
      if (window?.Intercom) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID
        });
      }
      try {
        loadUserInfo(loggedInUser?.attributes);
      } catch (error) {
        console.log(error);
      }
    }
  }, [isAuthenticated]);

  // update intercom once one of these props change and not trigger this function during loglout
  useEffect(() => {
    if (window?.Intercom && window?.Intercom?.booted && isAuthenticated) {
      updateIntercom();
    }
  }, [
    user?.user?.email,
    billing?.activePricingPlan?.nickname,
    apiKeys?.keys?.count,
    metrics?.metricsLimitData?.title,
    metrics?.metrics?.metricsAccount?.fileCount,
    metrics?.metrics?.metricsAccount?.gatewayCount,
    metrics?.metrics?.metricsMonthly?.transferBytes
  ]);

  // Stripe Customer Billing Address Modal handler
  useEffect(() => {
    if (
      billing.activePricingPlan &&
      (!(billing?.stripe_customer?.address?.country.length > 0) ||
        !(billing?.stripe_customer?.address?.postalCode.length > 0)) &&
        billing?.activePricingPlan?.nickname !== 'Free'
    ) {
      // setOpenBillingAddressModal(true);
    }
  }, [billing?.stripe_customer?.address, billing.activePricingPlan?.nickname]);

  const initializePricing = async () => {
    const sessionData = await fetchSession();
    if (sessionData?.accessToken) {
      await retrieveStripeCustomer();
      await getUsageMetrics();
    }
  };

  useEffect(() => {
    if (user?.user?.email) {
      initializePricing();
      getAllBillingPlans();
    }
  }, [user?.user]);

  function getLayout() {
    let layoutCls: any = VerticalLayout;

    switch (currentLayout) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = useMemo(() => getLayout(), [currentLayout]);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  },[]);

  useEffect(() => {
    if (focused) {
      handleSession();
    }
  }, [focused]);

  useEffect(() => {
    if (currentLayout === 'vertical') {
      setTheme(Themes.light);
    } else {
      setTheme(Themes.legacy);
    }
  }, [currentLayout]);

  useEffect(() => {
    if (currentThemeName === 'light') {
      setTheme(Themes.light);
    } else if (currentThemeName === 'dark') {
      setTheme(Themes.dark);
    }
  }, [currentThemeName]);

  return (
    <React.Fragment>
      <ThemeContext.Provider value={{ layout: currentLayout, themeName: currentThemeName, setLayout: setCurrentLayout, setThemeName: setCurrentThemeName }}>
        <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            {isAuthenticated && (
              <Layout>
                <Route exact path="/" component={PinManager} />
                <Route path="/pinmanager" component={PinManager} />
                <Route path="/keys" component={Keys} />
                <Route path="/v2" component={V2APIKeys} />
                <Route path="/profile" component={Account} />
                <Route path="/billing" component={Billing} />
                <Route path="/sync" component={PinataSync} />
                <Route path="/cidchecker" component={CIDChecker} />
                <Route exact path="/gateway" component={Gateway} />
                <Route path="/authenticate" component={Authenticate} />
                <Route path="/gateway/:name" component={Usage} />
                { user.user?.featureFlags?.developers &&
                  <Route path="/developers" component={Developers}/>
                }
                <Route exact path="/signin" render={() => <Redirect to="/pinmanager" />} />
                <Route exact path="/register" render={() => <Redirect to="/pinmanager" />} />
                {openBillingAddressModal && (
                  <BillingAddressModal
                    setModalOpen={setOpenBillingAddressModal}
                    modalIsOpen={openBillingAddressModal}
                  />
                )}
                <Route path="/components" component={Components} />
              </Layout>
            )}

            {!isAuthenticated && (
              <NonAuthLayout>
                <Switch>
                  <Route exact path="/" component={SignIn} />
                  <Route path="/signin" component={SignIn} />
                  <Route path="/register" component={Register} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="*" component={SignIn} />
                </Switch>
              </NonAuthLayout>
            )}
          </Switch>
        </Router>
      </ThemeProvider>
      </ThemeContext.Provider>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    billing: state.billing,
    data: state.data,
    gateways: state.gateways,
    apiKeys: state.apiKeys,
    metrics: state.metrics
  };
};

export default connect(mapStateToProps, {
  loadUserInfo,
  getUsageMetrics,
  retrieveStripeCustomer,
  getAllBillingPlans
})(App);
