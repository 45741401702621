import { SET_ALERT, REMOVE_ALERT } from 'actions/types';
import {AlertState} from "./types";

const initialState: AlertState = {
    alert: null
};

export default function (state = initialState, action: any) {
    const { type, payload } = action;
    switch (type) {
        case SET_ALERT:
            return {
                ...state,
                alert: payload
            };
        case REMOVE_ALERT:
            return {
                ...state,
                alert: null
            };
        default:
            return state;
    }
}
