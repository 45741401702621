import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { StripeProvider } from 'react-stripe-elements';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';
import './i18n';

import './pinataCustomStyles.css';

// Sentry.init({
//   dsn: 'https://ae3bf52394614b08803ad2d64be94c0a@o179825.ingest.sentry.io/6115620',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || 0.1,
//   environment: process.env.NODE_ENV || 'development'
// });

const app = (
  <Provider store={store}>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StripeProvider>
  </Provider>
);

Sentry.withErrorBoundary(app, { fallback: <p>an error has occurred</p> });
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
