export const makeDatePretty = (date: Date | number | string, locale: string = 'en') => {
  const newDate = new Date(date)
  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  switch (locale) {
    case "en":
      return `${month}/${day}/${year}`
    default:
      return `${month}/${day}/${year}`
  }
}

export const prettySize = (bytes: number, isDecimal = true, decimalPlaces = 2, includeUnits = true) => {
  const thresh = isDecimal ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
      return `${bytes} B`;
  }
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const numberOfDecimalPlaces = decimalPlaces ? decimalPlaces : 1;
  let u = -1;
  do {
      bytes /= thresh;
      ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return `${bytes.toFixed(numberOfDecimalPlaces)}${includeUnits ? ' '+units[u] : ''}`;
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
