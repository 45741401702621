import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Button } from '@mui/material';

const EditAccountDetails = ({ user, loggedInUser, setEditing, setAlert, changeEmail }) => {
  const [newFName, setNewFirstName] = useState(
    loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName']
      ? loggedInUser.attributes['custom:firstName']
      : ''
  );
  const [newLName, setNewLastName] = useState(
    loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:lastName']
      ? loggedInUser.attributes['custom:lastName']
      : ''
  );
  const [submitting, setSubmitting] = useState(false);
  const [newEmail, setNewEmail] = useState(user?.email || '');
  const { updateAttributes } = useAuth();
  const history = useHistory();

  const getLoggedInUserAttributes = () => {
    let fName = '';
    let lName = '';
    if (loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName']) {
      fName = loggedInUser.attributes['custom:firstName'];
    }

    if (loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:lastName']) {
      lName = loggedInUser.attributes['custom:lastName'];
    }

    return { fName, lName };
  };

  const submit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (getLoggedInUserAttributes().fName !== newFName || getLoggedInUserAttributes().lName !== newLName) {
      try {
        await updateAttributes({
          'custom:firstName': newFName,
          'custom:lastName': newLName
        });
        setAlert('Updated!', 'success');
        setEditing(false);
      } catch (error) {
        console.log(error);
        setAlert('Error updating', 'error');
      }
    }
    if (newEmail !== user.email) {
      try {
        const res = await changeEmail(newEmail);
        if (res.success) {
          setAlert('Email updated, logging out...', 'success');
          history.push('/signout');
        }
      } catch (error) {
        setAlert('Toruble updating email', 'error');
      }
    }
    setSubmitting(false);
  };
  return (
    <Form onSubmit={submit}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="formrow-firstname-Input">First Name</Label>
            <Input
              value={newFName}
              onChange={(e) => setNewFirstName(e.target.value)}
              type="text"
              className="form-control"
              id="formrow-firstname-Input"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="formrow-firstname-Input">Last Name</Label>
            <Input
              value={newLName}
              onChange={(e) => setNewLastName(e.target.value)}
              type="text"
              className="form-control"
              id="formrow-firstname-Input"
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="formrow-email-Input">Email</Label>
        <Input
          value={newEmail}
          type="email"
          onChange={(e) => setNewEmail(e.target.value)}
          className="form-control"
          id="formrow-email-Input"
        />
      </FormGroup>
      <div>
        <Button type="submit">{submitting ? 'Saving...' : 'Save'}</Button>
      </div>
    </Form>
  );
};

export default EditAccountDetails;
