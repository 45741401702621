import { useAuth } from 'hooks/useAuth';
import React, {useState, useEffect, MouseEvent} from 'react';
import { Row, Col, Input, Form, FormGroup } from 'reactstrap';
import {getErrorMessage} from "../../helpers/error.helper";
import {Box, Button, Card, CardContent, TextField, Typography} from "@mui/material";

interface AccountSecurityCardProps {
 setAlert: (message: string, type: string) => void;
}

const AccountSecurityCard = (props: AccountSecurityCardProps) => {
  const { setAlert } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { changePassword, getMFAOptions, verifytTotp, setupTotp, setPreferredMFA } = useAuth();

  const canSubmit = () => {
    if (!oldPassword || !newPassword) {
      return false;
    }
    return true;
  };

  const handleChangePassword = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      await changePassword(oldPassword, newPassword);
      setOldPassword('');
      setNewPassword('');
      setAlert('Password updated!', 'success');
    } catch (error) {
      const message = getErrorMessage(error);
      setAlert(message, 'error');
    }
  };

  return (
    <Box>
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5">Change Password</Typography>
                <Form>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <TextField
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      type="password"
                      label="Current password"
                      sx={{marginBottom: 1, marginTop: 1}}
                    />
                  </Box>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <TextField
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type="password"
                      label="New password"
                      sx={{marginBottom: 1, marginTop: 1}}
                    />
                  </Box>
                  <Button
                    onClick={handleChangePassword}
                    disabled={!canSubmit()}
                  >
                    Save
                  </Button>
                </Form>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
    </Box>
  );
};

export default AccountSecurityCard;
