import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import SignInForm from './SignInForm';
import ConfirmCode from './ConfirmCode';
import { clearCognitoCache, useAuth } from 'hooks/useAuth';
import { setAlert } from '../../store/alert/alert.actions';
import logoImg from '../../assets/images/logo.svg';

const SignIn = ({ setAlert }) => {
  const [authError, setAuthError] = useState(null);
  const [confirmCode, setConfirmCode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [mfa, setMFA] = useState(false);
  const [cogUser, setUser] = useState(null);
  const history = useHistory();
  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
      code: ''
    }
  });
  const { email } = methods.watch();

  const { logUserIn, confirmSignUp, resendConfirmationCode, confirmMFA } = useAuth();

  useEffect(() => {
    const clearCache = localStorage.getItem('pinataCacheBust2');
    if (!clearCache) {
      clearCognitoCache();
      localStorage.setItem('pinataCacheBust2', 'true');
    }
  }, []);

  const handleValidSubmit = async (data) => {
    const { email, password, code } = data;
    setSubmitting(true);
    setAuthError(null);
    if (confirmCode) {
      let res;
      if (mfa) {
        res = await confirmMFA(cogUser, code);
      } else {
        res = await confirmSignUp(email, password, code);
      }
      if (res.success) {
        history.push('/authenticate');
      } else {
        setSubmitting(false);
        setAuthError(res.error.message);
      }
    } else {
      const result = await logUserIn(email, password);
      if (result.user && result.user.challengeName) {
        //  Indicates the user has MFA enabled
        setMFA(true);
        setUser(result.user);
        setConfirmCode(true);
      } else if (result.error && result.error.code && result.error.code === 'UserNotConfirmedException') {
        setAuthError('Account has not been confirmed, enter code that was previously emailed or request a new one');
        setConfirmCode(true);
      } else if (!result.success) {
        setAuthError(result.error.message);
      } else {
        history.push('/authenticate');
      }
    }
    setSubmitting(false);
  };

  const handleResendCode = async () => {
    try {
      setSubmitting(true);
      if (email) {
        await resendConfirmationCode(email);
        setAlert('Confirmation code re-sent!', 'success');
      }
    } catch (error) {
      setAuthError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="mt-5 text-center">
                <p style={{ fontSize: 16 }}>
                  Need to register for an account?{' '}
                  <Link to="/register" className="font-weight-medium text-primary">
                    {' '}
                    Register
                  </Link>{' '}
                </p>
              </div>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <h5 className="text-white p-4">{mfa ? 'Enter the MFA code' : 'Sign Into Your Account'}</h5>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <FormProvider {...methods}>
                      <form className="form-horizontal" onSubmit={methods.handleSubmit(handleValidSubmit)}>
                        {authError && <Alert color="danger">{authError}</Alert>}
                        {confirmCode ? (
                          <ConfirmCode
                            confirmCode={confirmCode}
                            mfa={mfa}
                            setConfirmCode={setConfirmCode}
                            submitting={submitting}
                          />
                        ) : (
                          <SignInForm
                            confirmCode={confirmCode}
                            setConfirmCode={setConfirmCode}
                            submitting={submitting}
                          />
                        )}
                        {confirmCode && (
                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              <button
                                disabled={submitting}
                                type="submit"
                                className="btn btn-primary btn-block waves-effect waves-light mt-4"
                              >
                                Verify code
                              </button>
                              {mfa ? (
                                <button
                                  onClick={() => {
                                    methods.reset();
                                    setMFA(false);
                                    setConfirmCode(false);
                                  }}
                                  className="btn btn-block text-primary"
                                >
                                  Back to Sign In
                                </button>
                              ) : (
                                <button
                                  onClick={handleResendCode}
                                  className="btn btn-block waves-effect waves-light mt-4"
                                >
                                  Resend code
                                </button>
                              )}
                            </p>
                          </div>
                        )}
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Forgot your password?{' '}
                            <Link to="/reset-password" className="text-primary">
                              Click here to reset it.
                            </Link>
                          </p>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the Pinata's{' '}
                            <a
                              href="https://pinata.cloud/terms"
                              target="_blank"
                              rel="noopenner noreferrer"
                              className="text-primary"
                            >
                              Terms of Use
                            </a>
                          </p>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = ({ user }) => ({
  user
});

const mapDispatchToProps = { setAlert };

export default connect(mapStatetoProps, mapDispatchToProps)(SignIn);
