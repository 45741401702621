import React from 'react';
import { Modal } from 'reactstrap';
import PaymentMethod from './PaymentMethod';

const AddCardModal = ({ addCardModalOpen, setAddCardModalOpen, handleAddCard, billing, setAlert }) => {
    return (
        <Modal isOpen={addCardModalOpen} toggle={() => setAddCardModalOpen(false)} centered={true}>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Add Payment Card</h5>
                <button
                    type="button"
                    onClick={() => setAddCardModalOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <PaymentMethod handleAddCard={handleAddCard} billing={billing} setAlert={setAlert} />
            </div>
        </Modal>
    );
};

export default AddCardModal;
