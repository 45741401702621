import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logUserOut, useAuth } from 'hooks/useAuth';
import store from '../store';
import { LOG_OUT } from 'actions/types';

const UseSignOut = () => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  async function logOutAndClearAllData() {
    try {
      await logUserOut();
    } catch (error) {
      console.log(error);
    } finally {
      window?.Intercom?.booted && window?.Intercom('shutdown');
      window?.FS?.getCurrentSession() && window?.FS?.shutdown();
      await store.dispatch({ type: LOG_OUT });
    }
  }

  async function signOut() {
      return await logOutAndClearAllData();
  }

  return { signOut };
};

export default UseSignOut;
