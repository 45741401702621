import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavbarToggler, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { toggleLeftmenu } from '../../store/actions';

import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import logo from '../../assets/images/logo.svg';

interface HeaderProps {
  user: any;
  toggleMenu: any;
}

const Header = ({ user, toggleMenu }: HeaderProps) => {
  return (
      <header id="page-topbar">
        <Container fluid className="d-flex justify-content-between">
          <Link to="/" className="logo logo-dark">
            <span className="logo">
              <img src={logo} alt="" height="50" />
              <span className="logo-text-dark d-xs-none">Pinata</span>
            </span>
          </Link>

          <NavbarToggler className="d-lg-none" onClick={toggleMenu}>
            <i className="fa fa-fw fa-bars" />
          </NavbarToggler>

          <div className="d-flex">{user?.user && <ProfileMenu />}</div>
        </Container>
      </header>
  );
};

const mapStateToProps = (state: any) => {
  const { Layout, user } = state;
  const { layoutType, showRightSidebar, leftMenu } = Layout;
  return { layoutType, showRightSidebar, leftMenu, user };
};

export default connect(mapStateToProps, {
  toggleLeftmenu
})(withTranslation()(Header));
