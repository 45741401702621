import { Col, Row } from 'reactstrap';
import { validateMetricsLimits } from '../../helpers/validations';
import { planTypes } from 'helpers/enums';
import {BillingState} from "../../store/billing/types";
import {Metrics} from "../../store/metrics/types";
import {Button, Typography} from "@mui/material";

interface TableNavProps {
  setWizardModalOpen: (value: boolean) => void;
  metrics: Metrics;
  setAlert: (message: any, type: string) => void;
  billing: BillingState;
}

const TableNav = ({ setWizardModalOpen, metrics, setAlert, billing }: TableNavProps) => {
  const validateMetrics = () => {
    const validMetrics = validateMetricsLimits(metrics, billing);
    if (validMetrics) {
      setAlert(
        <span>
          This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
        </span>,
        'error'
      );
      return;
    }
    setWizardModalOpen(true);
  };
  return (
    <Row className="my-4">
      <Col xl={3} sm={12} md={5}>
        <Typography variant="h6">My Gateways</Typography>
        <Button onClick={() => validateMetrics()}  color="info">
          <i className="mdi mdi-plus mr-1" /> Create Gateway
        </Button>
      </Col>
    </Row>
  );
};

export default TableNav;
