import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import SelectCountry from 'components/Common/SelectCountry';
import { updateCustomerBillingAddress } from '../../store/billing/billing.actions';

type Props = {
  setModalOpen: any;
  mainActionHandler?: any;
  updateCustomerBillingAddress?: any;
  billingAddress: {
    country: string;
    postalCode: string;
  };
};

const BillingAddressForm: React.FC<Props> = ({
  setModalOpen,
  mainActionHandler,
  billingAddress,
  updateCustomerBillingAddress
}) => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      zipCode: billingAddress.postalCode,
      countryName: billingAddress.country
    }
  });

  const handleSubmit = async (data: any) => {
    const { zipCode, countryName } = data;
    const stripeCustomerAddress = { country: countryName, postal_code: zipCode };
    try {
      setIsDisabled(true);
      updateCustomerBillingAddress(stripeCustomerAddress);
    } catch (error) {
      console.log(error);
    } finally {
      if (mainActionHandler) await mainActionHandler();
      setIsDisabled(false);
      setModalOpen(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form className="my-2" onSubmit={methods.handleSubmit(handleSubmit)}>
        {isDisabled ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <p>Keep your billing address up to date. Please add your country and zip code information.</p>
            <SelectCountry billingAddress={billingAddress} />
          </>
        )}
        <button className="btn btn-primary btn-block mt-2" type="submit" disabled={isDisabled}>
          Update My Billing Address
        </button>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state: any) => ({
  billingAddress: state?.billing?.stripe_customer?.address
});

const mapDispatchToProps = {
  updateCustomerBillingAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAddressForm);
