import React, { useState } from 'react';
import KeyInfoModal from './KeyInfoModal';
import NewKeyModal from './NewKeyModal';
import { Button } from '@mui/material';
import {KeyPermissions} from "../../store/apiKeys/types";

interface TableNavProps {
  setAlert: () => any;
  generateNewApiKey: (chosenPermissions: KeyPermissions, maxUses: string, keyName: string) => any;
}

const TableNav = ({ setAlert, generateNewApiKey }: TableNavProps) => {
  const [newKeyModalOpen, setNewKeyModalOpen] = useState(false);
  const [keyInfoModalOpen, setKeyInfoModalOpen] = useState(false);
  const [keyData, setKeyInfo] = useState(null);

  const generateNewKey = async (permissions: KeyPermissions, maxUses: string, keyName: string) => {
    setNewKeyModalOpen(false);
    const keyInfo = await generateNewApiKey(permissions, maxUses, keyName);
    if (keyInfo) {
      setKeyInfo(keyInfo);
      setKeyInfoModalOpen(true);
    }
  };
  return (
    <React.Fragment>
      <div>
            <Button onClick={() => setNewKeyModalOpen(true)} color="info">
              <i className="mdi mdi-plus mr-1"></i> New Key
            </Button>
      </div>
      <NewKeyModal
        generateNewKey={generateNewKey}
        setAlert={setAlert}
        newKeyModalOpen={newKeyModalOpen}
        setNewKeyModalOpen={setNewKeyModalOpen}
      />
      {keyData && (
        <KeyInfoModal
          setKeyInfoModalOpen={setKeyInfoModalOpen}
          keyInfoModalOpen={keyInfoModalOpen}
          keyData={keyData}
          setAlert={setAlert}
        />
      )}
    </React.Fragment>
  );
};

export default TableNav;
