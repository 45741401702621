import React from 'react';
import { Modal, Alert } from 'reactstrap';
import { metricLimitInfo } from 'helpers/enums';
import { Link } from 'react-router-dom';

const RemovePinModal = ({ hash, removePinModalOpen, setRemovePinModalOpen, handleRemovePin, userMetricsLimit }) => {
  return (
    <Modal isOpen={removePinModalOpen} toggle={() => setRemovePinModalOpen(false)} centered={true}>
      {userMetricsLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricsLimit.type}>
          {userMetricsLimit.text} <Link to="/billing">Upgrade</Link>
        </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Delete File</h5>
        <button
          type="button"
          onClick={() => setRemovePinModalOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h3>Are you sure you want to remove this file?</h3>
        <p>{hash}</p>
        <p>It cannot be undone.</p>
        <button onClick={handleRemovePin} className="btn btn-danger">
          Delete
        </button>
        <button onClick={() => setRemovePinModalOpen(false)} className="btn">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default RemovePinModal;
