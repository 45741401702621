import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useEffect, useMemo, useState } from 'react';

export default function GatewayVirtualList(props) {
  const { gateways, loadGateways, totalGateways, gatewaysLoading, handleGatewaySelect } = props;
  const [localGateways, setLocalGateways] = useState([]);

  const isItemLoaded = (index) => !hasNextPage || index < localGateways.length;

  useEffect(() => {
    setLocalGateways([...localGateways, ...gateways]);
  }, [gateways]);

  const hasNextPage = useMemo(() => {
    return localGateways.length < totalGateways;
  }, [localGateways]);

  const itemCount = useMemo(() => {
    return hasNextPage ? localGateways.length + 1 : localGateways.length;
  }, [localGateways]);

  const loadMoreItems = (req) => {
    return gatewaysLoading ? () => {} : loadGateways(req);
  };

  const handleGatewayClick = (gatewayIndex) => {
    handleGatewaySelect(localGateways[gatewayIndex]);
  };

  const Row = (props) => {
    const { index, style } = props;
    return (
      <div style={style}>
        {index < localGateways.length && index < localGateways.length ? (
          <p className={'gateway-select-container'}>
            {localGateways[index].domain}
            <button className={'btn btn-primary'} onClick={() => handleGatewayClick(index)} disabled={gatewaysLoading}>
              Select
            </button>
          </p>
        ) : (
          'Loading...'
        )}
      </div>
    );
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <List
          className="List"
          height={150}
          itemSize={50}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Row}
        </List>
      )}
    </InfiniteLoader>
  );
}
