import React, { useState, useEffect } from 'react';
import { fetchApiKeys, generateNewApiKey, revokeApiKey } from '../../store/apiKeys/apikeys.actions';
import { setAlert } from '../../store/alert/alert.actions';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// import Component
import KeyTable from './KeyTable';
import { connect } from 'react-redux';
import TableNav from './TableNav';
import {PinataState} from "../../store/types";
import {CardContent, Card, Container} from "@mui/material";
import {CardsBox} from "../../components/Common/MuiComponents";
import {UserState} from "../../store/user/types";
import {KeyPermissions} from "../../store/apiKeys/types";

interface ApiKeysProps {
  fetchApiKeys: (offset: number) => void;
  apiKeys: {keys: {keys: any[], count: number}};
  setAlert: (...props: any) => void;
  generateNewApiKey: (chosenPermissions: KeyPermissions, maxUses: string, keyName: string) => any;
  revokeApiKey: (apiKey: string) => any;
  user: UserState;
}

const ApiKeys = ({ fetchApiKeys, apiKeys, setAlert, generateNewApiKey, revokeApiKey, user }: ApiKeysProps) => {
  const [keys, setKeys] = useState<any []>([]);
  const [totalKeys, setTotalKeys] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const offset = pageLimit * currentPage - pageLimit;
    fetchApiKeys(offset);
  }, []);

  useEffect(() => {
    if (apiKeys.keys) {
      setKeys(apiKeys.keys.keys);
      setTotalKeys(apiKeys.keys.count);
    }
  }, [apiKeys]);

  useEffect(() => {
    const offset = pageLimit * currentPage - pageLimit;
    fetchApiKeys(offset);
  }, [currentPage]);

  return (
    <React.Fragment>
        <Container>
          {
            !user.user?.featureFlags?.developers &&
              <Breadcrumbs title="Developer" breadcrumbItem="API Keys"/>
          }
          <CardsBox>
            <Card sx={user.user?.featureFlags?.developers ? {boxShadow: 'none'} : null}>
              <CardContent>
                <TableNav setAlert={setAlert} generateNewApiKey={generateNewApiKey} />
                <KeyTable
                  revokeApiKey={revokeApiKey}
                  keys={keys}
                  pageLimit={pageLimit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalKeys={totalKeys}
                  setAlert={setAlert}
                />
              </CardContent>
            </Card>
          </CardsBox>
        </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    apiKeys: state.apiKeys,
    user: state.user
  };
};
export default connect(mapStateToProps, { fetchApiKeys, setAlert, generateNewApiKey, revokeApiKey })(ApiKeys);
