import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import {
  fetchApiKeys,
  generateNewApiKey,
  getV2Keys,
  revokeApiKey,
  createNewKey,
  deleteV2Key
} from '../../store/apiKeys/apikeys.actions';
import { setAlert } from '../../store/alert/alert.actions';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// import Component
import KeyTable from './KeyTable';
import { connect } from 'react-redux';
import TableNav from './TableNav';
import {PinataState} from "../../store/types";
import {CardContent, Card, Container} from "@mui/material";
import {CardsBox} from "../../components/Common/MuiComponents";

interface V2ApiKeysProps {
  getV2Keys: (offset: number) => void;
  apiKeys: any;
  setAlert: any;
  createNewKey: (...props: any) => any;
  revokeApiKey: (...props: any) => any;
  deleteV2Key: (...props: any) => any;
}

const V2ApiKeys = ({ getV2Keys, apiKeys, setAlert, createNewKey, revokeApiKey, deleteV2Key }: V2ApiKeysProps) => {
  const [keys, setKeys] = useState([]);
  const [totalKeys, setTotalKeys] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const offset = pageLimit * currentPage - pageLimit;
    getV2Keys(offset);
  }, []);

  useEffect(() => {
    if (apiKeys.v2Keys) {
      setKeys(apiKeys.v2Keys);
      setTotalKeys(apiKeys.totalV2Keys);
    }
  }, [apiKeys]);

  useEffect(() => {
    const offset = pageLimit * currentPage - pageLimit;
    getV2Keys(offset);
  }, [currentPage]);

  const generateNewV2Key = async () => {
    setAlert('Creating key...', 'info');
    await createNewKey();
    setAlert('API key created!', 'success');
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="Developer" breadcrumbItem="Submarine Keys" />
          <CardsBox>
          <Card>
            <CardContent>
              <TableNav generateNewV2Key={generateNewV2Key} />
              <KeyTable
                deleteV2Key={deleteV2Key}
                keys={keys}
                pageLimit={pageLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalKeys={totalKeys}
                setAlert={setAlert}
              />
            </CardContent>
          </Card>
          </CardsBox>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    apiKeys: state.apiKeys
  };
};
export default connect(mapStateToProps, {
  deleteV2Key,
  getV2Keys,
  setAlert,
  generateNewApiKey,
  revokeApiKey,
  createNewKey
})(V2ApiKeys);
