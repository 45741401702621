import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import pinataSub from '../../../assets/images/Submarine-Beta_Standard.svg';
import { useSelector } from 'react-redux';
import { planTypes } from 'helpers/enums';
import useSignOut from '../../../hooks/useSignOut';
import {ThemeContext} from "../../../services/globalContext";
import {UserState} from "../../../store/user/types";
import {PinataState} from "../../../store/types";

interface ProfileMenuProps {
  user: UserState;
  success?: any;
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const billing = useSelector((state: any) => state.billing);

  const { user } = props;
  const [menu, setMenu] = useState(false);
  const { signOut } = useSignOut();

  const [username, setUsername] = useState('Admin');
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser') || '');
        setUsername(obj.displayName);
      } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake' || process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
        const obj = JSON.parse(localStorage.getItem('authUser') || '');
        setUsername(obj.username);
      }
    }
  }, [props.success]);

  const canAccessSubmarine = () => {
    return (
      billing?.activePricingPlan?.type !== planTypes.FREE.type ||
      // hardcoded support for old plans
      billing?.activePricingPlan?.name === 'PROFESSIONAL' ||
      billing?.activePricingPlan?.name === 'INDIVIDUAL'
    );
  };

  const handleToggleLayout = () => {
    theme.setLayout(theme.layout === 'vertical' ? 'horizontal' : 'vertical');
  }

  const handleToggleTheme = () => {
    theme.setThemeName(theme.themeName === 'dark' ? 'light' : 'dark');
  }

  return (
    <React.Fragment>
      {user.user ? (
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
          <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
            <img
              className="rounded-circle header-profile-user"
              src={`https:${
                user && user.user
                  ? user.user.avatar
                  : '//www.gravatar.com/avatar/b6c948ee4720eb52c72516e71e2708cc?s=200&r=pg&d=mm'
              }`}
              alt="Header Avatar"
            />
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/profile" className="dropdown-item">
              {' '}
              <i className="bx bx-user font-size-16 align-middle mr-1" />
              Settings
            </Link>
            <Link to="/billing" className="dropdown-item">
              <i className="bx bx-wallet font-size-16 align-middle mr-1" />
              Billing
            </Link>
            <Link to="/keys" className="dropdown-item">
              <i className="fas fa-key font-size-16 align-middle mr-1" />
              API Keys
            </Link>
            {canAccessSubmarine() && (
              <Link to="/v2" className="dropdown-item">
                <i className="fas fa-key font-size-16 align-middle mr-1" />
                Submarine Keys
                <img
                  style={{ height: 15, marginTop: '-8px', marginLeft: 5 }}
                  src={pinataSub}
                  alt="Image of a yellow submarine"
                />
              </Link>
            )}

            <a href="https://docs.pinata.cloud" target="_blank" rel="noopener noreferrer" className="dropdown-item">
              <i className="fas fa-book font-size-16 align-middle mr-1" />
              Documentation
            </a>
            <div className="dropdown-divider" />
            { user.user.featureFlags?.allowRedesign &&
              <Link to="#" onClick={handleToggleLayout} className="dropdown-item">
                Toggle Layout
              </Link>
            }
            {
              user.user.featureFlags?.allowRedesign && theme.layout === 'vertical' &&
                <Link to="#" onClick={handleToggleTheme} className="dropdown-item">
                  Toggle Theme
                </Link>
            }
            {
              user.user.featureFlags?.allowRedesign &&
              <Link to="/components" className="dropdown-item">
                MUI Components
              </Link>
            }
            <Link to="#" onClick={() => signOut()} className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <span>
          <Link to="/signin" className="btn btn-primary">
            Sign In
          </Link>
          <Link to="/register" className="btn btn-pinataSecondary">
            Register
          </Link>
        </span>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state: PinataState) => {
  return {
    user: state.user,
    currentPlan: state.billing?.activePricingPlan
  };
};

export default connect(mapStatetoProps)(ProfileMenu);
