import {
    USER_PIN_LIST_RETRIEVED,
    USER_PINNED_DATA_TOTALS_RECEIVED,
    USER_DATA_USAGE_RETRIEVED,
    REGION_PERMISSIONS_RECEIVED,
    PIN_JOBS,
    TRAVERSE_FOLDER,
    PIN_CONTENT_PREVIEW_UPDATED
} from 'actions/types';

const initialState = {
    userPinList: {
        count: 0,
        rows: []
    },
    userPinnedDataTotals: {
        pin_count: 0,
        pin_size_total: 0
    },
    folderDetails: null,
    pinJobList: [],
    pinRegionPermissions: null
};

export default function (state = initialState, action: any) {
    const { type, payload } = action;
    switch (type) {
        case USER_PIN_LIST_RETRIEVED:
            return {
                ...state,
                userPinList: payload
            };
        case PIN_CONTENT_PREVIEW_UPDATED:
            return {
                ...state,
                userPinList: {
                    ...state.userPinList,
                    rows: payload
                }
            };
        case USER_PINNED_DATA_TOTALS_RECEIVED:
            return {
                ...state,
                userPinnedDataTotals: payload
            };
        case REGION_PERMISSIONS_RECEIVED:
            return {
                ...state,
                pinRegionPermissions: payload
            };
        case PIN_JOBS:
            return {
                ...state,
                pinJobList: payload
            };
        case TRAVERSE_FOLDER:
            return {
                ...state,
                folderDetails: payload
            };
        default:
            return state;
    }
}
