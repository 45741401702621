import React, { useEffect } from 'react';
import { connect, ReactReduxContext } from 'react-redux';

import {
  createStripePaymentSource,
  detachStripeSourceFromCustomer,
  setDefaultCard
} from '../../store/billing/billing.actions';
import { setAlert } from '../../store/alert/alert.actions';

import AddCardModal from './AddCardModal';
import BillingAddressModal from './BillingAddressModal';
import RemoveCardModal from './RemoveCardModal';
import {CardContent, Card, Table, TableRow, TableHead, TableCell, TableBody, Typography, Button} from "@mui/material";
import {GatewaysState} from "../../store/gateways/types";
import {BillingState} from "../../store/billing/types";
import {PinataState} from "../../store/types";

const CARDS_ALLOWED = 5;

interface PaymentInfoProps {
  billing: BillingState;
  createStripePaymentSource: any;
  detachStripeSourceFromCustomer: any;
  setDefaultCard: any;
  setAlert: (message: string, type: string) => void;
  gateways: GatewaysState;
  openCardModal: any;
  setOpenCardModal: any;
  changePlanAfterCardAdded: any;
  actionAfterCardAdded: any;
}

function PaymentInfo(props: PaymentInfoProps) {
  const [removeCardModalOpen, setRemoveCardModalOpen] = React.useState(false);
  const [cardInfo, setCardInfo] = React.useState(null);
  const [openBillingAddressModal, setOpenBillingAddressModal] = React.useState(false);
  const {
    billing,
    gateways,
    createStripePaymentSource,
    detachStripeSourceFromCustomer,
    setAlert,
    setDefaultCard,
    openCardModal,
    setOpenCardModal,
    changePlanAfterCardAdded,
    actionAfterCardAdded
  } = props;
  const paymentMethods = billing?.stripe_customer?.paymentMethods;

  React.useEffect(() => {}, [openBillingAddressModal]);

  const confirmRemoveCard = async (card: {id: any}) => {
    setAlert('Removing card...', 'info');
    setRemoveCardModalOpen(false);
    setCardInfo(null);
    await detachStripeSourceFromCustomer(card.id);
  };

  const renderPaymentMethods = (method: any) => {
    return (
      <TableRow key={method.id}>
        <TableCell>{method.brand}</TableCell>
        <TableCell>{method.last4}</TableCell>
        <TableCell>{`${method.exp_month}/${method.exp_year}`}</TableCell>
        <TableCell>
          {method.isActive ? (
            <span className="text-success">Default</span>
          ) : (
            <button onClick={() => setDefaultCard(method.customer, method.id)} className="pinata-link-button p-0">
              Make Default
            </button>
          )}
        </TableCell>
        <TableCell>
          {!method.isActive && (
            <button onClick={() => handleOpenCardRemovalWindow(method)} className="pinata-link-button">
              <i className="fas fa-trash"></i>
            </button>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleAddCard = async (tokenId: string) => {
    setOpenCardModal(false);
    try {
      setAlert('Adding card...', 'info');
      await createStripePaymentSource(tokenId);
      if (changePlanAfterCardAdded) {
        actionAfterCardAdded();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenCardRemovalWindow = (paymentMethod: any) => {
    const hasGateways = gateways?.gateways?.rows?.length;
    if (hasGateways && paymentMethods.length === 1) {
      return setAlert('You cannot remove your card when you have active dedicated gateways', 'error');
    }

    setRemoveCardModalOpen(true);
    setCardInfo(paymentMethod);
  };

  const handleNewCardModal = () => {
    if (paymentMethods.length < CARDS_ALLOWED) {
      setOpenCardModal(true);
    } else {
      setAlert('You can only add 5 cards', 'error');
    }
  };

  // const handleBillingAddressModal = () => {
  //   setOpenBillingAddressModal(true);
  // };

  return (
    <>
      <Card>
        <CardContent>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Typography variant="h6">Payment Info</Typography>
            <Button onClick={handleNewCardModal}>
              Add Card
            </Button>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell scope="col">Brand</TableCell>
                <TableCell scope="col">Last 4</TableCell>
                <TableCell scope="col">Expiration</TableCell>
                <TableCell scope="col">Default</TableCell>
                <TableCell scope="col"></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{paymentMethods.map((pm) => renderPaymentMethods(pm))}</TableBody>
          </Table>
          {/*{Boolean(paymentMethods.length) && (*/}
          {/*  <button className="btn btn-sm btn-info" onClick={handleBillingAddressModal}>*/}
          {/*    Update My Billing Address*/}
          {/*  </button>*/}
          {/*)}*/}
        </CardContent>
      </Card>
      {openCardModal && (
        <AddCardModal
          setAddCardModalOpen={setOpenCardModal}
          addCardModalOpen={openCardModal}
          handleAddCard={handleAddCard}
          billing={billing}
          setAlert={setAlert}
        />
      )}
      {openBillingAddressModal && (
        <BillingAddressModal setModalOpen={setOpenBillingAddressModal} modalIsOpen={openBillingAddressModal} />
      )}
      {removeCardModalOpen && (
        <RemoveCardModal
          removeCardModalOpen={removeCardModalOpen}
          setRemoveCardModalOpen={setRemoveCardModalOpen}
          cardInfo={cardInfo}
          confirmRemoveCard={confirmRemoveCard}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: PinataState) => ({
  billing: state?.billing,
  gateways: state?.gateways
});

const mapDispatchToProps = {
  setDefaultCard,
  setAlert,
  createStripePaymentSource,
  detachStripeSourceFromCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
