import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <React.Fragment>
      <footer
        style={{ background: '#fff', left: 0, minHeight: 35, height: 'auto', textAlign: 'center', top: 10 }}
        className="footer"
      >
        <Row>
          <Col md={3}>
            <a className="pinata-link" href="https://pinata.cloud/privacy">
              Privacy
            </a>
          </Col>
          <Col md={3}>
            <a className="pinata-link" href="https://pinata.cloud/terms">
              Terms and Conditions
            </a>
          </Col>
          <Col md={3}>
            <a className="pinata-link" href="https://pinata.cloud/acceptableuse">
              Acceptable Use
            </a>
          </Col>
          <Col md={3}>
            <a className="pinata-link" href="https://pinata.cloud/dmca">
              DMCA
            </a>
          </Col>
        </Row>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
