import React from 'react';
import { Col, Row } from 'reactstrap';
import {Button, Typography} from "@mui/material";

interface TableNavProps {
  generateNewV2Key: () => void;
}

const TableNav = ({ generateNewV2Key }: TableNavProps) => {
  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <Typography variant="h6">Submarine Keys</Typography>
              <p>These API keys control our new Submarine API.</p>
              <Button onClick={generateNewV2Key} color="info">
                <i className="mdi mdi-plus mr-1"></i> New Key
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TableNav;
