import React from "react"
import { Modal, Alert } from "reactstrap"
import Upload from "pages/PinManager/Upload"
import { metricLimitInfo } from "helpers/enums"

const FileUploadModal = ({ fileUploadOpen, setFileUploadModalOpen, userMetricLimit, currentPlan }: any) => {
  const closeModal = () => {
    setFileUploadModalOpen(false)
  }
  return (
    <Modal
      isOpen={fileUploadOpen}
      toggle={closeModal}
      centered={true}
      backdrop="static"
    >
      {!currentPlan?.isLegacy && userMetricLimit?.title !== metricLimitInfo.PLAN_OK.title && (
          <Alert className="m-0" color={userMetricLimit.type}>
              {userMetricLimit.text}
          </Alert>
      )}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Upload File</h5>
        <button
          type="button"
          onClick={closeModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/* <FormUpload uploadType={"file"} closeModal={closeModal} /> */}
        <Upload uploadType={"file"} closeModal={closeModal} />
      </div>
    </Modal>
  )
}

export default FileUploadModal
