import React from 'react';
import { Modal, Row, Col } from 'reactstrap';

interface KeyInfoModalProps {
    setKeyInfoModalOpen: (open: boolean) => void;
    keyInfoModalOpen: boolean;
    keyData: any;
    setAlert: (message: string, type: 'success' | 'danger' | 'warning') => void;
}

const KeyInfoModal = ({ setKeyInfoModalOpen, keyInfoModalOpen, keyData, setAlert }: KeyInfoModalProps) => {
    const copyKeyInfo = () => {
        navigator.clipboard.writeText(
            `API Key: ${keyData.pinata_api_key}\n API Secret: ${keyData.pinata_api_secret}\n JWT: ${keyData.JWT}`
        );
        setAlert('Key Info Copied!', 'success');
    };
    return (
        <Modal isOpen={keyInfoModalOpen} toggle={() => setKeyInfoModalOpen(false)} centered={true}>
            <div className="modal-header">
                <h5 className="modal-title mt-0">API Key Info</h5>
                <button
                    type="button"
                    onClick={() => setKeyInfoModalOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div>
                    <h3>This info will only be shown once</h3>
                    <p>Make sure you store the info somewhere safe</p>
                </div>
                <Row>
                    <Col xs={4}>
                        <h5>API Key</h5>
                    </Col>
                    <Col xs={8}>
                        <p style={{ lineBreak: 'anywhere' }}>{keyData.pinata_api_key}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <h5>API Secret</h5>
                    </Col>
                    <Col xs={8}>
                        <p style={{ lineBreak: 'anywhere' }}>{keyData.pinata_api_secret}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <h5>JWT</h5>
                        <h6>(Secret access token)</h6>
                    </Col>
                    <Col xs={8}>
                        <p style={{ lineBreak: 'anywhere' }}>{keyData.JWT}</p>
                    </Col>
                </Row>
                <button onClick={copyKeyInfo} className="btn btn-primary">
                    Copy All
                </button>
                <button onClick={() => setKeyInfoModalOpen(false)} className="btn">
                    Done
                </button>
            </div>
        </Modal>
    );
};

export default KeyInfoModal;
