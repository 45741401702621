import React, { useEffect } from 'react';
import 'toastr/build/toastr.min.css';
import {Alert as MuiAlert, Box, Snackbar, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {styled} from "@mui/material/styles";
import {AlertState} from "../../store/alert/types";

const IconBox = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
}))

interface AlertProps {
  alert: AlertState
}

const alertTitles = {
  success: 'Success!',
  error: 'Error',
  warning: 'Uh oh, something went wrong',
  info: 'Did you know?'
}

//TODO: Get the colors from the theme once we refactor alert into UseContext
const iconMapping = {
  success: <IconBox sx={{backgroundColor: '#CCF1DF'}}><CheckIcon sx={{color:"#05542D"}} /> </IconBox>,
  error: <IconBox sx={{backgroundColor: '#F8BDBD'}}><ErrorOutline sx={{color:"#5C0707"}} /> </IconBox>,
  warning: <IconBox sx={{backgroundColor: '#F8E7BE'}}><WarningAmberRoundedIcon sx={{color:"#68430A"}} /> </IconBox>,
  info: <IconBox sx={{backgroundColor: '#D4F9FF'}}><InfoOutlinedIcon sx={{color:"#005765"}} /> </IconBox>
}

export const PinataAlert = (props: AlertProps) => {
  const {alert} = props;
  return <MuiAlert severity={alert?.alert?.type || "info"} iconMapping={iconMapping}>
    <Typography variant="body1">{alertTitles[alert?.alert?.type || 'success']}</Typography>
    <Typography variant="body2">{alert?.alert?.message}</Typography>
  </MuiAlert>
}

const VerticalAlert = ({ alert }: AlertProps) => {
  return (
      alert.alert ? <Snackbar open={Boolean(alert.alert)} anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
          <PinataAlert alert={alert} />
      </Snackbar> : null
  );
};

export default VerticalAlert;
