import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Label, Input, Spinner, ModalFooter, Alert } from 'reactstrap';
import { api } from 'services/api';
import { metricLimitInfo } from 'helpers/enums';
import { Link } from 'react-router-dom';

function ContentPreviewModal({
  contentPreviewModalOpen,
  toggleModal,
  setAlert,
  pinInfo,
  action,
  loading,
  userMetricsLimit,
  currentPlan
}) {
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [descrInputCount, setDescrInputCount] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [edit, setIsEdit] = useState(false);
  const fileInput = useRef(null);

  useEffect(async () => {
    let title = '';
    let description = '';
    let thumbnail = '';
    try {
      const { data: previewData } = await api.get(`v2/pins/${pinInfo.id}/preview`);
      if (previewData) {
        title = previewData.title;
        description = previewData.description;
        thumbnail = previewData.preview_url.substring(
          previewData.preview_url.length,
          previewData.preview_url.lastIndexOf('/') + 1
        );
        setIsEdit(true);
      }
    } catch (error) {
      console.log(error);
      setAlert('Failed to load previous preview settings.', 'error');
    } finally {
      setTitle(title);
      setDescription(description);
      setDescrInputCount(description.length);
      setThumbnail(thumbnail);
    }
  }, []);

  const textareaChange = (event) => {
    const count = event.target.value.length;
    setDescription(event.target.value);
    setDescrInputCount(count);
  };

  const handleTitle = (event) => {
    const result = event.target.value;
    setTitle(result);
  };

  const setContentPreviewSettings = () => {
    const config = {
      fileId: pinInfo.id,
      title,
      description,
      thumbnail
    };
    action(config);
  };

  const handleFileInput = async (e) => {
    setUploading(true);
    const files = e.target.files;
    setThumbnail(files);
    setUploading(false);
  };

  return (
    <Modal isOpen={contentPreviewModalOpen} toggle={() => toggleModal(false)} centered={true}>
      {!currentPlan?.isLegacy && userMetricsLimit?.title !== metricLimitInfo.PLAN_OK.title && (
        <Alert className="m-0" color={userMetricsLimit.type}>
          {userMetricsLimit.text} <Link to="/billing">Upgrade</Link>
        </Alert>
      )}
      <ModalHeader toggle={() => toggleModal(false)}>
        <div className="d-flex align-items-center">
          <i className="fas fa-eye mr-2" />
          <h5 className="m-0">Content Preview</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="mt-2">
              <Label for="title" className="text-muted">
                Title
              </Label>
              <div className="d-flex position-relative align-items-center">
                <Input id="title" onChange={handleTitle} value={title} readOnly={edit} />
                {!title && title !== '' && (
                  <Spinner color="primary" size="sm" style={{ left: 10, position: 'absolute' }} />
                )}
              </div>
            </div>
            <div className="mt-2">
              <Label for="textarea" className="text-muted">
                Description
              </Label>
              <div className="d-flex position-relative align-items-center">
                <Input
                  type="textarea"
                  id="textarea"
                  onChange={textareaChange}
                  maxLength="225"
                  rows="3"
                  value={description}
                  placeholder="This textarea has a limit of 225 chars."
                  className="position-relative"
                  readOnly={edit}
                />
                {!description && description !== '' && (
                  <Spinner color="primary" size="sm" style={{ left: 10, bottom: 10, position: 'absolute' }} />
                )}
              </div>
              <span className="badgecount badge badge-success"> {descrInputCount} / 225 </span>
            </div>
            <div className="mt-2">
              <Label for="thumbnail" className="text-muted">
                Add Thumbnail (max 1MB)<span className={`${!thumbnail ? 'text-danger' : null}`}>*</span>
              </Label>
              <div className="pinata-file-uploader">
                <input
                  accept="image/*"
                  id="thumbnail"
                  ref={fileInput}
                  className="d-none"
                  type="file"
                  onChange={handleFileInput}
                />
                <div className="d-flex align-items-center">
                  <button
                    onClick={(e) => fileInput.current && fileInput.current.click()}
                    className="btn btn-pinataSecondary"
                    disabled={edit}
                  >
                    {uploading ? 'Uploading...' : 'Upload a file'}
                  </button>

                  <p className="text-decoration-underline m-0 ml-3">
                    {(thumbnail?.length > 0 && thumbnail[0].name) || thumbnail}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </ModalBody>
      {!edit && (
        <ModalFooter>
          <button
            disabled={uploading || loading || !thumbnail}
            onClick={setContentPreviewSettings}
            className="btn btn-primary w-100"
            style={uploading || loading || !thumbnail ? { cursor: 'not-allowed' } : null}
          >
            Set
          </button>
        </ModalFooter>
      )}
    </Modal>
  );
}

export default connect(null, null)(ContentPreviewModal);
