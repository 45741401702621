import React from 'react';
import { Link } from 'react-router-dom';

const UpgradeCard = () => {
  return (
    <div>
      <h3>Pinata Gateways are only available on the Picnic Plan</h3>
      <Link className="btn btn-primary" to="/billing">
        Upgrade today!
      </Link>
    </div>
  );
};

export default UpgradeCard;
