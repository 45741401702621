import {Box, Button, Menu, MenuItem, Typography} from "@mui/material";
import {GatewayRow, Gateways} from "../../../store/gateways/types";
import {ROOT_DOMAIN} from "../../Gateway/GatewayWizard/gatewayConstants";
import {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface GatewaySettingsProps {
  gateways: Gateways;
  getAllGateways: (page: number) => void;
}

const SelectedBox = styled(Box)<{selected: boolean}>((props) => {
  const {selected, theme} = props;
  return {
    backgroundColor: selected ? theme.palette.grey["200"] : theme.palette.background.default,
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  }
});

function GatewaySettings (props: GatewaySettingsProps) {
  const [selectedGateway, setSelectedGateway] = useState<GatewayRow | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { gateways, getAllGateways } = props;

  useEffect(() => {
    if (gateways.rows.length > 0) {
      setSelectedGateway(gateways.rows[0]);
    }
  }, [gateways])

  useEffect(() => {
    getAllGateways(0);
  }, [])

  const open = Boolean(anchorEl);

  return  <Box sx={{display: "flex"}}>
    <Box sx={{flex: 1}}>
      <Typography variant="h6">Gateways</Typography>
      {
        gateways.rows.map((gateway) => {
          return <SelectedBox
            selected={gateway.id === selectedGateway?.id}
            onClick={() => setSelectedGateway(gateway)}
          >
            {gateway.domain}{ROOT_DOMAIN}
          </SelectedBox>
        })
      }
    </Box>
    <Box sx={{flex: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <Button
        sx={{alignSelf: 'flex-end'}}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        endIcon={<ExpandMoreIcon />}
        color="info"
      >
        Open Access
      </Button>
      <Box>
        <LockOutlinedIcon />
      </Box>
      <Typography variant="h6">Settings for gateway {selectedGateway?.domain}{ROOT_DOMAIN}</Typography>
    </Box>
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={() => setAnchorEl(null)}
    >
        <MenuItem>Request Token</MenuItem>
        <MenuItem>IP address</MenuItem>
        <MenuItem>Host URL</MenuItem>
    </Menu>
  </Box>
}

export default GatewaySettings;