import { planTypes } from './../../helpers/enums';
import { AxiosResponse } from 'axios';
import { getMetricsLimitData } from 'helpers/calculations';
import { metricsApi } from 'services/api';
import { MetricsActionsTypes, UsageStatus, UsageMetrics } from './types';

export const getUsageMetrics = () => async (dispatch: any, getState: any) => {
  const {
    billing: { activePricingPlan }
  } = getState();
  try {
    const metricsRes: AxiosResponse & { data: UsageStatus } = await metricsApi.get('metrics/status');

    dispatch({
      type: MetricsActionsTypes.USAGE_STATUS_RECEIVED,
      payload: {
        metrics: metricsRes.data
      }
    });

    const currentDate = new Date();
    const todayDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
    const isoDate = todayDate.toISOString();
    const usageRes: AxiosResponse & { data: UsageMetrics } = await metricsApi.get(`metrics/usage?start=${isoDate}`);

    dispatch({
      type: MetricsActionsTypes.USAGE_METRICS_RECEIVED,
      payload: {
        metrics: usageRes.data,
        activePricingPlan
      }
    });

    if (activePricingPlan?.type !== planTypes.ENTERPRISE.type) {
      dispatch(setMetricsStatus());
    }
  } catch (error) {
    console.log(error);
  }
};

export const setMetricsStatus = () => (dispatch: any, getState: any) => {
  try {
    const { metrics } = getState();
    const isOverLimitData = getMetricsLimitData(metrics?.metrics);
    dispatch({
      type: MetricsActionsTypes.SET_METRICS_LIMIT_STATUS,
      payload: isOverLimitData
    });
  } catch (error) {
    console.log(error);
  }
};
