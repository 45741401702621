import { REMOVE_ALERT, SET_ALERT } from '../../actions/types';

export const setAlert = (message: any, type: any, time = 5000, close?: any) => (dispatch: any) => {
  try {
    dispatch({
      type: SET_ALERT,
      payload: {
        message,
        type,
        close,
        timeOut: time
      }
    });
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT
      });
    }, time);
  } catch (error) {
    console.log(error);
  }
};
