import { logUserOut } from 'hooks/useAuth';

export const getErrorMessage = (error) => {
    if (error && error.response && error.response.data && error.response.data.error) {
        if (typeof error.response.data.error === 'string') {
            return error.response.data.error;
        } else if (typeof error.response.data.error === 'object') {
            return error.response.data.error.details;
        }
        return 'unknown error';
    }
    return error?.message ?? 'unknown error';
};

const api401Responses = [
    'Invalid/expired credentials',
    'The provided credentials do not authorize any user',
    'Account has been disabled',
    ''
];

export const checkForUnauthorized = (error) => {
    let unauthorized = false;
    if (error?.response?.error?.reason) {
        unauthorized = api401Responses.includes(error.response.error.reason);
    }

    if (unauthorized) {
        //  We do this here because clearing the redux state for logouts is not enough
        //  We need to call out to cognito and tell cognito to log the user out
        logUserOut();
    }

    return unauthorized;
};
