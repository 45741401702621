import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAuth, clearCognitoCache } from 'hooks/useAuth';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  ButtonGroup,
  Button,
  FormGroup,
  FormFeedback,
  Input,
  Label
} from 'reactstrap';

import ConfirmCode from './ConfirmCode';
import ButtonWithState from '../../components/Common/ButtonWithState';
import { setAlert } from '../../store/alert/alert.actions';
import { emailFieldRules, namesFieldRules, passwordFieldRules } from 'helpers/formFieldValidations';
import logoImg from '../../assets/images/logo.svg';
import { handleIfUserIsMarketingSignUp } from 'helpers/handleMarketingSignUp';

const Register = ({ setAlert }) => {
  const [authError, setAuthError] = useState(null);
  const [confirmCode, setConfirmCode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isBuilder, setIsBuilder] = useState(false);
  const [planName, setPlanName] = useState('');
  const { signUpUser, confirmSignUp } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      code: ''
    }
  });

  const firstNameState = methods.getFieldState('firstName', methods.formState);
  const lastNameState = methods.getFieldState('lastName', methods.formState);
  const emailState = methods.getFieldState('email', methods.formState);
  const passwordState = methods.getFieldState('password', methods.formState);
  const passwordConfirmState = methods.getFieldState('passwordConfirm', methods.formState);

  useEffect(() => {
    handleIfUserIsMarketingSignUp();
    const clearCache = localStorage.getItem('pinataCacheBust2');
    if (!clearCache) {
      clearCognitoCache();
      localStorage.setItem('pinataCacheBust2', 'true');
    }
  }, []);

  const handleSubmit = async (data) => {
    try {
      setAuthError(null);
      setSubmitting(true);
      const { email, password, firstName, lastName, isBuilder, code } = data;

      if (confirmCode) {
        await confirmSignUp(email, password, code);
        history.push('/authenticate');
      } else {
        await signUpUser(email, password, firstName, lastName, isBuilder);
        setAlert('Registration verification code sent to your email!', 'success');
        setConfirmCode(true);
      }
    } catch (error) {
      setAuthError(error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const canSubmit = useMemo(() => methods.formState.isValid, [methods.formState.isValid]);

  const getPlanCost = (plan) => {
    if (plan === 'Professional') {
      return '$20/month';
    } else if (plan === 'Individual') {
      return '$0.15/GB/Month';
    }
  };

  return (
    <React.Fragment>
      <div className="account-pages mt-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="mt-5 text-center">
                <p style={{ fontSize: 16 }}>
                  Already have an account ?{' '}
                  <Link to="/signin" className="font-weight-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
              </div>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-9">
                      <div className="text-primary p-4">
                        <h5 className="text-white">
                          {planName ? `Register for the ${planName} Plan` : 'Register Your Account'}
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      {/* <img src={profileImg} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <FormProvider {...methods}>
                      <form className="form-horizontal" onSubmit={methods.handleSubmit(handleSubmit)}>
                        {authError && <Alert color="danger">{authError}</Alert>}

                        {confirmCode ? (
                          <>
                            <ConfirmCode setConfirmCode={setConfirmCode} submitting={submitting}>
                              <Controller
                                name="email"
                                control={methods.control}
                                rules={emailFieldRules}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    type="email"
                                    invalid={emailState.invalid}
                                    disabled
                                    autoComplete="email"
                                    readOnly
                                  />
                                )}
                              />
                            </ConfirmCode>
                            <ButtonWithState disabled={!canSubmit} submitting={submitting}>
                              Submit Code
                            </ButtonWithState>
                            <button onClick={() => setConfirmCode(false)} className="pinata-link-button my-4">
                              <i className="fas fa-angle-left" /> Return back
                            </button>
                          </>
                        ) : (
                          <>
                            <Controller
                              name="firstName"
                              control={methods.control}
                              rules={namesFieldRules}
                              render={({ field }) => (
                                <FormGroup>
                                  <Label for={field.name} className="text-muted mb-1">
                                    First Name
                                  </Label>
                                  <Input
                                    id={field.name}
                                    {...field}
                                    placeholder="Enter your first name"
                                    invalid={firstNameState.invalid}
                                  />
                                  {firstNameState.invalid && (
                                    <FormFeedback>{firstNameState.error.message}</FormFeedback>
                                  )}
                                </FormGroup>
                              )}
                            />

                            <Controller
                              name="lastName"
                              control={methods.control}
                              rules={namesFieldRules}
                              render={({ field }) => (
                                <FormGroup>
                                  <Label for={field.name} className="text-muted mb-1">
                                    Last Name
                                  </Label>
                                  <Input
                                    id={field.name}
                                    {...field}
                                    placeholder="Enter your last name"
                                    invalid={lastNameState.invalid}
                                  />
                                  {lastNameState.invalid && <FormFeedback>{lastNameState.error.message}</FormFeedback>}
                                </FormGroup>
                              )}
                            />
                            <Controller
                              name="email"
                              control={methods.control}
                              rules={emailFieldRules}
                              render={({ field }) => (
                                <FormGroup>
                                  <Label for={field.name} className="text-muted mb-1">
                                    Email
                                  </Label>
                                  <Input
                                    id={field.name}
                                    {...field}
                                    type="email"
                                    placeholder="Enter email"
                                    invalid={emailState.invalid}
                                    autoComplete="email"
                                  />
                                  {emailState.invalid && <FormFeedback>{emailState.error.message}</FormFeedback>}
                                </FormGroup>
                              )}
                            />
                            <Controller
                              name="password"
                              control={methods.control}
                              rules={{ ...passwordFieldRules, deps: 'passwordConfirm' }}
                              render={({ field }) => (
                                <FormGroup>
                                  <Label for={field.name} className="text-muted mb-1">
                                    Password
                                  </Label>
                                  <Input
                                    id={field.name}
                                    {...field}
                                    type="password"
                                    placeholder="Enter password"
                                    invalid={passwordState.invalid}
                                    autoComplete="new-password"
                                  />
                                  {passwordState.invalid && (
                                    <FormFeedback>{passwordState?.error?.message}</FormFeedback>
                                  )}
                                </FormGroup>
                              )}
                            />
                            <Controller
                              name="passwordConfirm"
                              control={methods.control}
                              rules={{
                                ...passwordFieldRules,
                                deps: 'password',
                                validate: (val) => val === methods.getValues('password') || 'Passwords do not match'
                              }}
                              render={({ field }) => (
                                <FormGroup>
                                  <Label for={field.name} className="text-muted mb-1">
                                    Confirm Password
                                  </Label>
                                  <Input
                                    id={field.name}
                                    {...field}
                                    type="password"
                                    placeholder="Confirm password"
                                    invalid={passwordConfirmState.invalid}
                                    autoComplete="new-password"
                                  />
                                  {passwordConfirmState.invalid && (
                                    <FormFeedback>{passwordConfirmState?.error?.message}</FormFeedback>
                                  )}
                                </FormGroup>
                              )}
                            />
                            <div className="form-group">
                              <p className="m-0">Are You a Builder or a Creator?</p>
                              <p className="text-muted font-size-10">
                                Don't get self-conscious now. This answer won't impact your experience at all. It's to
                                help us get better at our jobs.
                              </p>
                              <ButtonGroup>
                                <Button
                                  color={isBuilder ? 'primary' : ''}
                                  onClick={() => setIsBuilder(true)}
                                  active={isBuilder === true}
                                >
                                  Builder/Developer
                                </Button>
                                <Button
                                  color={isBuilder ? '' : 'primary'}
                                  onClick={() => setIsBuilder(false)}
                                  active={isBuilder === false}
                                >
                                  Creator
                                </Button>
                              </ButtonGroup>
                            </div>
                            <ButtonWithState>Register</ButtonWithState>
                          </>
                        )}
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to Pinata's{' '}
                            <a
                              href="https://pinata.cloud/terms"
                              target="_blank"
                              rel="noopenner noreferrer"
                              className="text-primary"
                            >
                              Terms of Use
                            </a>
                          </p>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any
};

const mapStatetoProps = ({ user }) => ({
  user
});

const mapDispatchToProps = {
  setAlert
};

export default connect(mapStatetoProps, mapDispatchToProps)(Register);
